$env: false;
@import '../../../../../../styles/variables';

.tab-with-upload-icon {
    display: flex;

    .upload-icon-container {
        margin-left: 1rem;
        height: 2.2rem;

        .file-input-label {
            display: block;
        }

        .font-icon {
            font-size: 2.4rem;
            cursor: pointer;

            &.inactive {
                cursor: default;
            }
        }
    }
}
