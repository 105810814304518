$env: false;
@import '../../../styles/variables';

.layout-with-left-aside {
    flex-grow: 1;

    .first-slot {
        flex: 0 0 320px;
    }

    .second-slot {
        flex: 1;
        padding: 0 $padding-base-horizontal;
    }
}
