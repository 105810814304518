$env: false;
@import '../../../../styles/variables';

.footer-with-info {
    padding: .8rem 0 0 .8rem;
    font-size: $font-size-small;
    line-height: 1.5rem;

    .sides-container {
        display: flex;

        .left-side,
        .right-side {
            display: flex;
            flex: 1 0 50%;
            flex-direction: column;
        }
    }

    .alert-note {
        color: $color-primary;
    }
}
