$env: false;
@import '../../../styles/variables';

.modal.export-classic-as-of-date-report-modal {
    overflow: visible;

    .export-classic-as-of-date-report-modal-content {
        width: 55rem;
        overflow: visible;

        .as-of-date-container {
            margin-top: 2.4rem;
            padding-bottom: 2.4rem;
            border-bottom: $border;
        }

        .format-container {
            margin: 2.4rem 0;
        }

        .note {
            margin-top: 2.4rem;
            font-size: 1.6rem;
            font-weight: 600;
        }
    }
}
