.errors-logs-page {
  padding: 0 10rem;
}
.errors-logs-page .errors-logs-header {
  display: flex;
  justify-content: space-between;
}
.errors-logs-page .errors-logs-links {
  display: flex;
  align-items: center;
}
.errors-logs-page .errors-logs-links a {
  margin-left: 1.5rem;
}