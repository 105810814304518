$env: false;
@import '../../../../../../styles/variables';

.top-25-holdings-table {
    .widget-header-container {
        height: 3.6rem;
    }

    .widget-control-panel {
        text-align: right;

        .font-icon {
            font-size: $icon-large-size;
            margin-left: .6rem;
            vertical-align: middle;

            &:not(.inactive) {
                cursor: pointer;
            }
        }
    }
}
