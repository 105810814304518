$env: false;
@import '../../../../../../styles/colors';
@import '../../../../../../styles/variables';

.subscriptions-panel {
    padding: 2.4rem;

    &-header {
        font-weight: $font-weight-bold;
        font-size: $font-size-large;
        color: $color-header;
        margin-bottom: .5rem;
        padding: 0 1rem;
    }

    &-list-header {
        display: flex;
        justify-content: space-between;
        padding: .5rem 1rem;
        font-size: $font-size-small;
        color: $color-header;
        background-color: $color-table-inner-border;

        @if ($env) {
            color: $color-default;
        }

        span:last-child {
            text-align: right;
        }
    }

    .subscription {
        &-row {
            display: flex;
            padding: .5rem 1rem;
        }

        &-checkbox {
            display: flex;
            margin-right: auto;
            white-space: normal;

            .checkbox-label {
                font-weight: $font-weight-regular;
                color: $color-text;
            }

            .font-icon {
                flex-shrink: 0;
                padding-top: .2rem;
            }
        }

        &-name-no-checkbox {
            display: flex;
            margin-right: auto;
            white-space: normal;
            font-weight: $font-weight-regular;
            color: $color-text;
            align-items: center;
            margin-left: 2.3rem;
        }
    }

    &-footer {
        font-size: $font-size-small;
        margin-top: .5rem;
    }
}

.subscription-tooltip {
    width: 14rem;
    text-align: center;
}
