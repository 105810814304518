/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
.registered-shareholder-current .widget-content .rt-table {
  margin-bottom: 2rem;
}
.registered-shareholder-current .widget-content .section-title {
  font-weight: 700;
  margin-right: 1rem;
}
.registered-shareholder-current .widget-content .section-title-container {
  display: flex;
  background: #f4f1ed;
  padding: 1rem 2rem;
  margin-bottom: 2rem;
  cursor: pointer;
}
.registered-shareholder-current .widget-content .section-title-container .font-icon {
  cursor: inherit;
}
.registered-shareholder-current .widget-content .section-title-container .font-icon-arrow-drop-down {
  font-size: 2.4rem;
}
.registered-shareholder-current .widget-content .section-title-container .font-icon-paging-right {
  font-size: 1.2rem;
  padding: 0.6rem 0.9rem;
}
.registered-shareholder-current .align-center .cell-with-date,
.registered-shareholder-current .align-center .cell-with-number {
  justify-content: center;
  text-align: center;
}
.registered-shareholder-current .message-note {
  padding: 0 2rem 2rem;
}
.registered-shareholder-current .title-additional-info {
  color: #6b6056;
}