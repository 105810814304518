/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
.report-scheduler {
  display: block;
}
.report-scheduler .font-icon-paging-right {
  margin-left: 0.3rem;
  font-size: 1rem;
  cursor: pointer;
}
.report-scheduler .icon-rotate {
  transform: rotate(90deg);
}
.report-scheduler .collapsed {
  display: none;
}
.report-scheduler .header-section {
  display: flex;
  margin-bottom: 1.2rem;
  align-items: center;
}
.report-scheduler-header {
  font-weight: 700;
  line-height: 2;
  color: #000;
}
.report-scheduler .schedule-type-list {
  margin-top: 0.5rem;
}
.report-scheduler .schedule-type-list .radio-component:first-child {
  padding-left: 0;
}
.report-scheduler .week-day-list {
  display: flex;
  height: 33px;
  margin-top: 0.5rem;
}
.report-scheduler .week-day-list .font-icon {
  display: none;
}
.report-scheduler .week-day-list .radio-component.block {
  padding: 0;
}
.report-scheduler .week-day-list .radio-label {
  display: block;
  text-align: center;
  font-weight: 400;
  color: #941f40;
  width: 4rem;
  border: 1px solid #e9e3dc;
  padding: 0.5rem;
  margin: 0;
}
.report-scheduler .week-day-list .radio-input:checked ~ .radio-label {
  background-color: #988a7d;
  border-color: #988a7d;
  color: #fff;
}
.report-scheduler .month-day-container {
  margin-top: 1rem;
  padding-left: 2.3rem;
}
.report-scheduler .month-day-container .input-container.month-input {
  margin: 1rem 0;
}
.report-scheduler .month-day-container .input-container.month-input .text-input {
  width: 5rem;
}
.report-scheduler .month-day-container .input-container.month-input .text-input-label {
  font-size: 1.4rem;
}
.report-scheduler .month-day-container .input-container.month-input .text-input input {
  width: 100%;
}
.report-scheduler .report-scheduler-message {
  color: #941f40;
  font-size: 1.2rem;
  font-weight: 700;
  max-width: 38rem;
}
.report-scheduler .export-format-container {
  margin-top: 1.5rem;
}
.report-scheduler .export-format-container .export-format-header {
  font-weight: 700;
  line-height: 2;
}
.report-scheduler .export-format-container .export-format-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.report-scheduler .export-format-container .radio-component {
  padding-left: 0;
}