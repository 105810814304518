@import '../variables';
@import '../colors';

.dropdown {
    position: relative;

    &.open .dropdown-menu {
        display: block;
    }

    .divider {
        height: 1px;
        margin: .9rem 0;
        overflow: hidden;
        background-color: $color-border;
    }
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: $zindex-dropdown;
    display: none;
    float: left;
    min-width: $dropdown-min-width;
    padding: .5rem 0;
    margin: .2rem 0 0;
    list-style: none;
    text-align: left;
    background-color: $color-dropdown-bg;
    border: $color-border solid rgba(0, 0, 0, .15);
    border-radius: $border-radius;
    box-shadow: 0 .6rem 1.2rem rgba(0, 0, 0, .175);
    background-clip: padding-box;

    &-right {
        left: auto;
        right: 0;
    }
}
