.flexbox {
    display: flex;

    // wrap
    &-wrap {
        flex-wrap: wrap;
    }

    &-reverse {
        flex-direction: row-reverse;
    }

    // direction
    &-column {
        flex-direction: column;

        &.flexbox-reverse {
            flex-direction: column-reverse;
        }
    }

    .align-content,
    .justify-content {
        &-flex-start {
            align-content: flex-start;
        }

        &-flex-end {
            align-content: flex-end;
        }

        &-center {
            align-content: center;
        }

        &-space-between {
            align-content: space-between;
        }

        &-space-around {
            align-content: space-around;
        }

        &-stretch {
            align-content: stretch;
        }
    }

    .align-items,
    .align-self {
        &-flex-start {
            align-items: flex-start;
        }

        &-flex-end {
            align-items: flex-end;
        }

        &-center {
            align-items: center;
        }

        &-baseline {
            align-items: baseline;
        }

        &-stretch {
            align-items: stretch;
        }
    }
}
