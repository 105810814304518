$env: false;
.create-note-modal {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;

    .modal-textarea {
        width: 40rem;
        resize: none;
    }

    .note-length-counter {
        align-self: flex-end;
        margin: .5rem 0;
    }

    .modal-actions:before {
        display: none;
    }
}
