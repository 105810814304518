$env: false;
@import '../../../../styles/variables';
@import '../../../../styles/colors';

.export-historical-analysis-modal {
    width: 48rem;
    min-height: 36rem;

    .modal-actions {
        margin-bottom: 2rem;

        &:before {
            margin: 3.4rem .2rem;
        }
    }

    .format-container,
    .view-type-container {
        margin: 3.8rem 0;

        .radio-label {
            font-weight: normal;
            color: $color-text;
        }
    }

    .format-container {
        .format-label {
            font-weight: $font-weight-bold;
            padding: 0 0 1.8rem .5rem;
            color: $color-text;
        }
    }

    .view-type-container {
        .input-date-range-picker {
            margin: 1.2rem 0 0 2rem;
        }
    }
}
