/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
.geographic-analysis .table-easy-with-footer {
  padding-bottom: 5rem;
  margin-bottom: 0;
}
.geographic-analysis .font-icon.font-icon-filter {
  font-size: 2.2rem;
}
.geographic-analysis .custom-table-header {
  display: flex;
  flex: 1 1 auto;
  justify-content: space-between;
  align-items: center;
  margin-left: -1.2rem;
}
.geographic-analysis .custom-table-header-title {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 1;
  align-items: center;
}
.geographic-analysis .ReactVirtualized__Table__headerRow {
  align-items: stretch;
}
.geographic-analysis .ReactVirtualized__Table__headerColumn {
  padding: 0 2rem;
}
.geographic-analysis .ReactVirtualized__Table__headerColumn .ReactVirtualized__Table__headerTruncatedText {
  text-overflow: clip;
  overflow: auto;
  white-space: normal;
}
.geographic-analysis .ReactVirtualized__Table__row {
  align-items: stretch;
}
.geographic-analysis .ReactVirtualized__Table__row .ReactVirtualized__Table__rowColumn {
  display: flex;
  align-items: center;
}
.geographic-analysis .virtualized-sticky-footer {
  border-top: none;
  height: 4rem;
  padding: 0;
}
.geographic-analysis .virtualized-sticky-footer .ReactVirtualized__Table__rowColumn {
  text-transform: uppercase;
}
.geographic-analysis .virtualized-sticky-footer .ReactVirtualized__Table__rowColumn:first-child {
  text-align: left;
}
.geographic-analysis .message-note {
  padding: 0 0 1.2rem;
}
.geographic-analysis .trigger-wrapper {
  display: flex;
  align-items: center;
  margin-left: 0.6rem;
}
.geographic-analysis .action-icons-container .action-icon {
  font-size: 2.4rem;
}
.geographic-analysis .action-icons-container .action-icon:not(.inactive) {
  cursor: pointer;
}
.geographic-analysis .geographic-analysis-control-panel {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2.4rem 0;
}
.geographic-analysis .geographic-analysis-control-panel .left-details-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-shrink: 1;
}
.geographic-analysis .geographic-analysis-control-panel .left-details-container > * {
  margin-right: 1.2rem;
}
.geographic-analysis .geographic-analysis-control-panel .action-icons-container {
  flex: none;
}
.geographic-analysis .geographic-analysis-control-panel .checkbox-label {
  font-weight: 400;
  color: #000;
}
.geographic-analysis .geographic-analysis-selector {
  min-width: 23.8rem;
  padding: 0;
}
.geographic-analysis .geographic-analysis-selector .is-disabled .Select-control {
  opacity: 1;
}
.geographic-analysis .cell-with-number,
.geographic-analysis .cell-with-percent {
  display: flex;
  justify-content: flex-end;
}
.geographic-analysis .widget-fetching, .geographic-analysis .widget-no-data, .geographic-analysis .widget-error {
  min-height: 31rem;
}
.geographic-analysis .ReactVirtualized__Table__sortableHeaderIcon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}
.geographic-analysis .cell-value {
  width: 100%;
}
.geographic-analysis .shareholder-type-option {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.geographic-analysis .shareholder-type-option .font-icon {
  font-size: 1.8rem;
}
.geographic-analysis .column-filter {
  top: 0.3rem;
}
.geographic-analysis .shares-number {
  margin: 0 0.4rem;
}