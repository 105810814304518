/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
.top-25-holdings-table .widget-header-container {
  height: 3.6rem;
}
.top-25-holdings-table .widget-control-panel {
  text-align: right;
}
.top-25-holdings-table .widget-control-panel .font-icon {
  font-size: 2.4rem;
  margin-left: 0.6rem;
  vertical-align: middle;
}
.top-25-holdings-table .widget-control-panel .font-icon:not(.inactive) {
  cursor: pointer;
}