$env: false;
@import '../../../../../styles/variables';

.notes-control-panel {
    position: absolute;
    top: 8rem;
    right: 4rem;
    z-index: 1;

    .font-icon {
        font-size: $icon-large-size;
        margin-left: .6rem;
        line-height: 3.6rem;
        vertical-align: middle;

        &:not(.inactive) {
            cursor: pointer;
        }
    }

    .first-slot & {
        z-index: 1;
        right: 2rem;
    }
}
