$env: false;
@import '../../styles/variables';
@import '../../styles/colors';
@import '../../styles/mixins';

.pagination {
    display: flex;
    padding: 0 1rem;

    .page-selector {
        display: flex;
        align-items: center;

        .icon-pagination-container {
            min-width: 3rem;
        }

        .page-selector-form {
            display: flex;
            align-items: center;
        }

        .page-selector-input {
            width: 5rem;
            height: 4rem;
            margin: 0 1rem;
            padding: .3rem;

            @include border-rounded($border-dark);
        }

        .icon-pagination {
            color: $color-primary;
            font-weight: bold;
            cursor: pointer;
            font-size: $font-size-small;

            &.inactive {
                display: none;
            }

            &-offset {
                display: inline-block;
                margin: 0 .5rem;
            }
        }

        .font-icon:not(.inactive) {
            cursor: pointer;
        }
    }

    .page-size-container {
        display: flex;
        align-items: center;
        margin-right: 3rem;

        .page-size-selector {
            min-width: 7rem;
            margin: 0 .5rem;
        }
    }

    .pagination-text {
        color: $color-text-muted;
        font-size: $font-size-small;
    }
}
