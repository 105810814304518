/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
.slot {
  position: relative;
  width: 100%;
  min-height: 20rem;
  flex: 0 1 auto;
}
.slot.col-1 {
  max-width: 30%;
  min-width: 41.8rem;
  margin-bottom: 2.4rem;
  margin-right: 3.33333%;
}
.slot.col-2 {
  max-width: 63.33333%;
  min-width: calc(41.8rem * 2 + 3.33333%);
  margin-bottom: 2.4rem;
  margin-right: 3.33333%;
}
.slot.col-3 {
  width: 96.66666%;
  margin-bottom: 2.4rem;
  margin-right: 3.33333%;
}