@import '../colors';
@import '../variables';
@import '../mixins';
@import '~react-select/dist/react-select.css';

.Select-arrow {
    border-top-color: $color-primary;

    .is-open &,
    &-zone:hover > & {
        border-top-color: darken($color-primary, 10%);
    }
}

input[type='text'],
input[type='number'],
input[type='password'],
textarea {
    padding: .8rem;

    @include border-rounded($border-dark);
    outline: none;

    &:disabled {
        background-color: $color-background-disabled;
        opacity: $field-disabled-opacity;
    }
}

input[type='text'],
input[type='number'],
input[type='password'] {
    height: $height-base;
}

input[type='number'] {
    &::-webkit-inner-spin-button {
        display: none;
    }
}

.form-error-message,
.form-warning-message {
    color: $color-error-text;
    font-size: $font-size-small;
    font-weight: $font-weight-bold;
}

label {
    font-weight: $font-weight-bold;
    color: $color-text-label;
    white-space: nowrap;
    cursor: pointer;
}
