@import '../colors';
@import '../variables';

// Button variants
//
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons

@mixin view-group-btn-state($bg-color, $color, $border-color) {
    box-shadow: none;
    background-color: $bg-color;
    color: $color;
    border-color: $border-color;
    text-shadow: .2px 0 0, -.2px 0 0;

    &:hover,
    &:focus,
    &.focus {
        background-color: $bg-color;
        color: $color;
        border-color: $border-color;
    }
}

@mixin button-variant($color, $background, $border, $underline) {
    color: $color;
    background-color: $background;
    border-color: $border;

    &:before {
        content: '';
        position: absolute;
        left: 51%;  // Firefox fix to remove small visible dash
        right: 51%; // in the middle of the buttons
        bottom: -.1rem;
        background: $underline;
        height: .4rem;
        border-radius: 0 0 .3rem .3rem;
        transition-property: left, right;
        transition-duration: $animation-duration;
        transition-timing-function: ease-out;
    }

    &:hover,
    &.hover,
    &:focus,
    &.focus {
        &:before {
            left: -.1rem;
            right: -.1rem;
        }
    }

    &:active,
    &.active,
    &.disabled,
    &[disabled],
    fieldset[disabled] & {
        &:before {
            left: 50%;
            right: 50%;
        }

        &:hover,
        &:focus,
        &.focus {
            background-color: $background;
            border-color: $border;
        }
    }

    .badge {
        color: $background;
        background-color: $color;
    }
}

@mixin button-size($padding-vertical, $padding-horizontal, $font-size, $line-height, $border-radius) {
    padding: $padding-vertical $padding-horizontal;
    font-size: $font-size;
    line-height: $line-height;
    border-radius: $border-radius;
}
