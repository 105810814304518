$env: false;
@import '../../styles/variables';

$arrow-size: 1.4rem;

.tippy-popper {
    &[x-placement^=right] .ast-popup-theme .tippy-arrow {
        transform: rotate(45deg);
    }

    &[x-placement^=left] .ast-popup-theme .tippy-arrow {
        transform: rotate(-135deg);
    }

    &[x-placement^=top] .ast-popup-theme .tippy-arrow {
        transform: rotate(-45deg);
    }

    &[x-placement^=bottom] .ast-popup-theme .tippy-arrow {
        transform: rotate(135deg);
    }

    .ast-popup-theme {
        max-width: $popover-max-width;
        z-index: $zindex-popover;
        font-size: $font-size-small;
        word-wrap: break-word;
        text-align: left;
        background-color: $popover-bg;
        background-clip: padding-box;
        border: $tooltip-border-width solid $popover-border-color;
        border-radius: $border-radius;
        padding: $popover-content-padding-y $popover-content-padding-x;
        color: $color-text;
        box-shadow: $shadow-default;

        .tippy-arrow {
            position: absolute;
            z-index: -1;
            height: $arrow-size;
            width: $arrow-size;
            background-color: $popover-bg;
            border: $popover-border-width solid;
            border-color: transparent transparent $popover-border-color $popover-border-color;
            transform-origin: initial;
        }
    }
}
