$env: false;
@import '../../../styles/variables';

.alerts-modal {
    &-header {
        position: absolute;
        top: 1.5rem;
        left: 4.8rem;
        right: 4.8rem; // modal padding + cross icon width
        display: flex;
        justify-content: center;
        align-items: center;

        .header-text,
        .icon-pagination {
            font-size: 1.8rem;
            margin: 0 1rem;
        }

        .icon-pagination-container {
            display: flex;
            align-items: center;
        }

        .header-text {
            font-size: $font-size-x-large;
            font-weight: $font-weight-bold;
            line-height: normal;
            text-align: left;
            color: $color-widget-header;
        }
    }

    &-content {
        margin-top: 2.2rem;

        .alert-upper-section {
            display: flex;
            justify-content: space-between;
            border-top: $border;
            border-bottom: $border;
            padding: .8rem 0;
            margin: 1rem 0;

            .label {
                span:first-child {
                    font-weight: $font-weight-bold;
                    color: $color-widget-header;
                }
            }
        }

        .alert-text-section {
            width: 50rem;
            min-height: 8.5rem;
            word-wrap: break-word;
        }

        .alert-lower-section {
            border-top: $border;
            border-bottom: $border;
            padding: .8rem 0;
            margin: 1rem 0;

            .label {
                &:first-child {
                    margin-bottom: .8rem;
                }

                span:first-child {
                    font-weight: $font-weight-bold;
                    color: $color-widget-header;
                }
            }
        }
    }

    &-actions {
        display: flex;
        justify-content: space-between;

        .btn-custom {
            display: flex;
            align-items: center;

            &:hover {
                color: $color-text-inverse;
            }

            &:focus:before {
                left: 51%;
                right: 51%;
            }
        }
    }
}
