@import '../colors';
@import '../mixins';
@import '../variables';

.table {
    width: 100%;
    font-size: $font-size-small;
    table-layout: fixed;

    thead,
    tfoot {
        font-weight: $font-weight-bold;
    }

    thead {
        background-color: $color-table-header-bg;
    }

    tr {
        height: 4rem;
        border-bottom: $border;
    }

    th,
    td {
        text-align: right;
        position: relative;
        padding: .5rem;
        $padding: 1.5rem;

        &:first-child {
            padding-left: $padding;
            text-align: left;
        }

        &:last-child {
            padding-right: $padding;
        }

        &.text-col {
            text-align: left;
        }
    }

    th {
        &:hover {
            background-color: $color-table-header-bg-active;
        }

        &.active {
            background-color: $color-table-header-bg-active;
            //white-space: nowrap; // where should be arrow?
        }
    }
}

.table-list {
    width: 100%;

    tr {
        border-bottom: $border;
        height: 2.5rem;
    }

    td {
        padding: .4rem 0;

        &:last-child {
            font-weight: $font-weight-bold;
            text-align: right;
        }
    }
}

.cell-with-align-center {
    text-align: center;
    justify-content: center;
    width: 100%;
}

.cell-with-align-right,
.cell-with-currency-usd,
.cell-with-number,
.cell-with-percent,
.cell-with-mkt-val,
.cell-with-amount,
.cell-with-date {
    justify-content: flex-end;
    text-align: right;
    width: 100%;
}

.ReactTable {
    font-size: $font-size-small;
    overflow-x: auto;
    // IE10 fix ASTTSTRT-21949
    transform: scale(1);

    .rt-thead {
        background-color: $color-table-header-bg;
        height: $table-heading-row-height;
        font-weight: $font-weight-bold;
    }

    .rt-tr-group {
        .rt-tr:hover {
            background-color: $color-table-header-bg;

            .rt-td {
                @if ($env != true) {
                    border-right: $border;
                }

                &:first-child,
                &:last-child {
                    border-right: 0;
                }
            }
        }
    }

    .rt-th {
        display: flex;
        align-items: center;
        padding: 0 8px; // zoom fix ASTTSTRT-19271
        position: relative;

        @if ($env != true) {
            border-right: $border;
        }

        &:last-child {
            border-right: 0;
        }

        &.-header:hover:not(.expander) {
            background-color: $color-table-header-bg-active;
        }

        &.active {
            background-color: $color-table-header-bg-active;
        }

        .sort-icon {
            position: absolute;
            right: .3rem;
            top: 50%;
            transform: translateY(-50%);
        }

        .heading-cell {
            max-width: 100%;
        }
    }

    .rt-tr {
        display: flex;
        min-height: $table-body-row-height;
        height: 100%;

        @if ($env) {
            border-bottom: $border;
            border-color: #dcdfe4;
        }
    }

    .rt-td {
        display: flex;
        align-items: center;
        padding: 0 8px; // zoom fix ASTTSTRT-19271

        @if ($env != true) {
            border-right: 1px $color-table-header-bg solid;
        }
        min-height: $table-body-row-height; // ie11 shareholders table row display fix

        &:last-child {
            border-right: 0;
        }

        &:first-child {
            & .cell-with-align-right,
            & .cell-with-currency-usd,
            & .cell-with-number,
            & .cell-with-percent,
            & .cell-with-mkt-val,
            & .cell-with-amount,
            & .cell-with-date {
                justify-content: flex-start;
                text-align: left;
            }
        }
    }

    .rt-tfoot {
        font-weight: $font-weight-bold;
    }

    .font-icon {
        font-size: $table-icons-size;
        cursor: pointer;

        &.inactive {
            cursor: auto;
        }

        &[class*='font-icon-checkbox'] {
            cursor: inherit;
        }
    }

    .-cursor-pointer {
        cursor: pointer;
    }
}

.ReactVirtualized__Table:not(.widget-table) .ReactVirtualized__Table__headerColumn:not(:first-child) {
    justify-content: center;
    text-align: center;
}

.ReactVirtualized__Grid:focus {
    outline: none;
}

.widget-table {
    & + .ReactVirtualized__Table__row {
        .ReactVirtualized__Table__rowColumn {
            border: 0;
        }

        &:hover {
            background-color: inherit;

            .ReactVirtualized__Table__rowColumn {
                border: 0;
            }
        }
    }

    .ReactVirtualized__Table {
        &__header {
            &Row {
                padding-left: 0;

                &:not(:last-of-type) {
                    border-bottom: $border;
                }
            }

            &Column {
                border: 0;
            }
        }

        &__row {
            padding-left: 0;

            &:not(:last-of-type) {
                border-bottom: $border;
            }

            &Column {
                border: 0;
            }

            &:hover {
                background-color: inherit;

                .ReactVirtualized__Table__rowColumn {
                    border: 0;
                }
            }
        }
    }
}
