/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
.profile-menu {
  display: flex;
  flex-shrink: 1;
  align-items: center;
  height: 4rem;
  min-width: 0;
}
.profile-menu .profile-avatar {
  height: 2.4rem;
  width: 2.4rem;
  max-width: 2.4rem;
  max-height: 2.4rem;
  border-radius: 50%;
  border: 1px solid #988a7d;
}
.profile-menu .profile-avatar-container {
  position: relative;
}
.profile-menu .profile-avatar-container .badge {
  position: absolute;
  bottom: 65%;
  left: 50%;
}
.profile-menu .font-icon {
  font-size: 2.4rem;
  padding-right: 0.4rem;
  vertical-align: middle;
  cursor: pointer;
}
.profile-menu .user-name {
  flex: 1 1 auto;
  min-width: 10rem;
  text-align: right;
  padding: 0 1rem 0 0;
}
.profile-menu .toggle {
  display: inline-block;
  height: 100%;
}
.profile-menu .menu-list {
  min-width: 28rem;
  margin-top: 1.9rem;
  padding-top: 0;
}
.profile-menu .menu-list a {
  position: relative;
  padding: 1.2rem;
  display: flex;
  align-items: center;
}
.profile-menu .menu-list .badge {
  margin-left: auto;
}
.profile-menu .menu-list .profile-avatar {
  height: 2.1rem;
  width: 2.1rem;
  margin: 0 0.4rem;
  border-radius: 50%;
  border: 1px solid #988a7d;
}