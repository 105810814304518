$env: false;
@import '../../../styles/variables';
@import '../../../styles/colors';

.widget-institution-profile {
    .widget-content {
        padding-top: 3rem;
        min-height: 40rem;
    }

    .segment {
        margin-bottom: 4.8rem;

        &-title {
            color: $color-text;
        }

        .row-with-indent,
        .text-block-item:not(:first-child) {
            margin-top: 2rem;
        }
    }
}
