$env: false;
@import '../../../styles/variables';

.widget-activity-trend {
    .chart-content {
        height: $institutional-shareholders-widgets-content-height;
    }

    .table-content {
        height: 18rem;
    }
}
