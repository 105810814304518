/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
.widget-contact-details {
  min-width: 87rem;
  min-height: 40rem;
  padding: 2.4rem 3.6rem;
  line-height: 2.4rem;
}
.widget-contact-details .widget-content {
  padding-top: 2.4rem;
}
.widget-contact-details .widget-title-container {
  display: flex;
  align-items: baseline;
}
.widget-contact-details .widget-title {
  font-size: 2.4rem;
}
.widget-contact-details .widget-header .back-arrow {
  font-size: 1.4rem;
  font-weight: 400;
  margin-bottom: 1.2rem;
}
.widget-contact-details .widget-control-panel {
  display: flex;
  align-items: flex-end;
}
.widget-contact-details .container {
  display: flex;
}
.widget-contact-details .column {
  min-width: 20rem;
}
.widget-contact-details .column.main {
  min-width: 60rem;
  max-width: 70rem;
  margin-right: 2.4rem;
}
.widget-contact-details .segment:not(:last-child) {
  margin-bottom: 4.8rem;
}
.widget-contact-details .segment .title {
  color: #000;
  margin-top: 0;
  margin-bottom: 1.2rem;
}
.widget-contact-details .segment .text-block-item:not(:last-child) {
  margin-bottom: 2rem;
}
.widget-contact-details .company-info {
  font-size: 1.2rem;
}
.widget-contact-details .company-info-table {
  border-spacing: 0;
  width: 30rem;
  margin-bottom: 0.8rem;
}
.widget-contact-details .company-info-table td {
  border-bottom: 1px solid #e9e3dc;
  height: 2.4rem;
  line-height: 1;
}
.widget-contact-details .company-info-table td:last-child {
  text-align: right;
}
.widget-contact-details .company-info .aum-label {
  font-weight: 700;
}
.widget-contact-details .fund-list .link {
  display: block;
}