$env: false;
@import '../../styles/colors';
@import '../../styles/variables';

$max-widget-height: 45rem;
$min-col2-height: $max-widget-height * 2;

.home-page {
    min-height: 100%;

    &-slot {
        &.col-3 {
            display: grid;
            -ms-grid-rows: auto 0 1fr;
            grid-template-rows: auto 1fr;
            grid-column-gap: $column-margin-right;
            width: 100%;

            .slot {
                max-width: inherit;

                &:nth-child(2).col-2 {
                    grid-column-start: 2;
                    grid-column-end: 3;
                }

                &.col-2 {
                    grid-row-start: 1;
                    grid-row-end: 3;
                }
            }
        }

        &.perf-left {
            -ms-grid-columns: $column-width-max * 2 + $column-margin-right $column-margin-right $column-width-max;
            grid-template-columns: $column-width-max * 2 + $column-margin-right $column-width-max;

            .slot {
                &:nth-child(1).col-2 {
                    -ms-grid-row: 1;
                    -ms-grid-row-span: 3;
                    -ms-grid-column: 1;
                }

                &:nth-child(2).col-1 {
                    -ms-grid-row: 1;
                    -ms-grid-column: 3;
                }

                &:nth-child(3).col-1 {
                    -ms-grid-row: 3;
                    -ms-grid-column: 3;
                }
            }
        }

        &.perf-right {
            -ms-grid-columns: $column-width-max $column-margin-right $column-width-max * 2 + $column-margin-right;
            grid-template-columns: $column-width-max $column-width-max * 2 + $column-margin-right;

            .slot {
                &:nth-child(1).col-1 {
                    -ms-grid-row: 1;
                    -ms-grid-column: 1;
                }

                &:nth-child(2).col-2 {
                    -ms-grid-row: 1;
                    -ms-grid-row-span: 3;
                    -ms-grid-column: 3;
                }

                &:nth-child(3).col-1 {
                    -ms-grid-row: 3;
                }
            }
        }

        .widget-box-empty-container {
            position: relative;
            max-height: 41.8rem;
        }

        @media all and (max-width: 1458px) {
            &.col-1 {
                max-width: 50% - $column-margin-right;
            }

            &.col-3 {
                -ms-grid-columns: 50% - $column-margin-right $column-margin-right 50% - $column-margin-right;
                grid-template-columns: 50% - $column-margin-right 50% - $column-margin-right;

                .slot.home-page-slot.col-2 {
                    grid-column-start: 1;
                    grid-column-end: 3;
                }
            }

            &.perf-left .slot {
                &:nth-child(1).col-2 {
                    -ms-grid-row: 1;
                    -ms-grid-row-span: 1;
                    -ms-grid-column: 1;
                    -ms-grid-column-span: 4;
                }

                &:nth-child(2).col-1 {
                    -ms-grid-row: 3;
                    -ms-grid-column: 1;
                }

                &:nth-child(3).col-1 {
                    -ms-grid-row: 3;
                    -ms-grid-column: 3;
                }
            }

            &.perf-right .slot {
                &:nth-child(1).col-1 {
                    -ms-grid-row: 3;
                    -ms-grid-column: 1;
                }

                &:nth-child(2).col-2 {
                    -ms-grid-row: 1;
                    -ms-grid-row-span: 1;
                    -ms-grid-column: 1;
                    -ms-grid-column-span: 4;
                }

                &:nth-child(3).col-1 {
                    -ms-grid-row: 3;
                    -ms-grid-column: 3;
                }
            }
        }
    }
}
