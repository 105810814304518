$env: false;
@import '../../styles/variables';
@import '../../styles/colors';

.security-selector {
    &-content {
        position: relative;
    }

    &-container {
        display: flex;
    }

    .issuers-list {
        min-width: 30rem;
    }

    .securities-list {
        min-width: 48rem;
    }

    .list {
        color: $color-text-label;
        font-size: 1.4rem;
        line-height: 2.86;
        border: $border-dark;

        &-item {
            border-bottom: $border;
            padding: 0 .8rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &:hover,
            &-selected {
                background-color: $color-item-highlight;
            }

            &-text {
                cursor: default;
            }
        }

        .issuer-title {
            padding-left: 2rem;
            font-weight: $font-weight-bold;
            color: $_color-black;
        }

        &:not(:last-child) {
            margin-right: 1rem;
        }
    }

    .top-panel {
        display: flex;
        align-items: flex-end;
        padding-bottom: .6rem;

        .security-modal-title {
            flex: 1 0 auto;

            .title {
                margin: 0;
                display: inline-block;
            }

            .message-note {
                margin: 0 .6rem;
            }
        }

        .security-filter {
            position: relative;
            flex: none;
            display: flex;
            align-items: center;

            .font-icon-search {
                position: absolute;
                right: .5rem;
                top: .8rem;
                font-size: $font-size-x-large;

                &.disabled {
                    cursor: inherit;
                    color: $color-text-muted;
                }
            }

            &-input {
                width: 27.8rem;
                padding-right: 3rem;
            }
        }
    }

    .action-panel {
        margin: 1.5rem 0 0;
        display: flex;
        align-items: center;

        .action-group {
            display: flex;
            align-items: center;
            width: 100%;

            .btn:last-child {
                margin-left: auto;
            }
        }

        .checkbox-component {
            margin-left: 1rem;
            flex: 1;
        }

        .proxy-campaigns {
            flex-grow: 0;
        }
    }

    ::-webkit-scrollbar-track {
        background-color: transparent;
    }
}
