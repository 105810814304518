@charset "UTF-8";

@import 'colors';
@import 'variables';

$icon-version: 6;
$v: '?v=' + $icon-version;

@font-face {
    font-family: $icon-font-name;
    src:
        url($icon-font-path + "#{$icon-font-name}.woff" + $v) format('woff'),
        url($icon-font-path + "#{$icon-font-name}.ttf" + $v) format('truetype'),
        url($icon-font-path + "#{$icon-font-name}.svg#ast-font" + $v) format('svg');
    font-weight: normal;
    font-style: normal;
}

%font-icon {
    display: inline-block;
    font-family: $icon-font-name !important;
    font-size: $icon-base-size;
    color: $_color-marron;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    cursor: default;
}

.font-icon {
    @extend %font-icon;

    &.inactive {
        cursor: default;
        opacity: $icon-inactive-opacity;
    }

    &:not(.spinner-item) {
        animation: none;
    }

    &-add-circle:before {
        content: '\e900';
    }

    &-add-circle-outline:before {
        content: '\e901';
    }

    &-add-to-report:before {
        content: '\e902';
    }

    &-arrow-drop-down:before {
        content: '\e903';
    }

    &-arrow-left:before {
        content: '\e904';
    }

    &-arrow-right:before {
        content: '\e905';
    }

    &-arrow-up:before {
        content: '\e906';
    }

    &-attachment:before {
        content: '\e907';
    }

    &-avp-chart:before {
        content: '\e908';
    }

    &-bookmark:before {
        content: '\e909';
    }

    &-chart:before {
        content: '\e90a';
    }

    &-chart-box:before {
        content: '\e90b';
    }

    &-checkbox-checked:before {
        content: '\e90c';
    }

    &-checkbox-no-checked:before {
        content: '\e90d';
    }

    &-checked-circle:before {
        content: '\e90e';
    }

    &-clear:before {
        content: '\e90f';
    }

    &-collapse:before {
        content: '\e910';
    }

    &-comments:before {
        content: '\e911';
    }

    &-contact:before {
        content: '\e912';
    }

    &-delete:before {
        content: '\e913';
    }

    &-download:before {
        content: '\e914';
    }

    &-edit:before {
        content: '\e915';
    }

    &-entities:before {
        content: '\e916';
    }

    &-event:before {
        content: '\e917';
    }

    &-event-add:before {
        content: '\e918';
    }

    &-events:before {
        content: '\e919';
    }

    &-expand:before {
        content: '\e91a';
    }

    &-filter:before {
        content: '\e91b';
    }

    &-funds:before {
        content: '\e91c';
    }

    &-image:before {
        content: '\e91d';
    }

    &-info:before {
        content: '\e91e';
    }

    &-info-outline:before {
        content: '\e91f';
    }

    &-insider:before {
        content: '\e920';
    }

    &-institution:before {
        content: '\e921';
    }

    &-link:before {
        content: '\e922';
    }

    &-list:before {
        content: '\e923';
    }

    &-local-printshop:before {
        content: '\e924';
    }

    &-more-horiz:before {
        content: '\e925';
    }

    &-next:before {
        content: '\e926';
    }

    &-note:before {
        content: '\e927';
    }

    &-note-add:before {
        content: '\e928';
    }

    &-note-empty:before {
        content: '\e929';
    }

    &-notifications:before {
        content: '\e92a';
    }

    &-performance:before {
        content: '\e92b';
    }

    &-person-add:before {
        content: '\e92c';
    }

    &-question:before {
        content: '\e92d';
    }

    &-radio-no-selected:before {
        content: '\e92e';
    }

    &-radio-selected:before {
        content: '\e92f';
    }

    &-refresh:before {
        content: '\e930';
    }

    &-registered:before {
        content: '\e931';
    }

    &-search:before {
        content: '\e932';
    }

    &-subject:before {
        content: '\e933';
    }

    &-upload:before {
        content: '\e934';
    }

    &-view:before {
        content: '\e935';
    }

    &-view-list:before {
        content: '\e936';
    }

    &-view-pie-chart:before {
        content: '\e937';
    }

    &-view-three-columns:before {
        content: '\e938';
    }

    &-warning:before {
        content: '\e939';
    }

    &-paging-left:before {
        content: '\e93a';
    }

    &-paging-right:before {
        content: '\e93b';
    }

    &-double-paging-left:before {
        content: '\e93c';
    }

    &-double-paging-right:before {
        content: '\e93d';
    }

    &-transactions:before {
        content: '\e93e';
    }

    &-grid:before {
        content: '\e93f';
    }

    &-uncheck:before {
        content: '\e940';
    }

    &-check:before {
        content: '\e941';
    }

    &-archive:before {
        content: '\e942';
    }

    &-add-group:before {
        content: '\e943';
    }

    &-globe:before {
        content: '\e944';
    }

    &-remove:before {
        content: '\e945';
    }

    &-company-group:before {
        content: '\e946';
    }

    &-security-group:before {
        content: '\e947';
    }

    &-combine:before {
        content: '\e948';
    }

    &-compare:before {
        content: '\e949';
    }

    &-authorized:before {
        content: '\e94a';
    }

    &-upload-file:before {
        content: '\e94b';
    }

    &-alerts:before {
        content: '\e94c';
    }
}
