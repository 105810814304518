$env: false;
@import '../../../styles/colors';
@import '../../../styles/variables';

.widget-corporate-shareholders {
    .pie-content-container {
        position: relative;
        padding: .5rem 0;
        height: 16rem;
    }

    .legend-container {
        padding: 0;
    }

    .table-container {
        padding: 1rem 0 0;
        margin-bottom: 3rem;
    }
}
