$env: false;
@import '../../../styles/variables';

.widget-contact-details {
    min-width: 87rem;
    min-height: 40rem;
    padding: 2.4rem 3.6rem;
    line-height: 2.4rem;

    .widget {
        &-content {
            padding-top: 2.4rem;
        }

        &-title-container {
            display: flex;
            align-items: baseline;
        }

        &-title {
            font-size: $font-size-x-large;
        }

        &-header {
            .back-arrow {
                font-size: $font-size-medium;
                font-weight: $font-weight-regular;
                margin-bottom: 1.2rem;
            }
        }

        &-control-panel {
            display: flex;
            align-items: flex-end;
        }
    }

    .container {
        display: flex;
    }

    .column {
        min-width: 20rem;

        &.main {
            min-width: 60rem;
            max-width: 70rem;
            margin-right: 2.4rem;
        }
    }

    .segment {
        &:not(:last-child) {
            margin-bottom: 4.8rem;
        }

        .title {
            color: $color-text;
            margin-top: 0;
            margin-bottom: 1.2rem;
        }

        .text-block-item:not(:last-child) {
            margin-bottom: 2rem;
        }
    }

    .company-info {
        font-size: $font-size-small;

        &-table {
            border-spacing: 0;
            width: 30rem;
            margin-bottom: .8rem;

            td {
                border-bottom: $border;
                height: 2.4rem;
                line-height: 1;

                &:last-child {
                    text-align: right;
                }
            }
        }

        .aum-label {
            font-weight: $font-weight-bold;
        }
    }

    .fund-list {
        .link {
            display: block;
        }
    }
}
