/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
.reporting-downloads-page .reporting-header {
  line-height: 4.8rem;
}
.reporting-downloads-page .reporting-section .ReactVirtualized__Table__headerColumn.ReactVirtualized__Table__sortableHeaderColumn {
  justify-content: center;
}
.reporting-downloads-page .message-note {
  margin: 0 0 0.5rem;
}
.reporting-downloads-page .report-description:not(.inactive) {
  cursor: pointer;
}
.reporting-downloads-page .font-icon-download,
.reporting-downloads-page .font-icon-delete {
  font-size: 2.4rem;
}
.reporting-downloads-page .font-icon-download:not(.inactive),
.reporting-downloads-page .font-icon-delete:not(.inactive) {
  cursor: pointer;
}