$env: false;
@import '../../styles/variables';

.icon-date-picker {
    .font-icon {
        font-size: 2.4rem;
        cursor: pointer;
        vertical-align: middle;
        margin-left: .5rem;
    }

    .react-datepicker-popper {
        transform: translate3d(-15%, 20px, 0) !important;
    }
}
