$env: false;
@import '../../../styles/variables';

.search {
    align-items: center;
    display: flex;
    position: relative;
    margin: 0 1rem;

    .search-input {
        width: 100%;

        .text-input-label.horizontal {
            margin-right: 0;
        }

        .text-input {
            width: 100%;

            input {
                width: 100%;
                padding-right: 3.2rem;
            }
        }
    }

    .font-icon-search {
        font-size: $icon-large-size;
        position: absolute;
        right: .5rem;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;

        &.disabled {
            cursor: auto;
            opacity: $icon-inactive-opacity;
        }
    }

    .spinner-container {
        position: absolute;
        right: 3rem;
        top: 50%;
        transform: translateY(-50%);
    }
}
