@import '../colors';
@import '../mixins';
@import '../variables';

.modal {
    position: relative;
    overflow: auto;
    max-height: 90vh;
    min-width: 43rem;
    max-width: 112rem;
    padding: $modal-inner-padding;
    background: $color-modal-bg;

    @include border-rounded;
    // Prevent Chrome on Windows from adding a focus outline. For details, see
    // https://github.com/twbs/bootstrap/pull/10951
    outline: none;

    &-overlay {
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $color-modal-overlay;
        z-index: $zindex-modal-overlay;
    }

    &-header {
        display: flex;
        flex-direction: row;
        align-items: center;

        &-left {
            margin: 0;
            flex: 1 0 auto;
        }
    }

    &-message {
        margin: 2.4rem 0;
    }

    &-actions {
        margin-bottom: 1rem;

        &:before {
            display: block;
            content: '';
            height: 1px;
            background-color: $_color-pale-grey;
            margin: 2.4rem .2rem;
        }

        .btn {
            font-size: 1.6rem;
            font-weight: $font-weight-semi-bold;
            min-width: 8.3rem;

            &:not(:first-child) {
                margin-left: 2.4rem;
            }
        }
    }

    .close {
        cursor: pointer;
        font-size: $font-size-x-large;
        position: relative;
        left: .5rem;
    }

    .export-large-report-modal,
    .modal-widget-conflict {
        width: 38rem;
    }

    .exceed-modal {
        width: 41rem;

        .modal-message {
            margin-top: 0;
        }
    }
}
