$env: false;
@import '../../../../styles/colors';
@import '../../../../styles/variables';

.reporting-shareholder-list-search-page {
    .reporting-edit {
        margin-bottom: 2.4rem;
    }

    .securities-shareholders-container {
        display: flex;
        gap: 64px;
    }

    .reporting-section {
        margin-bottom: 10px;
    }

    .reporting-section-body {
        margin: 0;

        .selector.securities-selector {
            min-width: 45rem;
        }
    }

    .reporting-section-records {
        display: flex;
        align-items: center;
        margin: 2.4rem 0;

        .text-input {
            margin-left: .8rem;

            input[type='text'] {
                max-width: 10rem;
            }
        }
    }

    .expand-collapse-all-section {
        margin-bottom: 1rem;
    }

    .expand-collapse-all-text {
        display: flex;
        align-items: center;
        color: #941f40;
        cursor: pointer;
        width: fit-content;

        & i {
            margin-top: 4px;
            cursor: pointer;
            font-size: 2rem;
        }
    }

    .shares-owned-range {
        .checkbox-label {
            font-weight: $font-weight-regular;
            margin: 0 2.4rem 0 .8rem;
        }

        .input-container {
            display: inline-block;

            input[type='text'] {
                max-width: 16.4rem;
            }

            &:not(:last-child) {
                margin-right: 1.2rem;
            }
        }

        .text-input-label {
            margin-right: .6rem;
        }
    }

    .date-range {
        display: inline-block;
        margin-bottom: 2.4rem;

        .checkbox-component {
            margin-right: 3.2rem;
        }
    }

    .date-of-last-contact {
        display: flex;
        align-items: center;

        .checkbox-label {
            font-weight: $font-weight-regular;
            margin: 0 2.4rem 0 .8rem;
        }
    }

    .address-status {
        display: flex;
        flex-direction: column;
        width: 24rem;

        &-label {
            font-size: $font-size-small;

            &.disabled {
                opacity: $icon-inactive-opacity;
                cursor: default;
            }
        }

        &-selector {
            padding-left: 0;
        }
    }

    .reporting-include-section {
        display: flex;
        margin-top: 0.5rem;
    }

    .reporting-include-column {
        &:not(:last-child) {
            margin-right: 4.8rem;
        }

        .holder-columns-section-title {
            margin-bottom: 1.2rem;
            color: $color-header;
            font-weight: bold;

            &.disabled {
                opacity: .65;
            }
        }

        .select-all-checkbox {
            border-bottom: 1px solid $color-header;
        }

        .holder-columns {
            display: flex;
        }

        .holder-column {
            margin-right: 4.8rem;
        }

        .checkbox-label {
            font-weight: normal;
        }

        .address-type {
            display: flex;
            align-items: center;
        }

        .address-type-selector {
            min-width: 17.5rem;
            padding: 0 .5rem;
        }
    }

    .reporting-section-sort {
        display: flex;
        align-items: center;

        .reporting-section-sort-body {
            margin: 0 1.2rem;
        }

        .reporting-sort-field {
            display: flex;
            align-items: center;
        }

        .selector {
            margin-right: 2.6rem;
        }
    }

    .location-container {
        .location-row {
            display: flex;
            align-items: center;
            margin: 20px 0;
        }

        .checkbox-label {
            font-weight: $font-weight-regular;
            margin: 0 2.8rem 0 .8rem;
        }

        .location-cell {
            &:not(:first-child) {
                margin-left: 20px;
            }
        }

        .select-container {
            .selector {
                padding-left: 0;
            }

            .Select-loading-zone {
                cursor: default;
            }
        }

        .checkbox-container {
            margin-top: 20px;
        }

        .location-label {
            font-size: $font-size-small;
        }

        .location-text-input-container {
            .text-input {
                padding: 5px 5px 5px 0;
                display: block;
                min-width: 23.8rem;

                & > input {
                    width: 100%;
                }
            }
        }

        .location-select-wrapper {
            display: block;
            min-width: 23.8rem;
        }
    }

    .font-icon-paging-right {
        margin-left: .3rem;
        font-size: 1rem;
        cursor: pointer;
    }

    .icon-rotate {
        transform: rotate(90deg);
    }

    .expanded {
        display: none;
    }

    .ast-popover {
        margin-top: 4px;
        height: 20px;
        width: 20px;

        & i {
            font-size: 2rem;
        }
    }

    .shareholder-list-actions {
        border-top: $border;
        padding: 2.4rem 0 0;
        margin-top: 4.8rem;
        display: flex;
        align-items: center;

        .btn {
            margin: 0 2rem 0 0;
        }
    }

    .shareholder-list-section-header {
        font-weight: 700;
        line-height: 2;
        color: #000;
        display: flex;
        align-items: center;

        & .ast-popover {
            height: 20px;
            width: 20px;
            margin-top: 0;
            margin-right: 4px;

            & i {
                font-size: 2rem;
            }
        }
    }
}
