$env: false;
@import '../../../../styles/colors';
@import '../../../../styles/variables';

.calendar-action-panel {
    display: flex;
    align-items: center;
    padding-top: 1rem;

    .message-note {
        margin-left: 1rem;

        &.alert-note {
            color: $color-primary;
            font-weight: $font-weight-bold;
        }
    }

    .exchange-info {
        font-weight: $font-weight-bold;
        margin-left: auto;
        max-width: 25rem;
    }
}
