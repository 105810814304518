/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
.security-list-page {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
}
.security-list-page .title {
  margin-bottom: 0;
}
.security-list-page .title.disabled {
  opacity: 0.65;
}
.security-list-page .central-block {
  margin-top: 2.4rem;
}
.security-list-page .security-title {
  display: flex;
  align-items: baseline;
  font-size: 1.8rem;
  color: #6b6056;
}
.security-list-page .security-title .message-note {
  padding-left: 1.2rem;
}
.security-list-page .security-title .message-note.right {
  padding-left: 15rem;
}
.security-list-page .action-panel {
  margin: 1.5rem 0;
  display: flex;
  align-items: center;
}
.security-list-page .action-panel .action-group {
  flex-grow: 1;
  display: flex;
  align-items: baseline;
}
.security-list-page .action-panel .checkbox-component {
  margin-left: 1rem;
  flex-grow: 1;
}
.security-list-page .no-securities {
  text-align: center;
}
.security-list-page .search-by-security-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: auto;
  width: 65rem;
  background-color: #f8f6f4;
  position: relative;
}
.security-list-page .search-by-security-container .search-by-security-selector .form-error-summary {
  right: 12.5rem;
  position: absolute;
  top: 18.5rem;
}
.security-list-page .search-by-security-container .search-by-security-selector .form-error-summary .arrow {
  position: absolute;
  top: -0.5rem;
  left: 25.7rem;
  border-width: 0 5px 5px;
  border-bottom-color: #ef4e37;
  border-top-color: transparent;
  border-right-color: transparent;
}