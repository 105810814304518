/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
.shareholder-contacts-modal {
  min-height: 20rem;
}
.shareholder-contacts-modal .modal-header-left {
  padding-right: 10rem;
}
.shareholder-contacts-modal__content {
  margin: 2.4rem 0;
}
.shareholder-contacts-modal__actions {
  vertical-align: middle;
}
.shareholder-contacts-modal__actions .font-icon {
  font-size: 2.4rem;
  cursor: pointer;
  margin-left: 1rem;
}
.shareholder-contacts-modal .shareholder-contact-details {
  border-bottom: 1px solid #e9e3dc;
  break-inside: avoid;
}
.shareholder-contacts-modal .shareholder-contact-details:last-child {
  border-bottom: none;
}

@media print {
  .shareholder-contacts-modal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-width: none;
    max-height: none;
    border: none;
  }
  .shareholder-contacts-modal__actions {
    display: none;
  }
  .shareholder-contacts-modal .modal-header .close {
    display: none;
  }
}