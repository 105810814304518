/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
.registered-pagination {
  margin-bottom: 1rem;
  flex-direction: row-reverse;
  display: flex;
}
.registered-pagination .right-aside {
  font-size: 1.2rem;
  color: #6b6056;
  display: flex;
  align-items: center;
}