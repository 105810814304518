$env: false;
@import '../../../../../styles/variables';

.shareholders-table {
    .action-panel {
        display: flex;
        flex-wrap: wrap;
        text-align: right;
        margin-bottom: 1rem;

        .actions-container {
            display: flex;
            flex: 1;
            flex-wrap: wrap;
        }

        .message-notes-container {
            display: flex;
            flex-direction: column;

            .message-note.text-left {
                line-height: 2.8rem;
            }
        }

        .actions-group {
            margin-left: auto;
        }

        .font-icon {
            font-size: $icon-large-size;
        }

        .actions {
            display: inline-block;
            margin-left: 1rem;

            .font-icon {
                margin-left: .6rem;
                line-height: 3.6rem;
                vertical-align: middle;

                &:not(.inactive) {
                    cursor: pointer;
                }
            }
        }

        .icon-remove {
            margin-left: 1rem;

            &:not(.inactive) {
                cursor: pointer;
            }
        }
    }
}
