$env: false;
@import '../../../../../styles/variables';

.view-notes-link {
    color: $color-link;
    cursor: pointer;
    font-size: $font-size-medium;
    font-weight: $font-weight-regular;
    margin-left: 2rem;

    &:hover {
        color: $color-link-hover;
    }
}
