@import '../../styles/variables';
@import '../../styles/colors';
@import '../../styles/mixins';

$datepicker-font-size: 1.2rem;

.react-datepicker {
    font: inherit;
    font-size: $datepicker-font-size;
    background-color: $color-datepicker_bg;
    color: $color-text;
    border-radius: 0;
    box-shadow: 0 0 .5rem 0 rgba(0, 0, 0, .2);
    border: $border;
    width: 23.3rem;
    padding-bottom: 2rem;
    padding-top: .5rem;

    &__header__dropdown {
        margin-bottom: .6rem;
    }

    &__header {
        background-color: $color-datepicker_bg;
        border: none;
    }

    &__current-month {
        font-size: $datepicker-font-size;
        font-weight: $font-weight-bold;
        margin-bottom: .6rem;
    }

    &__navigation {
        top: 1.5rem;

        &--previous,
        &--next {
            font-family: $icon-font-name;
            color: $color-primary;
            display: block;
            border: none;
            font-size: $font-size-x-large;
        }

        &--previous {
            left: 1.5rem;

            &:before {
                content: '\e904';
            }
        }

        &--next {
            right: 3.6rem;

            &:before {
                content: '\e905';
            }
        }
    }

    &__month-container {
        float: none;
        display: block;
        margin: 0 auto;
    }

    &__month {
        margin: 0;
    }

    &__day-names {
        line-height: 0;
    }

    &__day-name,
    &__day {
        width: 2.7rem;
        line-height: 2.7rem;
    }

    &__day-name {
        border: $border;
        border-radius: 0;
        padding: 0 0 0 .8rem;
        margin: 0;
        background: $color-table-header-bg;
        font-weight: $font-weight-bold;
        letter-spacing: 2.6rem;
        overflow: hidden;
    }

    &__day {
        margin: 0;
        border: $border;
        border-top: none;

        @if ($env) {
            border: 0;
        }

        &:hover {
            background-color: $color-datepicker_hover;
        }

        &:not(&--today):hover {
            background-color: $color-datepicker_hover;
            color: $color-text-inverse;
        }

        @if ($env) {
            &:not(&--today):not(&--range-start):not(&--range-env):hover {
                background-color: #ecf1ff;
                color: $color-default;
                border-radius: 50%;
            }
        }

        &--today {
            font-weight: $font-weight-bold;
            color: $color-datepicker_today;
            background-color: $color-datepicker_today_bg;

            @if ($env) {
                position: relative;
                color: #0c41c9;
                background-color: transparent;

                &:before {
                    position: absolute;
                    content: ' ';
                    width: 100%;
                    height: 100%;
                    display: block;
                    border: 1px solid #0c41c9;
                    border-radius: 14px;
                    box-sizing: border-box;
                }
            }
        }

        &--outside-month {
            color: $color-text-muted;
        }

        &--highlighted {
            background-color: $color-datepicker_highlighted_bg;
            color: $color-datepicker_highlighted;
        }

        &--in-selecting-range,
        &--in-range {
            background-color: $color-datepicker_selected_bg;
            color: $color-datepicker_selected;

            @if ($env) {
                background-color: #ecf1ff;
                color: $color-primary;
                border-radius: 0;
            }
        }

        &--selected:not(&--in-range) {
            @if ($env != true) {
                background-color: $color-datepicker_selected_bg;
                color: $color-datepicker_selected;
            }

            @if ($env) {
                color: $color-text-inverse;
                background: #0c41c9;
                border-radius: 50%;
            }
        }

        @if ($env) {
            &--range-start,
            &--range-start:not(&--in-selecting-range):has(&--in-range),
            &--range-end,
            &--range-end:not(&--in-selecting-range):has(&--in-range), {
                color: $color-text-inverse !important;
                background-color: #ecf1ff !important;

                span {
                    z-index: 0;
                    border-radius: 50%;
                    background: #0c41c9;
                    display: block;
                    width: 100%;
                    height: 100%;
                }
            }

            &--range-start {
                border-radius: 50% 0 0 50%;
            }

            &--range-end {
                border-radius: 0 50% 50% 0;
            }
        }

        &--in-selecting-range:not(&--in-range) {
            background-color: lighten($color-datepicker_selected_bg, 20%);

            @if ($env) {
                background-color: #ecf1ff;
                color: $color-primary;
            }

            &:hover {
                background-color: $color-datepicker_hover;
            }
        }

        &--disabled {
            cursor: default;
            color: $color-datepicker_disabled;

            @if ($env) {
                color: #d0d4dc;
            }
        }

        &--disabled:hover {
            background-color: transparent;
        }
    }

    &-popper {
        z-index: $zindex-datepicker;
    }

    &-popper[data-placement^='bottom'] &__triangle {
        border-bottom-color: $color-datepicker_bg;
        border-top-color: $color-datepicker_bg;

        &:before {
            border-bottom-color: $color-border;
        }
    }

    &-popper[data-placement^='top'] &__triangle {
        border-top-color: $color-datepicker_bg;

        &:before {
            border-top-color: $color-border;
        }
    }

    &__year-read-view--down-arrow,
    &__month-read-view--down-arrow {
        border-top-color: $color-icon;
    }

    &__month-read-view:nth-child(2) {
        display: none;
    }

    &__year-option,
    &__month-option {
        line-height: 20px;
        width: 100%;
        display: block;
        margin-left: auto;
        margin-right: auto;
        background: $color-collapse-panel-bg;

        &:first-of-type,
        &:last-of-type {
            height: 2rem;
        }

        &:hover {
            background-color: $color-datepicker_selected_bg;
            color: $color-datepicker_selected;
        }
    }

    &__year-dropdown,
    &__month-dropdown {
        background-color: $color-collapse-panel-bg;
        border-radius: $border-radius;
        border: $border;
        width: 60%;
        left: 20%;

        &--scrollable {
            height: 24rem;
        }
    }

    &__navigation--years-previous {
        top: .8rem;
        border-top-color: $color-icon;
    }

    &__navigation--years-upcoming {
        top: .3rem;
        border-bottom-color: $color-icon;
    }

    ::-webkit-scrollbar-track {
        background-color: $color-collapse-panel-bg;
    }
}

.date-picker-component {
    display: inline-block;
    position: relative;

    &.inline {
        .date-picker-label {
            & + div {
                display: inline-block;
            }
        }
    }

    .date-picker-label {
        font-size: $font-size-base;
        color: $color-text;
        font-weight: $font-weight-regular;
        margin: 0 .6rem;
        user-select: none;

        &.disabled {
            opacity: .5;
        }
    }

    .date-picker-input {
        position: relative;
        display: inline-block;
        max-width: 16.4rem;

        input {
            padding-right: 3rem;
            width: 100%;
            z-index: 10;

            &::placeholder {
                font-style: italic;
            }

            &:not(:disabled) {
                background-color: $_color-white;
                cursor: pointer;
            }

            &.error {
                background-color: $color-invalid-input-background;
                border-color: $color-error-background;
            }
        }

        .placeholder {
            display: none;
        }

        .font-icon-events {
            font-size: $font-size-large;
            position: absolute;
            right: .7rem;
            top: 1.1rem; // (inputHeight(40) - iconHeight(18)) / 2

            &:not(.inactive) {
                cursor: pointer;
            }
        }
    }
}

body.ie {
    .react-datepicker {
        &__month-read-view--down-arrow {
            top: -1rem;
        }

        &__month-read-view--selected-month,
        &__year-read-view--selected-year {
            margin-right: 1.5rem;
        }
    }

    .date-picker-component {
        .date-picker-input {
            input {
                &:-ms-input-placeholder {
                    color: $color-text-placeholder;
                }

                &:focus ~ .placeholder.empty {
                    display: block;
                    position: absolute;
                    top: .9rem;
                    left: .9rem;
                    font-style: italic;
                    color: $color-text-placeholder;
                }
            }
        }
    }
}
