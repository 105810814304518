$env: false;
@import '../../styles/variables';

.slot {
    position: relative;
    width: 100%;
    min-height: 20rem;
    flex: 0 1 auto;

    &.col-1 {
        max-width: $column-width-max;
        min-width: $column-width-min;
        margin-bottom: $column-margin-bottom;
        margin-right: $column-margin-right;
    }

    &.col-2 {
        max-width: $column-width-max * 2 + $column-margin-right;
        min-width: calc(#{$column-width-min} * 2 + #{$column-margin-right});
        margin-bottom: $column-margin-bottom;
        margin-right: $column-margin-right;
    }

    &.col-3 {
        width: $column-width-max * 3 + $column-margin-right * 2;
        margin-bottom: $column-margin-bottom;
        margin-right: $column-margin-right;
    }
}
