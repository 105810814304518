$env: false;
.export-shareholder-contacts-modal {
    .format-container {
        .form-section-title {
            font-weight: 700;
            margin: 2.5rem 0 1rem;
        }

        .radio-label {
            font-weight: 400;
            color: #000;
        }
    }
}
