//names from http://chir.ag/projects/name-that-color/

/* private variables */
$_color-black:              #000;

$_color-blue:               #008ad0;
$_color-green:              #599f08;
$_color-wenge:              #6b6056;
$_color-sand-dune:          #7f7369;
$_color-gray:               #7f7f7f;
$_color-americano:          #847a72;
$_color-marron:             #941f40;
$_color-ast-taupe:          #988a7d;
$_color-night-shadz:        #a73251;
$_color-pistachio:          #accf83;
$_color-dark-gray:          #b2a89d;
$_color-cloudy:             #b4aea9;
$_color-rose:               #b8697f;
$_color-pale-silver:        #cbc4be;
$_color-cherry:             #d81a32;
$_color-marron-light:       #dcb6c1;
$_color-gainsboro:          #e0dbd8;
$_color-alizarin-crimson:   #e23240;
$_color-carmine-pink:       #e56676;
$_color-pale-grey:          #e9e3dc;
$_color-cararra:            #eae8e5;
$_color-vermillion:         #ef4e37;
$_color-isabelline:         #efeae5;
$_color-orange:             #f37721;
$_color-pale-pink-two:      #f4f1ed;
$_color-sandy:              #f7a46b;
$_color-white-smoke:        #f8f6f4;
$_color-marigold:           #fcb633;
$_color-topaz:              #fdcf77;
$_color-pale-pink:          #fddfdb;
$_color-white:              #fff;
$_color-tundora:            #4a4a4a;
$_color-sky:                #ebf5ff;

@if ($env) {
    $_color-wenge:          #3b485d;
    $_color-marron:         #19316f;
    $_color-orange:         #19316f;
    $_color-vermillion:     #0c41c9;
    $_color-pale-pink-two:  #f9f9fb;
    $_color-pale-grey:      #f5f8ff;
    $_color-white-smoke:    #f9f9fb;
    $_color-sand-dune:      #b6bbc6;
}

/* public variables */
$color-default:             $_color-black !default;
$color-primary:             $_color-marron !default;
$color-positive:            $_color-green !default;
$color-negative:            $_color-alizarin-crimson !default;

/* Link */
$color-link:                $_color-marron !default;
//$color-link:                #AD8993; // temporar !defaulty
$color-link-hover:          $_color-vermillion !default;

/* Icons */
$color-icon:                $_color-marron !default;
//$color-icon:                #AD8993 !default;
$color-icon-inverse:        $_color-white !default;
$color-icon-disabled:       $_color-ast-taupe !default;

/* Text */
$color-text:                $_color-black !default;
$color-text-inverse:        $_color-white !default;
$color-text-muted:          $_color-sand-dune !default;
$color-text-placeholder:    $_color-pale-silver !default;
$color-text-label:          $_color-wenge !default;
$color-text-info:           $_color-wenge !default;
$color-text-list-item:      $_color-ast-taupe !default;

$color-header:              $_color-wenge !default;
$color-aside-bg:            $_color-white-smoke !default;
$color-collapse-panel-bg:   $_color-pale-pink-two !default;

$color-border:              $_color-pale-grey !default;
$color-border-dark:         $_color-pale-silver !default;
$color-line:                $_color-pale-grey !default;

/* Buttons */
$color-btn-text:            $_color-white !default;
$color-btn-default-bg:      $color-primary !default;
$color-btn-default-border:  $color-primary !default;
$color-btn-underline:       $_color-vermillion !default;
$color-btn-shadow:        rgba(0, 0, 0, .2);

/* Inputs */
$color-background-disabled: $_color_white !default;

/* NavPanel and Tabs */
$color-nav-item-active:     $_color-sand-dune !default;
$color-nav-item-hover:      $_color-pale-pink-two !default;
$color-tab-active:          $_color-wenge !default;
$color-tab-bg:              $_color-white !default;
$color-nav-border:          $color-border !default;
$color-nav-link-active:     $color-border !default;

/* Table */
$color-table-outer-border:      $_color-pale-grey !default;
$color-table-inner-border:      $_color-cararra !default;
$color-table-header-bg:         $_color-pale-pink-two !default;
$color-table-header-bg-active:  $_color-pale-grey !default;
$color-table-link:              $_color-alizarin-crimson !default;
$color-specific-column-bg:      $_color-white-smoke !default;
$color-obsolete-position:       $_color-dark-gray !default;
$color-table-column-bg:     rgba(203, 196, 190, .3);

/* Message card */
$color-message-card-bg:         $_color-white !default;

/* Dropdown */
$color-dropdown-bg:             $_color-white !default;
$color-dropdown-bg-active:      $_color-isabelline !default;
$color-dropdown-bg-hover:       $_color-sky !default;

/* Notification panel */
$color-notification-badge-bg:   $_color-orange !default;
$color-notification-badge-te:   $_color-white !default;

/* Intervals */
$color-intervals-active-bg:     $_color-ast-taupe !default;
$color-intervals-active-text:   $color-text-inverse !default;

$color-avatar-outline:          $_color-ast-taupe;

/* Modals */
$color-modal-bg:            $_color-white !default;
$color-modal-overlay:       rgba(100, 100, 100, .5) !default;

/* Spinner */
$color-spinner_bg:          $_color-pale-silver !default;
$color-spinner:             $_color-vermillion !default;

/* Loading-block */
$color-loading-block_bg:    $_color-pale-silver !default;
$color-loading-block:       $_color-vermillion !default;

/* DatePicker */
$color-datepicker_bg:               $_color-white !default;
$color-datepicker_navigations:      $_color-pale-silver !default;
$color-datepicker_week:             $_color-pale-silver !default;
$color-datepicker_today:            $_color-white !default;
$color-datepicker_today_bg:         $_color-orange !default;
$color-datepicker_selected:         $_color-white !default;
$color-datepicker_selected_bg:      $_color-marron !default;
$color-datepicker_highlighted:      $_color-white !default;
$color-datepicker_highlighted_bg:   $_color-marigold !default;
$color-datepicker_disabled:         $_color-pale-grey !default;
$color-datepicker_hover:            $_color-pale-silver !default;
$color-datepicker_options_bg:       $_color-white !default;
$color-datepicker_options_hover:    $_color-pale-grey !default;
$color-datepicker_options_arrow:    $_color-americano !default;

/* Table bar chart */
$color-bar-chart:           $_color-pistachio;

/* Error message */
$color-error-background:    $_color-vermillion;
$color-warning-background:  $_color-alizarin-crimson;
$color-error-text:          $_color-white;

/* Reporting */
$color-reporting-nav-bg:        $_color-white-smoke;
$color-reporting-nav-separator: $_color-isabelline;
$color-started-report-name:     $_color-ast-taupe;
$color-saved-report-title:      $_color-ast-taupe;

/* Error page */
$color-error-code:              $_color-cloudy !default;
$color-error-message:           $_color-black !default;

/* Invalid input */
$color-invalid-input-background: $_color-pale-pink;

/* Widget */
$color-widget-header:       $_color-wenge;
$color-widget-bg:           $_color-white;

/* Shareholder positions */
$shareholder-positions-date: $_color-wenge;

/* Scroll */
$color-scroll:              $_color-pale-silver !default;
$color-scroll-track:        $_color-white !default;

/* Footer */
$color-footer-background:   $_color-cherry;

@if ($env) {
    $color-footer-background:   $_color-marron;
}

/* Highlights */
$color-item-highlight:      $_color-gainsboro;

$color-square:              $_color-blue;
