$env: false;
@import '../../../styles/variables';

.top-shareholders-search-modal {
    overflow: visible;

    &-content {
        padding-top: 1rem;
        width: 41rem;
    }

    .arrow-role {
        font-size: 2rem;
        line-height: 3rem;
    }

    .Select-input {
        width: 100%;
    }

    .Select-option.is-disabled,
    .Select-noresults {
        color: $color-text-muted;
    }
}
