/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
.widget-registered-holder-type .os-total {
  font-size: 1.2rem;
  padding: 0 0 1rem 1.2rem;
}
.widget-registered-holder-type .os-total-text {
  font-weight: 700;
  margin-right: 0.5rem;
}
.widget-registered-holder-type .table-container {
  padding-top: 1rem;
}
.widget-registered-holder-type .pie-content-container {
  padding: 0.5rem 0;
  height: 16rem;
}