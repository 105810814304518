$env: false;
@import '../../../../styles/variables';
@import '../../../../styles/colors';

.widget-full-quote {
    .widget-header {
        font-size: $font-size-x-large;
        font-weight: $font-weight-bold;
    }

    .widget-control-panel {
        display: flex;
        align-items: flex-end;
    }
}

.full-quote-modal {
    width: 53rem;
    padding-top: 1.2rem;

    .widget-header {
        flex-grow: 0;
        margin-right: .6rem;
    }

    .action-icon {
        font-size: 2.9rem;
    }

    .full-quote-content {
        min-width: 48rem;
    }

    .modal-header {
        position: absolute;
        right: 2rem;
        top: 2rem;
        z-index: 1;

        .modal-header-left {
            display: none;
        }

        .close {
            left: auto;
        }
    }
}

.full-quote-content {
    margin: 2.4rem 0 1.2rem;

    .content-title {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        font-size: $font-size-large;

        &-left {
            flex-shrink: 1; // IE 10 fix
            padding-right: 2rem;

            .title {
                font-weight: $font-weight-bold;
            }
        }

        &-right {
            .first-prop {
                font-weight: $font-weight-bold;
            }

            .last-prop {
                margin-left: 1.2rem;
                font-weight: $font-weight-bold;

                &.positive:before {
                    content: '+';
                }

                &.negative:before {
                    content: '-';
                }

                &.neutral {
                    color: $color-positive;
                }
            }
        }
    }
}

.full-quote-table {
    display: flex;
    flex-flow: row wrap;

    .column {
        flex: 1 0 50%;
        max-width: 50%;

        &:first-child {
            padding-right: 1rem;
        }

        &:not(:first-child) {
            padding-left: 1rem;
        }

        &-item {
            display: flex;
            justify-content: space-between;
            height: 2.9rem;
            border-bottom: .1rem solid $_color-pale-grey;
            align-items: center;

            &-name {
                flex: 1 1 auto;
            }

            &-value {
                flex: 1 1 auto;
                font-weight: $font-weight-bold;
                display: inline-block; // IE 10 fix
                text-align: right;
                padding-left: .5rem;
            }
        }
    }

    &-footer {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-top: 1.3rem;

        .market-close-date {
            font-style: italic;
            color: $color-text-info;
        }
    }
}
