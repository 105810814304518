$env: false;
@import '../../../../styles/colors';
@import '../../../../styles/variables';

.top-25-holdings-table {
    .table-view-four {
        overflow-x: auto;
    }

    .heading-cell {
        text-align: center;

        &-position-change {
            display: flex;
            align-items: center;

            .dropdown-wrapper {
                display: flex;

                .dropdown {
                    margin-left: .8rem;
                    font-weight: normal;

                    .font-icon {
                        font-size: 1.6rem;
                    }
                }
            }
        }
    }

    .cell-position-change,
    .cell-os-change {
        background-color: rgba($_color-white-smoke, .5);
    }

    .current-company {
        font-weight: $font-weight-bold;
    }

    .not-selected-period {
        font-weight: normal;
        margin-left: 1rem;
    }

    .ReactTable.without-scroll {
        overflow: visible;
    }
}
