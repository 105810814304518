$env: false;
@import '../../styles/colors';
@import '../../styles/variables';
@import '../../styles/mixins';

.dropdown {
    display: inline-block;

    .dropdown-trigger {
        position: relative;

        @include border-rounded($border-dark);
        border-radius: $border-radius;
        padding: .5rem 2rem .5rem .5rem;
        background-color: $color-dropdown-bg;

        &.placeholder {
            color: $color-text-placeholder;
        }

        &.open {
            border-radius: 0;

            @include border-top-radius($border-radius);
        }

        .arrow-container {
            position: absolute;
            right: 0;
            margin: auto;
            top: 0;
            bottom: 0;
            display: flex;
            align-items: center;
        }

        .font-icon {
            font-size: $icon-large-size;
            cursor: pointer;
        }
    }
}

.dropdowm-menu {
    @include border-rounded($border-dark);
    display: inline-block;
    border-radius: $border-radius;
    border-top: none;
    margin-top: -.2rem;

    .dropdown-option {
        padding: .5rem;
        margin: -.1rem;
        border: $border-dark;
        cursor: pointer;

        &:last-child {
            @include border-bottom-radius($border-radius);
        }

        &:hover,
        &.active {
            background-color: $color-dropdown-bg-active;
        }
    }

    &.popover {
        &.bottom {
            margin-top: 0;
        }

        .popover-content {
            padding: 0;
        }

        .arrow {
            display: none;
        }
    }
}
