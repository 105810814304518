$env: false;
@import '../../../styles/colors';

.widget-placeholder {
    .widget-content {
        border: 3px dashed $color-border;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 30rem;

        font-size: 3rem;
        background-color: $_color-cararra;
    }
}
