/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
.control-books-tabs {
  display: flex;
  flex-direction: row;
  text-align: center;
  margin-top: 2rem;
}
.control-books-tabs .control-books-tab {
  border: 1px solid transparent;
  border-bottom: 1px solid #e9e3dc;
  padding: 1.5rem;
  cursor: pointer;
  color: #941f40;
  font-weight: 400;
  white-space: nowrap;
}
.control-books-tabs .control-books-tab:hover {
  color: #ef4e37;
}
.control-books-tabs .control-books-tab.active {
  border: 1px solid #e9e3dc;
  border-bottom: 1px solid transparent;
  color: #6b6056;
  text-shadow: 0.3px 0 0, -0.3px 0 0;
  cursor: default;
}
.control-books-tabs-overlay {
  flex: 1;
  border-bottom: 1px solid #e9e3dc;
}