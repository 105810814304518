/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
.reporting-share-range-search-page .message-note {
  margin-top: 2.4rem;
}
.reporting-share-range-search-page .reporting-header ~ .message-note {
  font-weight: 300;
  margin: 0;
  padding: 0;
}
.reporting-share-range-search-page .reporting-share-range-search-form {
  margin-top: 2.4rem;
}
.reporting-share-range-search-page .share-range-input-delimiter {
  display: inline-block;
  margin: 3.4rem 1.2rem;
}
.reporting-share-range-search-page .fields-container {
  margin-bottom: 4.8rem;
}
.reporting-share-range-search-page .share-range-input {
  display: inline-block;
  width: 23.8rem;
  margin-bottom: 4.8rem;
}
.reporting-share-range-search-page .share-range-input.block {
  display: block;
}
.reporting-share-range-search-page .share-range-input input {
  width: 23.8rem;
}