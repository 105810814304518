$env: false;
@import '../../styles/variables';

.radio {
    &-component {
        padding: .5rem;

        &.disabled {

            opacity: $field-disabled-opacity;
            cursor: default;
        }
    }

    &-component.inline {
        display: inline-block;
    }

    &-component.block {
        display: block;
    }

    &-input {
        display: none;

        & ~ .font-icon {
            font-size: 2rem;
            vertical-align: middle;
        }

        & ~ .font-icon-radio-no-selected {
            display: inline-block;
        }

        & ~ .font-icon-radio-selected {
            display: none;
        }

        &:checked ~ .font-icon-radio-no-selected {
            display: none;
        }

        &:checked ~ .font-icon-radio-selected {
            display: inline-block;
        }
    }

    &-label {
        margin-left: .5rem;
    }
}
