/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
.auto-suggest-label {
  display: inline-block;
  font-weight: 400;
  font-size: 1.4rem;
  color: #000;
  white-space: nowrap;
  margin-bottom: 0.5rem;
}
.auto-suggest .Select {
  height: 4rem;
}
.auto-suggest .Select-value {
  padding-right: 2rem !important;
}
.auto-suggest .Select-control, .auto-suggest .Select-arrow-zone, .auto-suggest .Select-loading-zone {
  display: flex;
  align-items: center;
}
.auto-suggest .Select-loading-zone {
  width: auto;
}
.auto-suggest .Select-control, .auto-suggest .Select-input, .auto-suggest .Select-multi-value-wrapper {
  height: 100%;
  width: 100%;
}
.auto-suggest .Select-value-label, .auto-suggest .Select-placeholder {
  line-height: 4rem;
}
.auto-suggest .Select-input > input {
  padding: 0.8rem 1.2rem 0.8rem 0;
}
.auto-suggest .Select-arrow-zone {
  cursor: default;
  pointer-events: none;
}
.auto-suggest .Select-arrow-zone .font-icon {
  vertical-align: middle;
}
.auto-suggest .Select-option:not(:last-child) {
  border-bottom: 1px solid #e9e3dc;
}
.auto-suggest .Select-option.is-focused {
  background-color: initial;
}
.auto-suggest .Select.is-focused:not(.is-open) > .Select-control {
  border-color: #e9e3dc;
  box-shadow: none;
}