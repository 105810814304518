/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
.export-historical-analysis-modal {
  width: 48rem;
  min-height: 36rem;
}
.export-historical-analysis-modal .modal-actions {
  margin-bottom: 2rem;
}
.export-historical-analysis-modal .modal-actions:before {
  margin: 3.4rem 0.2rem;
}
.export-historical-analysis-modal .format-container,
.export-historical-analysis-modal .view-type-container {
  margin: 3.8rem 0;
}
.export-historical-analysis-modal .format-container .radio-label,
.export-historical-analysis-modal .view-type-container .radio-label {
  font-weight: normal;
  color: #000;
}
.export-historical-analysis-modal .format-container .format-label {
  font-weight: 700;
  padding: 0 0 1.8rem 0.5rem;
  color: #000;
}
.export-historical-analysis-modal .view-type-container .input-date-range-picker {
  margin: 1.2rem 0 0 2rem;
}