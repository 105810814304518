/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
.error-page {
  position: relative;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding-top: 0.8rem;
  padding-bottom: 8.3rem;
}
.error-page-header {
  text-align: center;
}
.error-page-content {
  flex: 1 0 auto;
  align-items: center;
  align-content: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: calc(100vh - 7.5rem - 16rem);
}
.error-page .error-code {
  font-size: 25rem;
  line-height: 28rem;
  color: #b4aea9;
}
.error-page .error-message-title {
  font-size: 2.4rem;
  font-weight: bold;
  line-height: 2.8rem;
  color: #000;
  text-transform: uppercase;
  margin: 2.4rem 0 4.8rem;
  text-align: center;
}
.error-page .error-message-text {
  line-height: 2.4rem;
  color: #000;
  text-align: center;
}
.error-page .error-message-text p {
  margin: 0;
}
.error-page .error-action {
  margin: 4.8rem 0;
}
.error-page .ast-logo {
  height: 10rem;
}
.error-page .expanded-error {
  text-align: center;
  width: 100%;
  padding: 0 10rem;
}
.error-page .expanded-error-header {
  display: flex;
  justify-content: space-between;
}
.error-page .expanded-error-title {
  text-align: left;
}
.error-page .expanded-error-links {
  display: flex;
  align-items: center;
}
.error-page .expanded-error-links a {
  margin-left: 1.5rem;
}
.error-page-no-issuer-central {
  padding-top: 11rem;
}
.error-page-no-issuer-central .error-page-content {
  min-height: calc(100vh - 7.5rem - 34rem);
}
.error-page-no-issuer-central .error-message-text {
  text-align: left;
  line-height: normal;
  color: #4a4a4a;
  font-size: 2.4rem;
}
.error-page-no-issuer-central .bottom-error-code {
  margin: 2.4rem 0 4.8rem;
  color: #b2a89d;
  font-size: 1.8rem;
}
.error-page-no-issuer-central .ast-logo {
  height: 14rem;
}