/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
.note-details-modal .note-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  margin: 1rem 0;
  border: 0.1rem solid #e9e3dc;
  border-left: 0;
  border-right: 0;
}
.note-details-modal .note-info .info-section {
  display: flex;
  color: #6b6056;
}
.note-details-modal .note-info .info-section .title {
  font-weight: 700;
  margin-right: 1rem;
}
.note-details-modal .note-text-section {
  width: 40rem;
  height: 26rem;
  word-break: break-all;
  overflow-y: auto;
  padding: 0.8rem;
  border: 0.1rem solid #e9e3dc;
  border-radius: 0.8rem;
}
.note-details-modal .modal-actions {
  margin-top: 2.4rem;
}
.note-details-modal .modal-actions:before {
  display: none;
}