$env: false;
@import '../../../styles/variables';
@import '../../../styles/colors';

.widget-shareholder-elections {
    .pie-content-container {
        position: relative;
        padding: .5rem 0;
        height: 16rem;
    }

    .table-container {
        padding: 1rem 0 .1rem;

        &.disable .table-easy {
            opacity: .65;
        }
    }

    .no-dividend-info {
        font-style: italic;
        padding: .5rem 0 0;
    }
}
