@import '../variables';

// Single side border-radius

@mixin border-radius($radius: $border-radius) {
    border-radius: $radius;
}

@mixin border-top-radius($radius) {
    border-top-right-radius: $radius;
    border-top-left-radius: $radius;
}

@mixin border-right-radius($radius) {
    border-bottom-right-radius: $radius;
    border-top-right-radius: $radius;
}

@mixin border-bottom-radius($radius) {
    border-bottom-right-radius: $radius;
    border-bottom-left-radius: $radius;
}

@mixin border-left-radius($radius) {
    border-bottom-left-radius: $radius;
    border-top-left-radius: $radius;
}

@mixin border-rounded($border: $border, $border-radius: $border-radius) {
    border: $border;

    @include border-radius($border-radius);
}
