/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
.control-books-result .widget-header {
  margin-bottom: 1rem;
}
.control-books-result .widget-header .widget-info-container span:not(:first-child) {
  margin-left: 0.5rem;
}
.control-books-result .widget-header .balance-cob-message {
  font-weight: normal;
  font-size: 1.4rem;
  margin-top: 1.5rem;
  color: #6b6056;
}
.control-books-result .widget-control-panel.bordered {
  display: flex;
  align-items: flex-end;
  padding-bottom: 1rem;
  border-bottom: 1px solid #e9e3dc;
  margin-bottom: 1rem;
}
.control-books-result .ReactTable {
  margin-top: 1rem;
}

.control-books-column-manager.common-columns-manager-view {
  max-height: 46rem;
}