$env: false;
@import '../../styles/variables';
@import '../../styles/mixins';

.report-scheduler {

    .font-icon-paging-right {
        margin-left: .3rem;
        font-size: 1rem;
        cursor: pointer;
    }

    .icon-rotate {
        transform: rotate(90deg);
    }

    .collapsed {
        display: none;
    }

    .header-section {
        display: flex;
        margin-bottom: 1.2rem;
        align-items: center;
    }

    display: block;

    &-header {
        font-weight: $font-weight-bold;
        line-height: 2;
        color: $color-default;
    }

    .schedule-type-list {
        margin-top: .5rem;

        .radio-component:first-child {
            padding-left: 0;
        }
    }

    .week-day-list {
        display: flex;
        height: 33px;
        margin-top: .5rem;

        @if ($env) {
            background-color: #f3f4f6;

            @include border-radius(19px);
            border: 2px solid #f3f4f6;
            overflow: hidden;
            height: auto;
        }

        .font-icon {
            display: none;
        }

        .radio-component.block {
            padding: 0;
        }

        .radio-label {
            display: block;
            text-align: center;
            font-weight: $font-weight-regular;
            color: $color-primary;
            width: 4rem;
            border: $border;
            padding: .5rem;
            margin: 0;
        }

        .radio-input:checked ~ .radio-label {
            background-color: $color-text-list-item;
            border-color: $color-text-list-item;
            color: $color-text-inverse;

            @if ($env) {
                background-color: #0c41c9;
                border-color: #0c41c9;

                @include border-radius(19px);
            }
        }
    }

    .month-day-container {
        margin-top: 1rem;
        padding-left: 2.3rem;

        .input-container.month-input {
            margin: 1rem 0;

            .text-input {
                width: 5rem;

                &-label {
                    font-size: $font-size-medium;
                }

                input {
                    width: 100%;
                }
            }
        }
    }

    .report-scheduler-message {
        color: $color-primary;
        font-size: $font-size-small;
        font-weight: $font-weight-bold;
        max-width: 38rem;
    }

    .export-format-container {
        margin-top: 1.5rem;

        .export-format-header {
            font-weight: $font-weight-bold;
            line-height: 2;
        }

        .export-format-list {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }

        .radio-component {
            padding-left: 0;
        }
    }
}
