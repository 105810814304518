$env: false;
@import '../../../../styles/colors';

.shareholder-cell-with-icon {
    display: flex;
    align-items: center;

    .font-icon {
        color: $color-icon-disabled;
        cursor: default;
    }
}
