$env: false;
.column-filter {
    display: inline;
    cursor: pointer;
    position: relative;
    top: .2rem;

    .popover-content .font-icon {
        vertical-align: middle;
    }
}

.filter-lists {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 13rem;

    .checkbox-label {
        .font-icon {
            width: 2.2rem;
            text-align: center;
        }
    }

    .close-btn {
        align-self: flex-end;
        line-height: normal;

        .font-icon {
            font-size: 2rem;
            position: relative;
            left: .5rem;
            cursor: pointer;
        }
    }

    .btn-panel {
        padding-top: .5rem;
    }
}
