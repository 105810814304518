/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
.widget-historical-analysis-table .analysis-table.ReactTable {
  overflow-x: visible;
}
.widget-historical-analysis-table .widget-content {
  padding-top: 1rem;
}
.widget-historical-analysis-table .widget-content .historical-analysis-content {
  min-width: 120rem;
}
.widget-historical-analysis-table .widget-header {
  display: flex;
  align-items: center;
}
.widget-historical-analysis-table .widget-header-container {
  padding-bottom: 2.4rem;
  border-bottom: 1px solid #e9e3dc;
}
.widget-historical-analysis-table .widget-control-panel {
  display: flex;
  align-items: center;
}
.widget-historical-analysis-table .widget-title {
  font-size: 2.4rem;
}
.widget-historical-analysis-table .historical-analysis-chart {
  position: relative;
  height: 31.8rem;
}
.widget-historical-analysis-table .historical-analysis-chart-legend {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  max-width: 40rem;
}
.widget-historical-analysis-table .historical-analysis-chart .colored-square {
  background-color: #008ad0;
  border: 0 solid #008ad0;
}
.widget-historical-analysis-table .historical-analysis-chart .legend-label {
  margin-left: 0.5rem;
  font-size: 1.2rem;
  font-weight: normal;
}
.widget-historical-analysis-table .additional-component {
  display: flex;
  align-items: center;
}
.widget-historical-analysis-table .additional-component .label {
  margin-left: 0.5rem;
  font-size: 1.2rem;
  font-weight: normal;
}