$env: false;
@import '../../../styles/variables';
@import '../../../styles/colors';

$securityListWidth: 65rem;

.security-list-page {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;

    .title {
        margin-bottom: 0;

        &.disabled {
            opacity: .65;
        }
    }

    .central-block {
        margin-top: 2.4rem;
    }

    .security-title {
        display: flex;
        align-items: baseline;
        font-size: $font-size-large;
        color: $color-text-label;

        .message-note {
            padding-left: 1.2rem;

            &.right {
                padding-left: 15rem;
            }
        }
    }

    .action-panel {
        margin: 1.5rem 0;
        display: flex;
        align-items: center;

        .action-group {
            flex-grow: 1;
            display: flex;
            align-items: baseline;
        }

        .checkbox-component {
            margin-left: 1rem;
            flex-grow: 1;
        }
    }

    .no-securities {
        text-align: center;
    }

    .search-by-security-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: auto;
        width: $securityListWidth;
        background-color: $color-aside-bg;
        position: relative;

        .search-by-security-selector {
            .form-error-summary {
                right: 12.5rem;
                position: absolute;
                top: 18.5rem;

                .arrow {
                    position: absolute;
                    top: -.5rem;
                    left: 25.7rem;
                    border-width: 0 5px 5px;
                    border-bottom-color: #ef4e37;
                    border-top-color: transparent;
                    border-right-color: transparent;
                }
            }
        }
    }
}
