$env: false;
@import '../../../styles/variables';
@import '../../../styles/colors';

.search-result-table.ReactTable {
    font-size: $font-size-medium;

    .rt-tr {
        padding: 1rem 1.4rem;

        @if ($env != true) {
            border-bottom: $border;
        }

        .rt-td {
            border-right: none;
            padding-left: .5rem;

            &:first-child {
                padding-left: 0;
            }
        }

        &:hover {
            background-color: initial;

            .rt-td {
                border-right: none;
            }
        }
    }

    .truncated-text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .strong {
        font-weight: $font-weight-bold;
    }
}
