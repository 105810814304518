$env: false;
@import '../../../styles/variables';
@import '../../../styles/colors';

.widget-buys-and-sells {
    .ReactVirtualized__Table__row {
        padding-left: 1.5rem;

        &.grouped-row {
            padding-left: 0;

            .ReactVirtualized__Table__rowColumn:first-child {
                font-weight: $font-weight-bold;
                padding-left: .8rem;
            }
        }

        .ReactVirtualized__Table__rowColumn:first-child {
            padding-left: 2rem;
        }
    }
}
