$env: false;
@import '../../styles/variables';

.checkbox {
    &-component {
        padding: .5rem 0;

        &.disabled {
            opacity: $field-disabled-opacity;
            cursor: default;
        }
    }

    &-component.inline {
        display: inline-block;
    }

    &-component.block {
        display: block;
    }

    &-component.flex {
        display: flex;
    }

    &-input {
        position: absolute;
        overflow: hidden;
        clip: rect(0 0 0 0);
        height: 1px;
        width: 1px;
        margin: -1px;
        border: 0;
        vertical-align: middle;

        & ~ .font-icon {
            font-size: 2rem;
            font-weight: $font-weight-bold;
            vertical-align: middle;
            width: 1.8rem;
            position: relative;
            left: -.2rem;
        }

        & ~ .font-icon-checkbox-no-checked {
            display: inline-block;
        }

        & ~ .font-icon-checkbox-checked {
            display: none;
        }

        &:checked ~ .font-icon-checkbox-no-checked {
            display: none;
        }

        &:checked ~ .font-icon-checkbox-checked {
            display: inline-block;
        }
    }

    &-label {
        display: inline-block; // IE 10 fix
        margin-left: .5rem;
        flex-grow: 1;

        & .font-icon {
            margin-left: .3rem;
        }

        & .label-text {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 1.3rem;
            font-weight: normal;
        }
    }
}
