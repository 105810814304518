$env: false;
@import 'src/styles/variables';

.recent-search {
    .recent-search__header {
        font-weight: $font-weight-bold;
        font-size: $font-size-medium;
        color: $color-header;
        margin-bottom: 1rem;
        display: flex;
        align-items: center;

        .recent-search__title {
            margin-right: .8rem;
        }

        .ast-popover {
            height: $table-icons-size;
        }

        .font-icon {
            font-size: $table-icons-size;
        }
    }

    .recent-search__spinner {
        height: 5.6rem;
    }

    .recent-search__no-result {
        padding-top: .8rem;
        padding-bottom: .8rem;
        height: 5.4rem;
        display: flex;
        align-items: center;
        color: $color-text-muted;
    }

    .recent-search-account {
        display: flex;
        align-items: center;
        padding-top: .8rem;
        padding-bottom: .8rem;

        &:last-child {
            margin-bottom: 0;
        }

        .recent-search-account__icon {
            margin-right: .8rem;
            font-size: $table-icons-size;
            min-width: 3.4rem;
            text-align: center;

            &.inactive {
                color: $color-icon-disabled;
            }
        }

        .recent-search-account__link {
            overflow: hidden;

            &.inactive {
                pointer-events: none;
                color: $color-text-muted;
            }
        }
    }
}

.popover.recent-search-popover {
    width: 30rem;
    font-size: $font-size-small;
}
