$env: false;
@import '../../../styles/colors';
@import '../../../styles/variables';

.shareholders-table {
    %small-header-label {
        font-size: $font-size-x-small;
        font-weight: $font-weight-regular;
    }

    .rt-th {
        white-space: pre-wrap;

        &:first-child {
            display: none;

            & + .rt-th {
                width: 380px !important; // zoom fix ASTTSTRT-19271
                max-width: 38rem;
                padding-left: $table-expander-size + $table-cell-horizontal-padding;
            }
        }
    }

    .rt-tr .rt-td:nth-child(2) {
        max-width: 33.5rem;
    }

    .empty {
        width: 2.4rem;
        height: 2.4rem;
    }

    .cell-with-align-right,
    .cell-with-change,
    .cell-with-currency-usd,
    .cell-with-percent,
    .cell-with-position,
    .cell-with-mkt-val,
    .cell-with-date,
    .cell-with-number {
        width: 100%;
        text-align: right;
    }

    .heading-cell-with-filtration {
        .trigger-wrapper {
            display: inline;
            position: absolute;
            bottom: 1.8rem;
            padding: 0 .5rem;
            vertical-align: sub;
        }
    }

    .heading-cell-with-date {
        .position-date {
            font-size: $font-size-x-small;
            font-weight: $font-weight-regular;
        }
    }

    .heading-cell,
    .heading-cell-with-date {
        width: 100%;
        text-align: center;

        @if ($env) {
            text-align: right;
        }
    }

    .action-panel {
        justify-content: space-between;
        display: flex;

        & > .actions {
            flex: 0 0 auto;
            margin: 0 0 0 auto;
            padding-left: 1rem;
        }

        .additional-info {
            line-height: $shareholder-table-action-panel-tooltip-height;
            text-align: left;

            .show-cede-and-co {
                padding: 0 2rem 0 0;
                font-weight: $font-weight-regular;
            }

            .message-note {
                line-height: $shareholder-table-action-panel-tooltip-height;

                &:not(:last-child) {
                    padding-right: 2rem;
                }
            }
        }

        .registered-table-note {
            display: flex;
            line-height: 2.8rem;
            overflow: hidden;
            align-items: flex-start;

            .message-note {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin: 0 .9rem 0 0;
                padding: 0;
                line-height: 2.8rem;
            }

            .font-icon {
                font-size: $icon-large-size;
                vertical-align: text-top;
            }
        }
    }

    .shareholders-table-rt {
        &.without-scroll {
            overflow: visible;
        }

        .spinner {
            justify-content: center;
        }

        .funds-table .spinner {
            padding-left: $table-expander-size + $table-cell-horizontal-padding;
            justify-content: flex-start;
        }

        .rt-th:first-child,
        .rt-td:first-child {
            border-right: 0;
        }

        .rt-th {
            padding: 0 2.2rem;

            @if ($env) {
                padding: 0 $table-cell-horizontal-padding;

                &.active {
                    padding-right: $table-icons-size;
                }
            }
        }

        .rt-expandable {
            justify-content: center;
        }
    }

    .rt-tbody {
        .font-icon {
            cursor: auto;
        }

        .font-icon-collapse,
        .font-icon-expand {
            cursor: pointer;
        }

        .cell-with-link {
            flex-shrink: 1;
            display: block;
        }
    }

    .position-value:not(.active) {
        cursor: default;
    }

    &-popover-tip-content {
        width: 28rem;
        font-size: $font-size-small;
    }
}
