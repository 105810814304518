$env: false;
@import '../../../../../../styles/variables';

.column-manager-modal {
    display: flex;
    justify-content: space-between;

    .column-lists {
        border-bottom: $border;
        padding-bottom: 2.4rem;
        margin-right: 2.4rem;
    }

    .column-list {
        .column-list-label {
            display: block;
            line-height: 3;
            color: $_color-black;
            padding-right: 2.7rem;
            font-weight: $font-weight-bold;
        }

        .checkbox-list {
            padding-right: 2.7rem;
            overflow: hidden;
        }

        .checkbox-label {
            font-weight: normal;
            color: $color-default;
        }

        .checkbox-component {
            overflow: hidden;
        }
    }

    .btn-panel {

        padding: 1.2rem 0 0;

        .btn {
            margin: 1.2rem 1.2rem 0;
            font-size: 1.6rem;
            font-weight: $font-weight-semi-bold;

            &:first-child {
                margin-left: 0;
            }
        }
    }

    .custom-views-section {
        margin: -5.1rem -2.4rem -1.5rem 0;
        padding: 5.1rem 1rem 1.5rem;
        width: 19.2rem;
        background-color: $color-aside-bg;

        .custom-views-section-header {
            display: block;
            line-height: 3;
            color: $_color-black;
            font-weight: $font-weight-bold;
        }

        .custom-views-list {
            max-height: 45.9rem;
            overflow-y: auto;
        }

        .custom-views-section-item {
            display: flex;
            justify-content: space-between;
            padding: .6rem 0;

            .custom-view-name {
                display: block; // IE 10 fix
                flex-shrink: 1; // IE 10 fix
                max-width: 10rem;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .font-icon {
                font-size: $font-size-x-large;
                padding-left: .3rem;
                cursor: pointer;
            }
        }
    }
}
