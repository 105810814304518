/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
.search-result-table.ReactTable {
  font-size: 1.4rem;
}
.search-result-table.ReactTable .rt-tr {
  padding: 1rem 1.4rem;
  border-bottom: 1px solid #e9e3dc;
}
.search-result-table.ReactTable .rt-tr .rt-td {
  border-right: none;
  padding-left: 0.5rem;
}
.search-result-table.ReactTable .rt-tr .rt-td:first-child {
  padding-left: 0;
}
.search-result-table.ReactTable .rt-tr:hover {
  background-color: initial;
}
.search-result-table.ReactTable .rt-tr:hover .rt-td {
  border-right: none;
}
.search-result-table.ReactTable .truncated-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.search-result-table.ReactTable .strong {
  font-weight: 700;
}