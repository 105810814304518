$env: false;
@import '../../../styles/variables';
@import '../../../styles/colors';

//$body-row-height: 2.4rem;

.table-sticky-column {
    height: 100%;
    font-size: $font-size-small;

    .cell {
        padding: 0 .6rem;
        min-width: 0;
        display: table;
        border-bottom: $border;
    }

    .last-row-cell {
        border-bottom: none;
    }

    .truncated-text {
        display: table-cell;
        vertical-align: middle;
        line-height: normal;
        max-width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .text-right {
        justify-content: flex-end;
    }

    .text-left {
        justify-content: flex-start;
    }

    .header-cell {
        background-color: $color-table-header-bg;
        font-weight: $font-weight-bold;
    }

    .table-header {
        background-color: $color-table-header-bg;
    }

    .ReactVirtualized__Grid:first-child {
        // hide scroll of the fixed columns
        width: 100% !important;
    }
}
