/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
.app-header {
  min-height: 8rem;
  flex-shrink: 0;
}
.app-header .app-header-content {
  display: flex;
  justify-content: space-between;
}
.app-header .security-container {
  display: flex;
  flex-shrink: 1;
  min-width: 0;
}
.app-header .security-container-content {
  display: flex;
  align-self: flex-start;
  align-items: flex-end;
  flex-shrink: 1;
  min-width: 0;
  min-height: 2.7rem;
  margin-top: 0.4rem;
}
@-moz-document url-prefix() {
  .app-header .security-container-content {
    margin-top: 0.3rem;
  }
}
.app-header .security-container .security-list {
  margin-right: 1.8rem;
}
.app-header .security-container .security-name {
  font-size: 1.8rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  flex-shrink: 1;
  display: inline-block;
}
.app-header .security-container-selector {
  display: flex;
  margin-right: 0.5rem;
  margin-left: 2rem;
  min-width: 15rem;
}
.app-header .security-container-selector .security-selector-trigger {
  margin-left: 0.5rem;
  color: #941f40;
  text-decoration: none;
  cursor: pointer;
}
.app-header .security-container-selector .security-selector-trigger:hover {
  color: #ef4e37;
}
.app-header .security-container .font-icon {
  font-size: 2.2rem;
  vertical-align: text-top;
}
.app-header-popover-tip-content {
  width: 28rem;
  font-size: 1.2rem;
}
.app-header .search-section {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  position: relative;
}
.app-header .search-section .search-input {
  width: 27.8rem;
  height: 4rem;
}
.app-header .search-section .float-recent-search {
  position: absolute;
  left: 1rem;
  right: 1rem;
  top: 4rem;
  padding-top: 1rem;
  border-bottom: 1px solid #e9e3dc;
  background: #fff;
  z-index: 1090;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  overflow: hidden;
}
.app-header .search-section .float-recent-search .recent-search__header {
  padding: 0 1rem;
}
.app-header .search-section .float-recent-search .recent-search-list {
  max-height: calc(5.8rem * 5);
  overflow-y: auto;
}
.app-header .search-section .float-recent-search .recent-search-account {
  padding-left: 1rem;
  padding-right: 1rem;
}
.app-header .search-section .float-recent-search .recent-search__no-result {
  padding-left: 1rem;
  padding-right: 1rem;
  justify-content: center;
}
.app-header .search-section .float-recent-search .recent-search-account:hover {
  background: #f4f1ed;
}

.select-security-popover {
  z-index: 1115;
}