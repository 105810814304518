/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
.securities-selector.selector .Select-control {
  height: 4rem;
}
.securities-selector.selector .Select-control .Select-value {
  line-height: 3.8rem;
}
.securities-selector.selector .Select-menu-outer {
  max-height: 45.2rem;
}
.securities-selector.selector .Select-menu-outer .Select-menu {
  max-height: 45rem;
}
.securities-selector.selector .Select-menu-outer .Select-menu .Select-option {
  max-height: 4.5rem;
  padding: 0.7rem 1rem;
}
.securities-selector.selector .Select-menu-outer .Select-menu .Select-option .checkbox-component.flex {
  align-items: center;
}
.securities-selector.selector .Select-menu-outer .Select-menu .Select-option .checkbox-component.flex .font-icon {
  flex-shrink: 0;
}
.securities-selector.selector .Select-menu-outer .Select-menu .Select-option .checkbox-label {
  font-weight: normal;
}
.securities-selector.selector .Select-menu-outer .Select-menu .Select-option.option-default {
  cursor: default;
}
.securities-selector.selector .Select-menu-outer .Select-menu .Select-option.option-default .checkbox-component {
  cursor: default;
}
.securities-selector.selector .Select-menu-outer .Select-menu .Select-option.option-default .font-icon {
  opacity: 0.4;
}
.securities-selector.selector .Select-menu-outer .Select-menu .Select-option.is-focused {
  background-color: inherit;
}
.securities-selector.selector .Select-menu-outer .Select-menu .Select-option:hover {
  background-color: #ebf5ff;
}