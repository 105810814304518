.column-filter {
  display: inline;
  cursor: pointer;
  position: relative;
  top: 0.2rem;
}
.column-filter .popover-content .font-icon {
  vertical-align: middle;
}

.filter-lists {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 13rem;
}
.filter-lists .checkbox-label .font-icon {
  width: 2.2rem;
  text-align: center;
}
.filter-lists .close-btn {
  align-self: flex-end;
  line-height: normal;
}
.filter-lists .close-btn .font-icon {
  font-size: 2rem;
  position: relative;
  left: 0.5rem;
  cursor: pointer;
}
.filter-lists .btn-panel {
  padding-top: 0.5rem;
}