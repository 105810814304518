$env: false;
@import '../../../styles/colors';
@import '../../../styles/variables';

.reporting-dataviz-report-page {
    .reporting-header {
        margin-bottom: 2.4rem;
        line-height: 2;
    }

    .reporting-dataviz-report-control-panel {
        &:after {
            display: block;
            content: ' ';
            height: 1px;
            background-color: $color-border;
            margin: 2.4rem 0;
        }
    }

    .section-title {
        font-size: $font-size-medium;
        font-weight: $font-weight-bold;
        padding-bottom: 1rem;
    }

    .control-panel-form {
        display: flex;
        align-items: flex-end;

        .text-input-label {
            font-size: $font-size-small;
            font-weight: $font-weight-bold;
            color: $_color-wenge;
        }

        .save-btn {
            min-width: 9.2rem;
            margin: 0 2.4rem;
        }

        input[type='text'] {
            min-width: 39.5rem;
        }
    }

    .control-panel-actions-container {
        display: flex;
        align-items: center;
    }

    .control-panel-actions {
        display: flex;

        .action-icon {
            font-size: $font-size-x-large;
            cursor: pointer;
        }
    }

    .control-panel-note {
        max-width: 40rem;
        min-width: 32rem;
        margin-left: 1.2rem;
    }

    .widget-historical-analysis-table,
    .widget-peer-analysis {
        .widget-content {
            height: auto;
            overflow-x: auto;

            .historical-analysis-content,
            .peer-analysis-content {
                overflow: hidden;
            }
        }
    }
}
