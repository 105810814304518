$env: false;
@import '../../styles/variables';

.info-popup {
    display: table;
    border-spacing: 0 .5rem;

    &-row {
        display: table-row;
        font-size: $font-size-medium;
    }

    &-label,
    &-text {
        display: table-cell;
    }

    &-label {
        padding-right: 1.2rem;
        white-space: nowrap;
        text-align: right;
        font-weight: $font-weight-bold;
        color: $color-text-list-item;
    }
}
