$env: false;
@import 'src/styles/variables';

.upload-request-templates {
    display: flex;
    gap: 5rem;
    margin-bottom: 1rem;

    &__column {
        flex: 1;
        overflow: hidden;
    }

    &__group {
        margin-bottom: 4rem;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__group-header {
        font-weight: 700;

        &:first-child {
            margin-top: 0;
        }

        &__placeholder {
            height: 21px;
            margin-top: 0;
        }
    }

    &__document {
        font-size: 1.2rem;
        margin-top: .5rem;

        span {
            color: $color-link;
            cursor: pointer;

            &:hover {
                color: $color-link-hover;
            }
        }
    }
}
