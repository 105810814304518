$env: false;
.textarea-component {
    display: inline-block;
    padding: .5rem;

    .textarea-label.vertical {
        display: block;
        margin-bottom: .5rem;
    }

    .textarea-label.horizontal {
        display: inline-block;
        vertical-align: top;
        margin-right: .5rem;
        margin-top: .5rem;
    }
}
