$env: false;
@import '../../../styles/variables';

.search-result-context-panel {
    .accordion-header {
        display: flex;
        line-height: 2.4rem;
    }

    .panel-header {
        display: flex;
        width: 100%;

        .header {
            &-context-type {
                flex: 11 0 auto;
                width: 220px;
                padding: 0 8px;
            }

            &-issuer {
                flex: 5 0 auto;
                width: 100px;
                padding: 0 8px;
            }

            &-security {
                flex: 6 0 auto;
                width: 120px;
                padding: 0 8px;
            }
        }
    }

    .view-more-item {
        padding: 1.8rem 5.8rem;
    }
}
