/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
.widget-contacts-table {
  min-height: 30rem;
}
.widget-contacts-table .ReactTable {
  overflow-x: visible;
}
.widget-contacts-table .widget-header-container {
  align-items: baseline;
}
.widget-contacts-table .auto-suggest .Select-loading-zone {
  padding: 0;
  right: 3rem;
  position: absolute;
}
.widget-contacts-table .widget-header {
  font-size: 2.4rem;
  flex-grow: 0;
}
.widget-contacts-table .contacts-table-links-panel {
  display: flex;
  flex-direction: row;
  text-align: center;
  margin-right: 4rem;
}
.widget-contacts-table .contacts-table-links-panel .tab {
  padding: 2rem 1.7rem;
  cursor: pointer;
  color: #941f40;
  border-bottom: 1px solid #e9e3dc;
  white-space: nowrap;
}
.widget-contacts-table .contacts-table-links-panel .tab:hover {
  color: #ef4e37;
}
.widget-contacts-table .contacts-table-links-panel .tab.active {
  border: 1px solid #e9e3dc;
  border-bottom: none;
  text-shadow: 0.3px 0 0, -0.3px 0 0;
  margin-right: -1px;
  margin-top: -1px;
}
.widget-contacts-table .contacts-table-links-panel .tab.active.disabled {
  color: #6b6056;
  pointer-events: none;
}
.widget-contacts-table .contacts-table-control-panel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
}
.widget-contacts-table .contacts-table-control-panel .action-icon {
  font-size: 2.4rem;
}
.widget-contacts-table .contacts-table-control-panel .action-icon:not(.inactive) {
  cursor: pointer;
}
.widget-contacts-table .contacts-table-control-panel .contacts-actions-panel {
  display: flex;
  align-items: stretch;
  flex-grow: 1;
  justify-content: flex-end;
}
.widget-contacts-table .contacts-table-control-panel .contacts-actions-panel .contacts-table-pagination {
  margin-right: 2.8rem;
}
.widget-contacts-table .contacts-table-control-panel .contacts-actions-panel .action-icons-container {
  display: flex;
  align-items: center;
}
.widget-contacts-table .contacts-table-control-panel .contacts-table-search form {
  display: flex;
  align-items: center;
}
.widget-contacts-table .contacts-table-control-panel .contacts-table-search-input-container {
  position: relative;
}
.widget-contacts-table .contacts-table-control-panel .contacts-table-search-input-container input {
  width: 20rem;
}
.widget-contacts-table .contacts-table-control-panel .contacts-table-search-input-container .font-icon-search {
  position: absolute;
  right: 0.5rem;
  top: 0.8rem;
  font-size: 2.4rem;
}
.widget-contacts-table .contacts-table-control-panel .contacts-table-search-input-container .font-icon-search.disabled {
  cursor: inherit;
  color: #7f7369;
}
.widget-contacts-table .contacts-table-control-panel .contacts-table-search .my-shareholders-checkbox {
  margin-left: 1rem;
  outline: none;
}
.widget-contacts-table .contacts-table-control-panel .contacts-table-search .my-shareholders-checkbox .checkbox-label {
  font-weight: 400;
  color: #941f40;
}
.widget-contacts-table .widget-fetching {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.widget-contacts-table .rt-thead .rt-th {
  justify-content: center;
  text-align: center;
  padding: 0 2rem;
}
.widget-contacts-table .rt-thead .rt-th:first-child {
  justify-content: flex-start;
}
.widget-contacts-table .rt-thead .rt-th:focus {
  outline: none;
}
.widget-contacts-table .rt-td {
  flex: 1 1 auto;
}
.dataviz-slot .widget-contacts-table .ReactTable {
  overflow-x: auto;
}
.dataviz-slot .widget-contacts-table .ReactTable .rt-th:hover {
  background-color: inherit;
  cursor: auto;
}

.my-shareholders-popup {
  width: 21rem;
}