$env: false;
@import '../../../styles/colors';
@import '../../../styles/variables';

.terms-and-conditions-modal {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    position: relative;

    &-content {
        flex-grow: 1;
        height: 100%;
        max-height: calc(90vh - 16.5rem);
        overflow: auto;
        width: 80rem;
        padding: 0 1rem;
    }
}
