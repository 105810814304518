$env: false;
@import '../../../../../../styles/variables';

.report-downloads {
    position: relative;
    padding: 2.4rem;

    .report-downloads-link {
        display: flex;
        align-items: center;
        height: 2.4rem;
        font-size: $font-size-large;
        font-weight: bold;
        outline: none;

        &.active {
            color: $_color-black;
            cursor: default;
            background-color: transparent;

            &:before {
                content: '';
                position: absolute;
                background-color: $color-link;
                top: 1.6rem;
                bottom: 1.6rem;
                left: 0;
                width: 3px;
            }
        }
    }

    .spinner-item-block,
    .badge {
        margin-left: auto;
    }

    .spinner-item {
        width: 2rem;
        height: 2rem;
    }
}
