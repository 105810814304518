$env: false;
@import '../../../styles/variables';
@import '../../../styles/colors';

.registered-shareholder-current {
    .widget-content {
        .rt-table {
            margin-bottom: 2rem;
        }

        .section-title {
            font-weight: $font-weight-bold;
            margin-right: 1rem;

            &-container {
                display: flex;
                background: $color-table-header-bg;
                padding: 1rem 2rem;
                margin-bottom: 2rem;
                cursor: pointer;

                & .font-icon {
                    cursor: inherit;

                    &-arrow-drop-down {
                        font-size: $font-size-x-large;
                    }

                    &-paging-right {
                        font-size: $font-size-small;
                        padding: .6rem .9rem;
                    }
                }
            }
        }
    }

    .align-center {
        .cell-with-date,
        .cell-with-number {
            justify-content: center;
            text-align: center;
        }
    }

    .message-note {
        padding: 0 2rem 2rem;
    }

    .title-additional-info {
        color: $color-text-info;
    }
}
