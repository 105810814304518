$env: false;
@import '../../../styles/variables';
@import '../../../styles/colors';

.personal-info {
    &-header {
        display: flex;
        justify-content: space-between;
    }

    &-content {
        display: flex;
        padding: 2.4rem 0 .7rem;

        .profile-avatar {
            height: 16rem;
            width: 16rem;
            border-radius: 50%;
        }
    }

    &-image {
        margin-right: 4rem;
        position: relative;
        width: $user-image-size;
        // IE 11
        min-width: $user-image-size;
        height: $user-image-size + 2.6rem;

        .profile-avatar {
            width: $user-image-size;
            height: $user-image-size;
        }
    }

    &-data {
        min-width: 0;
        // IE 10
        flex-shrink: 1;
        padding-bottom: 3rem;
    }

    &-data-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
    }

    .user {
        &-name,
        &-title,
        &-email,
        &-role {
            font-size: $font-size-large;
        }

        &-name {
            font-weight: $font-weight-bold;
            margin-bottom: 1.2rem;
        }

        &-email {
            display: flex;
            word-break: break-all;

            .edit-email-link {
                display: flex;
                align-items: center;
            }
        }
    }

    .info-footer {
        padding-top: 1rem;
    }

    .link {
        color: $color-link;
        cursor: pointer;

        &:hover {
            color: $color-link-hover;
        }
    }
}

body.ie {
    .personal-info .info-message ol {
        margin-left: 1.8rem;
    }
}
