/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
.period-range {
  display: flex;
  flex-wrap: wrap;
}
.period-range.small {
  width: 20.5rem;
  height: 6.8rem;
}
.period-range .period {
  box-sizing: border-box;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 3.5rem;
  height: 3.5rem;
  border: 1px solid #e9e3dc;
  color: #941f40;
  font-size: 1.2rem;
  margin: 0 0 -0.1rem -0.1rem;
  cursor: pointer;
}
.period-range .period.active {
  color: #fff;
  background-color: #988a7d;
  cursor: default;
}
.period-range .period:not(.active):hover {
  color: #ef4e37;
}
.period-range .period .font-icon {
  font-size: 2rem;
}