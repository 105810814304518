$env: false;
@import '../../../../styles/variables';
@import '../../../../styles/colors';

.virtualized-sticky-footer {
    font-weight: $font-weight-bold;
    padding-top: .6em;
    border-top: solid $color-border 2px;

    .colored-square {
        display: none;
    }

    .cell-total {
        text-align: left;

        & + .cell-value {
            float: right;
        }
    }
}
