$env: false;
@import '../../styles/colors';
@import '../../styles/variables';

.app-header {
    min-height: 8rem;
    flex-shrink: 0;

    .app-header-content {
        display: flex;
        justify-content: space-between;
    }

    .security-container {
        display: flex;
        flex-shrink: 1; // IE 10 fix
        min-width: 0;

        &-content {
            display: flex;
            align-self: flex-start;
            align-items: flex-end;
            flex-shrink: 1; // IE 10 fix
            min-width: 0;
            min-height: 2.7rem;
            margin-top: .4rem;
        }

        @-moz-document url-prefix() {
            &-content {
                margin-top: .3rem;
            }
        }

        .security-list {
            margin-right: 1.8rem;
        }

        .security-name {
            font-size: $font-size-large;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            flex-shrink: 1;
            display: inline-block;
        }

        &-selector {
            display: flex;
            margin-right: .5rem;
            margin-left: 2rem;
            min-width: 15rem;

            .security-selector-trigger {
                margin-left: .5rem;
                color: $color-link;
                text-decoration: none;
                cursor: pointer;

                &:hover {
                    color: $color-link-hover;
                }
            }
        }

        .font-icon {
            font-size: $table-icons-size;
            vertical-align: text-top;
        }
    }

    &-popover-tip-content {
        width: 28rem;
        font-size: $font-size-small;
    }

    .search-section {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;
        position: relative;

        .search-input {
            width: 27.8rem;
            height: 4rem;
        }

        .float-recent-search {
            position: absolute;
            left: 1rem;
            right: 1rem;
            top: 4rem;
            padding-top: 1rem;
            border-bottom: $border;
            background: $color-modal-bg;
            z-index: $zindex-popover;
            box-shadow: $shadow-default;
            border-radius: $border-radius;
            overflow: hidden;

            .recent-search__header {
                padding: 0 1rem;
            }

            .recent-search-list {
                max-height: calc(5.8rem * 5); // 5 items
                overflow-y: auto;
            }

            .recent-search-account {
                padding-left: 1rem;
                padding-right: 1rem;
            }

            .recent-search__no-result {
                padding-left: 1rem;
                padding-right: 1rem;
                justify-content: center;
            }

            .recent-search-account:hover {
                background: $color-nav-item-hover;
            }
        }
    }
}

.select-security-popover {
    z-index: $zindex-important;
}
