$env: false;
@import '../../../../../../styles/colors';
@import '../../../../../../styles/variables';

.ReactTable {
    .rt-table .no-dividend-content {
        display: none;
    }

    .rt-td,
    .rt-th {
        &.no-dividend-container .no-dividend-content {
            display: flex;
            position: absolute;
            top: 0; //IE 11 fix
            right: 1px;
            background-color: white;
            font-style: italic;
            color: $_color-gray;
            text-align: left;
            font-weight: normal;
            padding: 0 .8rem;
            line-height: 4rem;
        }
    }

    .rt-tr {
        position: relative;
    }

    .rt-th.no-dividend-container {
        position: static;
        line-height: $table-body-row-height;
    }

    .rt-th.no-dividend-container .no-dividend-content {
        line-height: $table-heading-row-height;
        background-color: $_color-pale-pink-two;
    }

    .rt-tr:hover .no-dividend-container .no-dividend-content {
        background-color: $_color-pale-pink-two;
        cursor: default;
    }
}

.registered-shareholder-client-uploads {
    .drag-and-drop-overlay {
        display: block;
        z-index: $zindex-popover;
        background-color: $color-background-disabled;
        opacity: $icon-inactive-opacity;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    .widget-content.drag-active {
        .ReactTable {
            border: .2rem dashed $color-primary;
            z-index: $zindex-important;
            position: relative;
        }
    }
}
