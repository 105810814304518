$env: false;
.dataviz-export-page {
    pointer-events: none;

    .widget-institution-profile {
        max-width: 70rem;
    }

    .widget {
        border: none;
        min-height: initial;
        height: initial;
    }

    .slot {
        &.col-2 {
            max-width: 100%;
            width: initial;
        }

        &.col-3 {
            display: flex;
        }
    }
}
