$env: false;
@import '../../../styles/colors';
@import '../../../styles/variables';

.widget-bad-addresses {
    .pie-content-container {
        position: relative;
        padding: .5rem 0;
        height: 16rem;
    }

    .legend-container {
        padding: 0;
    }

    .table-container {
        padding: 1rem 0 0;
    }

    .secondary-title {
        color: $_color-gray;
        font-size: $font-size-base;
        font-weight: $font-weight-regular;
    }

    .overfilling-row {
        min-width: 18rem;
    }
}
