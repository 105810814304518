$env: false;
@import '../../styles/colors';
@import '../../styles/variables';

.input-container {
    .text-input {
        display: inline-block;
        position: relative;

        input.error {
            background-color: $color-invalid-input-background;
            border-color: $color-error-background;
        }

        &-label.horizontal {
            margin-right: 1rem;
        }

        &-label.vertical {
            display: block;
            margin-bottom: .5rem;
        }
    }

    .text-input-label {
        font-weight: $font-weight-regular;
        font-size: $font-size-medium;
        color: $color-default;

        &.disabled {
            opacity: .5;
            cursor: default;
        }
    }
}
