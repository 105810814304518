/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
.issuer-resources-page {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100%;
  padding: 0 2.4rem 1rem;
}
.issuer-resources-page .title {
  margin-bottom: 0.4rem;
}
.issuer-resources-page-content {
  display: flex;
}
.issuer-resources-page-content .resource-column {
  padding-right: 2rem;
  min-width: 38rem;
}
.issuer-resources-page-content .resource-group:not(:last-child) {
  margin-bottom: 5rem;
}
.issuer-resources-page-content .resource-group-header {
  font-weight: 700;
}
.issuer-resources-page-content .resource-item {
  margin: 0.5rem 0;
}
.issuer-resources-page-content .resource-item-label {
  color: #941f40;
  cursor: pointer;
  font-size: 1.2rem;
}
.issuer-resources-page-content .resource-item-label:hover {
  color: #ef4e37;
}