/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
.analysis-legend {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 31rem;
  padding-right: 1rem;
  overflow-y: auto;
}
.analysis-legend .item {
  display: flex;
  align-items: center;
  height: 3.1rem;
  width: 100%;
  position: relative;
}
.analysis-legend .item .label {
  margin-left: 0.5rem;
  font-size: 1.2rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-shrink: 1;
  display: block;
}
.analysis-legend .item .font-icon {
  margin-left: auto;
  font-size: 2.2rem;
  cursor: pointer;
}
.analysis-legend .no-data-text {
  font-size: 1.2rem;
  width: auto;
  font-style: italic;
  line-height: 2rem;
}