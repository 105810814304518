/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
.shareholders-table .action-panel {
  display: flex;
  flex-wrap: wrap;
  text-align: right;
  margin-bottom: 1rem;
}
.shareholders-table .action-panel .actions-container {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
}
.shareholders-table .action-panel .message-notes-container {
  display: flex;
  flex-direction: column;
}
.shareholders-table .action-panel .message-notes-container .message-note.text-left {
  line-height: 2.8rem;
}
.shareholders-table .action-panel .actions-group {
  margin-left: auto;
}
.shareholders-table .action-panel .font-icon {
  font-size: 2.4rem;
}
.shareholders-table .action-panel .actions {
  display: inline-block;
  margin-left: 1rem;
}
.shareholders-table .action-panel .actions .font-icon {
  margin-left: 0.6rem;
  line-height: 3.6rem;
  vertical-align: middle;
}
.shareholders-table .action-panel .actions .font-icon:not(.inactive) {
  cursor: pointer;
}
.shareholders-table .action-panel .icon-remove {
  margin-left: 1rem;
}
.shareholders-table .action-panel .icon-remove:not(.inactive) {
  cursor: pointer;
}