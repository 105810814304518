/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
.reporting-dataviz-report-page .reporting-header {
  margin-bottom: 2.4rem;
  line-height: 2;
}
.reporting-dataviz-report-page .reporting-dataviz-report-control-panel:after {
  display: block;
  content: " ";
  height: 1px;
  background-color: #e9e3dc;
  margin: 2.4rem 0;
}
.reporting-dataviz-report-page .section-title {
  font-size: 1.4rem;
  font-weight: 700;
  padding-bottom: 1rem;
}
.reporting-dataviz-report-page .control-panel-form {
  display: flex;
  align-items: flex-end;
}
.reporting-dataviz-report-page .control-panel-form .text-input-label {
  font-size: 1.2rem;
  font-weight: 700;
  color: #6b6056;
}
.reporting-dataviz-report-page .control-panel-form .save-btn {
  min-width: 9.2rem;
  margin: 0 2.4rem;
}
.reporting-dataviz-report-page .control-panel-form input[type=text] {
  min-width: 39.5rem;
}
.reporting-dataviz-report-page .control-panel-actions-container {
  display: flex;
  align-items: center;
}
.reporting-dataviz-report-page .control-panel-actions {
  display: flex;
}
.reporting-dataviz-report-page .control-panel-actions .action-icon {
  font-size: 2.4rem;
  cursor: pointer;
}
.reporting-dataviz-report-page .control-panel-note {
  max-width: 40rem;
  min-width: 32rem;
  margin-left: 1.2rem;
}
.reporting-dataviz-report-page .widget-historical-analysis-table .widget-content,
.reporting-dataviz-report-page .widget-peer-analysis .widget-content {
  height: auto;
  overflow-x: auto;
}
.reporting-dataviz-report-page .widget-historical-analysis-table .widget-content .historical-analysis-content,
.reporting-dataviz-report-page .widget-historical-analysis-table .widget-content .peer-analysis-content,
.reporting-dataviz-report-page .widget-peer-analysis .widget-content .historical-analysis-content,
.reporting-dataviz-report-page .widget-peer-analysis .widget-content .peer-analysis-content {
  overflow: hidden;
}