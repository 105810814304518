/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
.multiple-security-selector {
  position: relative;
}
.multiple-security-selector .list-container {
  display: flex;
  height: 36rem;
}
.multiple-security-selector .security-filter {
  position: absolute;
  bottom: 100%;
  right: 0;
  display: flex;
  align-items: center;
  padding-bottom: 0.6rem;
}
.multiple-security-selector .security-filter .font-icon-search {
  position: absolute;
  right: 0.5rem;
  top: 0.8rem;
  font-size: 2.4rem;
}
.multiple-security-selector .security-filter .font-icon-search.disabled {
  cursor: inherit;
  color: #7f7369;
}
.multiple-security-selector .security-filter-input input {
  width: 23rem;
  padding-right: 3rem;
}
.multiple-security-selector .issuers-list {
  width: 41rem;
  margin-right: 1.7rem;
}
.multiple-security-selector .securities-list {
  width: 65rem;
}
.multiple-security-selector .error-container {
  width: 46rem;
  position: relative;
}
.multiple-security-selector .check-boxes-list {
  border: 1px solid #b2a89d;
  color: #6b6056;
}
.multiple-security-selector .check-boxes-list-item {
  border-top: 1px solid #e9e3dc;
  padding: 0 0 0 0.8rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.multiple-security-selector .check-boxes-list-item:not(.title):hover, .multiple-security-selector .check-boxes-list-item.checked {
  background-color: #e0dbd8;
}
.multiple-security-selector .check-boxes-list-item.select-all {
  border: 0;
}
.multiple-security-selector .check-boxes-list-item.title {
  line-height: 3.6rem;
  padding-left: 2.8rem;
  font-weight: 700;
  color: #000;
}
.multiple-security-selector .check-boxes-list-item .label {
  padding: 0.5rem;
}