/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
.save-custom-view {
  padding: 2rem 0.4rem 0.4rem;
}
.save-custom-view .text-input-label {
  font-size: 1.2rem;
}
.save-custom-view .btn-panel {
  border-top: 1px solid #e9e3dc;
  margin-top: 3rem;
}
.save-custom-view .btn-panel .btn {
  margin: 2.4rem 1rem 0;
  font-size: 1.6rem;
  font-weight: 600;
}
.save-custom-view .btn-panel .btn:first-child {
  margin-left: 0;
}