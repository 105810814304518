/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
.widget-menu {
  cursor: pointer;
  flex-shrink: 1;
}
.widget-menu .ast-popover {
  display: block;
  position: relative;
}

.widget-list {
  font-size: 1.2rem;
}
.widget-list .widget-item {
  position: relative;
  padding: 1rem 1rem 1rem 0;
  cursor: pointer;
  color: #941f40;
  white-space: nowrap;
}
.widget-list .widget-item .font-icon {
  position: absolute;
  font-size: 2.4rem;
}
.widget-list .widget-item.disabled {
  cursor: default;
  color: #7f7369;
}
.widget-list .widget-item.disabled .font-icon {
  color: #7f7369;
}
.widget-list .widget-item:not(:last-child) {
  border-bottom: 1px solid #e9e3dc;
}
.widget-list .widget-item:hover:not(.disabled) {
  color: #ef4e37;
}
.widget-list .widget-item .widget-name {
  margin-left: 2.6rem;
  vertical-align: middle;
}