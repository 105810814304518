$env: false;
@import '../../../../../styles/variables';
@import '../../../../../styles/mixins';
@import '../../../../../styles/colors';

$performance-chart-filter-width: 18rem;

.performance-chart-filter-header {
    @include border-radius($border-radius-lg);
    display: flex;
    width: $performance-chart-filter-width;
    padding: 1rem;
    border: 1px solid $popover-border-color;
    align-items: center;
    justify-content: space-between;

    .font-icon-arrow-up,
    .font-icon-arrow-drop-down {
        font-size: $table-icons-size;
    }
}

.performance-chart-filter-content {
    width: $performance-chart-filter-width;

    &.popover {
        border-top: none;

        &.bottom {
            margin-top: 0;
        }

        .arrow {
            display: none;
        }
    }

    .checkbox-component.block,
    .checkbox-label {
        display: flex;
    }

    .colored-checkbox-label {
        flex: 1;
        justify-content: space-between;
    }

    .checkbox-label {
        font-size: $font-size-small;
        font-weight: $font-weight-regular;
        color: $color-default;
    }
}
