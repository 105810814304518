$env: false;
@import '../../../styles/variables';

.widget-peer-analysis {
    .widget {
        &-content {
            padding-top: 1rem;

            .peer-analysis-content {
                min-width: 120rem;
            }
        }

        &-header {
            display: flex;
            align-items: center;

            &-container {
                padding-bottom: 2.4rem;
                border-bottom: $border;
            }
        }

        &-control-panel {
            display: flex;
            align-items: center;
        }

        &-title {
            font-size: $font-size-x-large;
            margin-right: 13rem;
        }
    }

    .peer-selector {
        font-size: $font-size-medium;

        &:before {
            content: '+';
        }
    }

    .peer-analysis-chart {
        position: relative;
        height: 31.8rem;

        &-legend {
            position: absolute;
            top: 0;
            left: 0;
            z-index: $zindex-chart-legend;
            max-width: 40rem;
        }
    }
}
