@import '../colors';
@import '../variables';
@import '../mixins';

.btn {
    display: inline-block;
    position: relative;
    margin-bottom: 0;
    font-family: inherit;
    font-weight: $btn-font-weight;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    user-select: none;
    border: $btn-border-width solid transparent;
    white-space: nowrap;
    outline: 0;

    @include button-size($padding-base-vertical, $padding-base-horizontal, $font-size-base, $line-height-base, $btn-border-radius-base);

    &.btn-large {
        height: 4.8rem;
    }

    & + & {
        margin-left: $btn-margin;
    }

    // .btn has styles from .btn-default
    @include button-variant($color-btn-text, $color-btn-default-bg, $color-btn-default-border, $color-btn-underline);

    @if ($env) {
        &.btn-link {
            @include button-variant($color-primary, transparent, transparent, $color-btn-underline);
        }
    }

    &:active,
    &.active {
        outline: 0;
        background-image: none;
        box-shadow: inset 0 .25rem .5rem rgba(0, 0, 0, .4);
    }

    &.disabled,
    &[disabled],
    fieldset[disabled] & {
        cursor: $cursor-disabled;
        opacity: .65;
        box-shadow: none;
    }
}
