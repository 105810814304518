/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
.shareholder-information .address-type {
  text-transform: capitalize;
}
.shareholder-information .message-note:not(:last-child) {
  margin-bottom: 1.2rem;
}
.shareholder-information .widget-content {
  padding: 2.2rem;
  background: #f8f6f4;
  height: auto;
}
.shareholder-information .widget-accordion {
  padding-top: 1.2rem;
}
.shareholder-information .widget-title-container .header-info {
  font-size: 1.8rem;
  margin-bottom: 1.2rem;
}
.shareholder-information .widget-title-container .title,
.shareholder-information .widget-title-container .info {
  font-weight: 700;
}
.shareholder-information .widget-title-container .tax-category {
  display: flex;
}
.shareholder-information .widget-title-container .ssn-tax-container {
  margin-top: 1rem;
}
.shareholder-information .accordion-header {
  display: flex;
  border: none;
  padding: 0;
  font-weight: inherit;
  line-height: 2rem;
  color: #941f40;
}
.shareholder-information .accordion-header .font-icon-paging-right {
  margin: 0 1.3rem 0 0;
}
.shareholder-information .accordion-header .font-icon-arrow-drop-down {
  font-size: 2.4rem;
  margin: 0 0 0 -0.5rem;
}
.shareholder-information .bad-address-container,
.shareholder-information .last-contact-date-container,
.shareholder-information .email-container {
  margin-top: 1.2rem;
}
.shareholder-information .bad-address-container .title,
.shareholder-information .last-contact-date-container .title,
.shareholder-information .email-container .title {
  font-weight: 700;
}
.shareholder-information .last-contact-date-container .title {
  margin-right: 0.5rem;
}
.shareholder-information .position-block {
  padding-bottom: 2rem;
}
.shareholder-information .position-row {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #e9e3dc;
  line-height: 3rem;
}
.shareholder-information .position-row.drp-row {
  line-height: 2rem;
  padding-bottom: 0.5rem;
}
.shareholder-information .position-title {
  margin-right: auto;
  flex-shrink: 0;
}
.shareholder-information .position-value {
  display: inline-block;
  font-weight: 700;
  text-align: right;
  padding-left: 0.5rem;
}
.shareholder-information .note-mark {
  font-weight: 400;
}