$env: false;
@import '../../../../styles/colors';
@import '../../../../styles/variables';

.widget-navigation-panel {
    &.nav.horizontal {
        border-bottom: none;
        flex-shrink: 1; // IE 10 fix
    }

    .nav-item {
        a {
            padding: .5rem 1.2rem;

            &.disabled {
                color: $color-tab-active;
                text-shadow: $link-active-text-shadow;
                cursor: default;
            }
        }

        &.inactive,
        &:hover.inactive {
            color: $color-link;
            cursor: default;
            opacity: .65;
        }
    }
}
