$env: false;
@import '../../../../styles/variables';
@import '../../../../styles/colors';

.advanced-search-modal {
    .text-input-label.horizontal {
        margin-right: 0;
    }

    .search {
        margin: 0;
    }

    .radio-component {
        margin: .6rem 2.2rem .5rem .2rem;

        .radio-label {
            font-weight: normal;
            color: $color-default;
        }
    }

    .message-note {
        margin-top: .5rem;
        height: 1.5rem;
        width: 38rem;
    }
}
