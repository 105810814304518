.select-email-recipients-modal {
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
}
.select-email-recipients-modal .modal-actions {
  margin-top: 3rem;
  margin-left: auto;
}
.select-email-recipients-modal .modal-actions:before {
  display: none;
}

.select-email-recipients-subtitle {
  margin: 1rem 0 1.5rem;
  font-weight: 400;
  line-height: 2;
}

.select-email-recipients-header {
  display: flex;
  align-items: center;
  padding-left: 43px;
  background-color: #f4f1ed;
  height: 3.5rem;
  color: #6b6056;
  font-weight: 600;
  gap: 302px;
}
.select-email-recipients-header span {
  margin-right: -2.4rem;
}

.user-full-name {
  flex-basis: 410px;
  flex-shrink: 1;
  flex-grow: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 10px;
  color: #6b6056;
  font-weight: 400;
}

.user-email {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 440px;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 20px;
  padding-left: 8px;
  padding-right: 8px;
}

.user-list-item {
  display: flex;
  align-items: center;
  border-bottom: 0.1rem solid #e9e3dc;
  padding-left: 1.5rem;
}

.recipient-list-container {
  border: 0.2rem solid #e9e3dc;
}