/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
.equiniti-footer {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 0.7rem 2rem;
  color: #fff;
  height: 7.5rem;
  line-height: 3rem;
  background: #d81a32;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.equiniti-footer .footer-links {
  display: inline-flex;
  gap: 40px;
}
.equiniti-footer .footer-link {
  color: #fff;
  cursor: pointer;
}
.equiniti-footer .footer-link:hover {
  color: inherit;
  text-decoration: underline;
}
.equiniti-footer .footer-copyright {
  color: #a1a8b4;
}