$env: false;
@import '../../../styles/colors';
@import '../../../styles/variables';

.search-action-panel {
    position: relative;
    display: flex;
    align-items: baseline;
    padding: .8rem 1.3rem;

    .compare-actions {
        display: flex;
        align-items: baseline;
        flex-grow: 1;

        .font-icon-combine,
        .font-icon-compare {
            align-self: center;
            margin-right: 1rem;
            padding: .5rem;
            border: $border;
            border-radius: $border-radius;
            height: auto;
            cursor: pointer;

            &.inactive {
                cursor: $cursor-disabled;
            }
        }
    }

    .expand-collapse-trigger {
        color: $color-primary;
        cursor: pointer;

        &:not(:first-child) {
            margin-left: 2rem;
        }
    }
}

.limit-accounts-modal,
.fail-combine-modal {
    max-width: 36rem;
    color: $color-text-info;
}
