/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
.tippy-popper[x-placement^=right] .ast-popup-theme .tippy-arrow {
  transform: rotate(45deg);
}
.tippy-popper[x-placement^=left] .ast-popup-theme .tippy-arrow {
  transform: rotate(-135deg);
}
.tippy-popper[x-placement^=top] .ast-popup-theme .tippy-arrow {
  transform: rotate(-45deg);
}
.tippy-popper[x-placement^=bottom] .ast-popup-theme .tippy-arrow {
  transform: rotate(135deg);
}
.tippy-popper .ast-popup-theme {
  max-width: 80rem;
  z-index: 1090;
  font-size: 1.2rem;
  word-wrap: break-word;
  text-align: left;
  background-color: #fff;
  background-clip: padding-box;
  border: 0.1rem solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  padding: 0.9rem 1.4rem;
  color: #000;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
}
.tippy-popper .ast-popup-theme .tippy-arrow {
  position: absolute;
  z-index: -1;
  height: 1.4rem;
  width: 1.4rem;
  background-color: #fff;
  border: 0.1rem solid;
  border-color: transparent transparent rgba(0, 0, 0, 0.2) rgba(0, 0, 0, 0.2);
  transform-origin: initial;
}