/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
.widget-registered-shareholder-table .cell-with-align-right,
.widget-registered-shareholder-table .cell-with-currency-usd,
.widget-registered-shareholder-table .cell-with-number,
.widget-registered-shareholder-table .cell-with-percent,
.widget-registered-shareholder-table .cell-with-mkt-val,
.widget-registered-shareholder-table .cell-with-amount,
.widget-registered-shareholder-table .cell-with-date {
  width: 100%;
}
.widget-registered-shareholder-table .description {
  margin-bottom: 15px;
}
.widget-registered-shareholder-table .ReactTable {
  overflow-x: visible;
}
.widget-registered-shareholder-table .ReactTable .heading-cell {
  width: 100%;
  text-align: center;
}
.widget-registered-shareholder-table .ReactTable .rt-th {
  padding: 0 2.2rem;
  justify-content: center;
}
.widget-registered-shareholder-table .ReactTable .rt-td:first-child,
.widget-registered-shareholder-table .ReactTable .rt-th:first-child {
  text-align: left;
}
.widget-registered-shareholder-table .ReactTable .rt-td:first-child .heading-cell,
.widget-registered-shareholder-table .ReactTable .rt-th:first-child .heading-cell {
  text-align: left;
}
.widget-registered-shareholder-table .ReactTable .rt-td:first-child .cell-with-currency-usd,
.widget-registered-shareholder-table .ReactTable .rt-td:first-child .cell-with-number,
.widget-registered-shareholder-table .ReactTable .rt-td:first-child .cell-with-percent,
.widget-registered-shareholder-table .ReactTable .rt-td:first-child .cell-with-mkt-val,
.widget-registered-shareholder-table .ReactTable .rt-td:first-child .cell-with-amount,
.widget-registered-shareholder-table .ReactTable .rt-td:first-child .cell-with-date,
.widget-registered-shareholder-table .ReactTable .rt-th:first-child .cell-with-currency-usd,
.widget-registered-shareholder-table .ReactTable .rt-th:first-child .cell-with-number,
.widget-registered-shareholder-table .ReactTable .rt-th:first-child .cell-with-percent,
.widget-registered-shareholder-table .ReactTable .rt-th:first-child .cell-with-mkt-val,
.widget-registered-shareholder-table .ReactTable .rt-th:first-child .cell-with-amount,
.widget-registered-shareholder-table .ReactTable .rt-th:first-child .cell-with-date {
  text-align: left;
  justify-content: flex-start;
}
.widget-registered-shareholder-table .ReactTable .rt-tr-group:hover .rt-td:first-child {
  border-right: 1px solid #e9e3dc;
}
.widget-registered-shareholder-table .ReactTable .icon-cell {
  justify-content: center;
}
.widget-registered-shareholder-table .ReactTable .info-icon-block {
  display: inline-block;
}
.widget-registered-shareholder-table .business-date {
  font-size: 1.2rem;
  font-style: italic;
  color: #6b6056;
  padding-bottom: 0.5rem;
}