$env: false;
@import '../../../styles/colors';

.widget-overall-ownership-statistics {
    .pie-content-container {
        position: relative;
        padding: .5rem 0;
        height: 16rem;
    }

    .legend-container {
        padding: 0;
    }

    .total-value-container {
        padding: 0 0 1rem 1.2rem;
        font-size: 1.2rem;

        .total-value-message {
            margin-right: .5rem;
            font-weight: bold;
        }
    }

    .table-container {
        padding: 1rem 0 0;
    }
}
