$env: false;
@import '../../../../../../styles/variables';
@import '../../../../../../styles/colors';

.widget-registered-shareholder-table {
    .cell-with-align-right,
    .cell-with-currency-usd,
    .cell-with-number,
    .cell-with-percent,
    .cell-with-mkt-val,
    .cell-with-amount,
    .cell-with-date {
        width: 100%;
    }

    .description {
        margin-bottom: 15px;
    }

    .ReactTable {
        overflow-x: visible;

        .heading-cell {
            width: 100%;
            text-align: center;
        }

        .rt-th {
            padding: 0 2.2rem;
            justify-content: center;
        }

        .rt-td,
        .rt-th {
            &:first-child {
                text-align: left;

                .heading-cell {
                    text-align: left;
                }

                .cell-with-currency-usd,
                .cell-with-number,
                .cell-with-percent,
                .cell-with-mkt-val,
                .cell-with-amount,
                .cell-with-date {
                    text-align: left;
                    justify-content: flex-start;
                }
            }
        }

        .rt-tr-group:hover {
            .rt-td {
                &:first-child {
                    border-right: $border;
                }
            }
        }

        .icon-cell {
            justify-content: center;
        }

        .info-icon-block {
            display: inline-block;
        }
    }

    .business-date {
        font-size: $font-size-small;
        font-style: italic;
        color: $color-text-info;
        padding-bottom: .5rem;
    }
}
