/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
.subscriptions-panel {
  padding: 2.4rem;
}
.subscriptions-panel-header {
  font-weight: 700;
  font-size: 1.8rem;
  color: #6b6056;
  margin-bottom: 0.5rem;
  padding: 0 1rem;
}
.subscriptions-panel-list-header {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  color: #6b6056;
  background-color: #eae8e5;
}
.subscriptions-panel-list-header span:last-child {
  text-align: right;
}
.subscriptions-panel .subscription-row {
  display: flex;
  padding: 0.5rem 1rem;
}
.subscriptions-panel .subscription-checkbox {
  display: flex;
  margin-right: auto;
  white-space: normal;
}
.subscriptions-panel .subscription-checkbox .checkbox-label {
  font-weight: 400;
  color: #000;
}
.subscriptions-panel .subscription-checkbox .font-icon {
  flex-shrink: 0;
  padding-top: 0.2rem;
}
.subscriptions-panel .subscription-name-no-checkbox {
  display: flex;
  margin-right: auto;
  white-space: normal;
  font-weight: 400;
  color: #000;
  align-items: center;
  margin-left: 2.3rem;
}
.subscriptions-panel-footer {
  font-size: 1.2rem;
  margin-top: 0.5rem;
}

.subscription-tooltip {
  width: 14rem;
  text-align: center;
}