/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
.select-peers-popover {
  display: flex;
  flex-direction: column;
}
.select-peers-popover .checkbox-label {
  font-weight: 400;
  font-size: 1.2rem;
}
.select-peers-popover .checkbox-label .unavailable-peer {
  font-style: italic;
}
.select-peers-popover .close-btn {
  text-align: right;
}
.select-peers-popover .close-btn .font-icon {
  font-size: 2.1rem;
  cursor: pointer;
}
.select-peers-popover .btn-panel {
  padding-top: 1.3rem;
}
.select-peers-popover .btn {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
}
.select-peers-popover .checkbox-list {
  overflow: hidden;
}