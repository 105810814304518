$env: false;
@import 'src/styles/variables';
@import 'src/styles/components';

.user-secure-upload-edit-page {
    padding: 2.4rem;

    &_header {
        margin: 2rem 0 0;
    }

    &__actions {
        margin-left: auto;
        padding-top: 1.4rem;

        .btn:first-of-type {
            margin: 0 1.5rem 0 0;
        }
    }

    .drag-and-drop-overlay {
        display: block;
        z-index: $zindex-popover;
        background-color: $color-background-disabled;
        opacity: $icon-inactive-opacity;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    .ReactTable.drag-active {
        border: .2rem dashed $color-primary;
        z-index: $zindex-important;
        position: relative;
    }

    .files-field_dnd-description {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 1rem;
    }

    .form-control__label {
        align-self: flex-end;
        width: 50%;
        font-weight: 700;
        line-height: 2;
        color: #000;

        .message-note {
            font-weight: $font-weight-regular;
            margin-left: 1rem;
        }
    }

    .textarea-component {
        width: 100%;
        padding: 0;

        textarea {
            width: 100%;
        }
    }

    .form-control__field {
        margin: 0 0 1.2rem;
    }

    .label-button-container{
        display: flex;
        justify-content: space-between;
    }

    .icon-button-container {
        display: flex;
        align-items: center;
        gap: 1rem;
        padding-bottom: 8px;

        .ast-popover {
            height: 20px;
            width: 20px;
        }

        & i {
            font-size: 2rem;
        }
    }

    .no-results {
        padding: 2.4rem 0;
    }

    .request-description-input {
        .text-input,
        input {
            width: 100%;
        }
    }

    .cell-with-status {
        display: flex;
        align-items: center;

        & > span {
            margin-right: $padding-small-vertical;
        }

        .font-icon {
            display: block;
        }
    }

    .message-note.negative {
        @extend .negative;
    }

    .comments-actions-container {
        display: flex;
        align-items: center;
    }

    .comments_field {
        width: 80%;
    }
}
