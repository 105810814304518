$env: false;
@import '../../../../styles/colors';
@import '../../../../styles/variables';

.widget-contacts-table {
    min-height: 30rem;

    .ReactTable {
        overflow-x: visible;
    }

    .widget-header-container {
        align-items: baseline;
    }

    .auto-suggest .Select-loading-zone {
        padding: 0;
        right: 3rem;
        position: absolute;
    }

    .widget-header {
        font-size: $font-size-x-large;
        flex-grow: 0;
    }

    .contacts-table-links-panel {
        display: flex;
        flex-direction: row;
        text-align: center;
        margin-right: 4rem;

        .tab {
            padding: 2rem 1.7rem;
            cursor: pointer;
            color: $_color_marron;
            border-bottom: $border;
            white-space: nowrap;

            &:hover {
                color: $color-link-hover;
            }

            &.active {
                border: $border;
                border-bottom: none;
                text-shadow: .3px 0 0, -.3px 0 0;

                &.disabled {
                    color: $color-tab-active;
                    pointer-events: none;
                }

                // Fix border offset
                margin-right: -1px;
                margin-top: -1px;
            }
        }
    }

    .contacts-table-control-panel {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-grow: 1;

        .action-icon {
            font-size: $font-size-x-large;

            &:not(.inactive) {
                cursor: pointer;
            }
        }

        .contacts-actions-panel {
            display: flex;
            align-items: stretch;
            flex-grow: 1;
            justify-content: flex-end;

            .contacts-table-pagination {
                margin-right: 2.8rem;
            }

            .action-icons-container {
                display: flex;
                align-items: center;
            }
        }

        .contacts-table-search {
            form {
                display: flex;
                align-items: center;
            }

            &-input-container {
                position: relative;

                input {
                    width: 20rem;
                }

                .font-icon-search {
                    position: absolute;
                    right: .5rem;
                    top: .8rem;
                    font-size: $font-size-x-large;

                    &.disabled {
                        cursor: inherit;
                        color: $color-text-muted;
                    }
                }
            }

            .my-shareholders {
                &-checkbox {
                    margin-left: 1rem;
                    outline: none;

                    .checkbox-label {
                        font-weight: $font-weight-regular;
                        color: $_color_marron;
                    }
                }
            }
        }
    }

    .widget-fetching {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .rt-thead {
        .rt-th {
            justify-content: center;
            text-align: center;
            padding: 0 2rem;

            &:first-child {
                justify-content: flex-start;
            }

            &:focus {
                outline: none;
            }
        }
    }

    .rt-td {
        flex: 1 1 auto; // IE 10 fix
    }

    .dataviz-slot & {
        .ReactTable {
            overflow-x: auto;

            .rt-th:hover {
                background-color: inherit;
                cursor: auto;
            }
        }
    }
}

.my-shareholders-popup {
    width: 21rem;
}
