/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
.recent-search .recent-search__header {
  font-weight: 700;
  font-size: 1.4rem;
  color: #6b6056;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
}
.recent-search .recent-search__header .recent-search__title {
  margin-right: 0.8rem;
}
.recent-search .recent-search__header .ast-popover {
  height: 2.2rem;
}
.recent-search .recent-search__header .font-icon {
  font-size: 2.2rem;
}
.recent-search .recent-search__spinner {
  height: 5.6rem;
}
.recent-search .recent-search__no-result {
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  height: 5.4rem;
  display: flex;
  align-items: center;
  color: #7f7369;
}
.recent-search .recent-search-account {
  display: flex;
  align-items: center;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
}
.recent-search .recent-search-account:last-child {
  margin-bottom: 0;
}
.recent-search .recent-search-account .recent-search-account__icon {
  margin-right: 0.8rem;
  font-size: 2.2rem;
  min-width: 3.4rem;
  text-align: center;
}
.recent-search .recent-search-account .recent-search-account__icon.inactive {
  color: #988a7d;
}
.recent-search .recent-search-account .recent-search-account__link {
  overflow: hidden;
}
.recent-search .recent-search-account .recent-search-account__link.inactive {
  pointer-events: none;
  color: #7f7369;
}

.popover.recent-search-popover {
  width: 30rem;
  font-size: 1.2rem;
}