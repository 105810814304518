/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
.checkbox-component {
  padding: 0.5rem 0;
}
.checkbox-component.disabled {
  opacity: 0.4;
  cursor: default;
}
.checkbox-component.inline {
  display: inline-block;
}
.checkbox-component.block {
  display: block;
}
.checkbox-component.flex {
  display: flex;
}
.checkbox-input {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  border: 0;
  vertical-align: middle;
}
.checkbox-input ~ .font-icon {
  font-size: 2rem;
  font-weight: 700;
  vertical-align: middle;
  width: 1.8rem;
  position: relative;
  left: -0.2rem;
}
.checkbox-input ~ .font-icon-checkbox-no-checked {
  display: inline-block;
}
.checkbox-input ~ .font-icon-checkbox-checked {
  display: none;
}
.checkbox-input:checked ~ .font-icon-checkbox-no-checked {
  display: none;
}
.checkbox-input:checked ~ .font-icon-checkbox-checked {
  display: inline-block;
}
.checkbox-label {
  display: inline-block;
  margin-left: 0.5rem;
  flex-grow: 1;
}
.checkbox-label .font-icon {
  margin-left: 0.3rem;
}
.checkbox-label .label-text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.3rem;
  font-weight: normal;
}