$env: false;
@import '../../../styles/variables';

.widget-analysis-turnover {
    .widget-header {
        display: flex;

        .font-icon {
            font-size: $icon-large-size;
            margin-left: .5rem;
            cursor: pointer;
        }

        &.ellipsis {
            display: flex !important;
        }
    }
}
