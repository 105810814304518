$env: false;
@import '../../../../styles/variables';

.reporting-control-books-page {
    .reporting-header-container {
        display: flex;
        align-items: center;
        max-width: 97rem;
        justify-content: space-between;
    }

    .reporting-subheader {
        display: inline-block;
        color: $color-default;
    }

    .reporting-section {
        margin-bottom: 2.4rem;

        &-body {
            margin: 0;
        }

        .reporting-actions {
            border-top: none;
            display: inline-block;
            margin: 0 1.2rem;
            padding: 0;

            .btn-control-books-submit {
                width: 10rem;
            }
        }

        .date-range-section {
            display: flex;
            flex-flow: row wrap;
            align-items: center;
        }

        .additional-options-section {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .checkbox-section {
                height: 3.5rem;

                .checkbox-component:not(:first-child) {
                    margin-left: 3rem;
                }
            }

            .checkbox-label {
                font-weight: $font-weight-regular;
            }

            .message-note {
                &.disabled {
                    opacity: .5;
                }
            }
        }
    }

    .subscribe-email {
        display: block;
        font-weight: $font-weight-regular;
        color: $color-default;
        margin: 4.8rem 0 0;
    }

    .reporting-header-alternative {
        margin: 0 0 .5rem;
    }

    .securities-container {
        display: flex;
        align-items: center;
        width: 50rem;
        margin: 1rem 0 2rem;

        & > span {
            color: $color-text-label;
            font-weight: $font-weight-bold;
            margin-right: 1rem;
        }

        .securities-selector {
            min-width: 45rem;
        }
    }

    .control-books-note {
        width: 50rem;
        margin: 1.5rem 0 0 .5rem;

        .note-title {
            font-weight: $font-weight-bold;
        }
    }

    .control-books-container {
        display: flex;
        align-items: center;
        height: 9.1rem;

        .message-note {
            margin-left: 1.5rem;
        }

        .spinner.loading {
            justify-content: flex-start;
        }
    }

    .total-outstanding-shares {
        max-width: 97rem;

        .table-panel {
            display: flex;
            justify-content: space-between;
            margin-bottom: .5rem;
        }

        .cob-date-message {
            padding-left: 1rem;
            font-style: italic;
        }

        .ReactTable {
            .rt-thead {
                height: 4rem;

                .rt-th {
                    border-right: 0;

                    &:focus {
                        outline: none;
                    }
                }
            }

            .rt-td {
                border-right: 0;
                border-top: .1rem solid $color-border;
            }
        }
    }

    .treasury-accounts-link {
        font-size: $font-size-base;
    }
}
