/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
.upload-request-templates {
  display: flex;
  gap: 5rem;
  margin-bottom: 1rem;
}
.upload-request-templates__column {
  flex: 1;
  overflow: hidden;
}
.upload-request-templates__group {
  margin-bottom: 4rem;
}
.upload-request-templates__group:last-child {
  margin-bottom: 0;
}
.upload-request-templates__group-header {
  font-weight: 700;
}
.upload-request-templates__group-header:first-child {
  margin-top: 0;
}
.upload-request-templates__group-header__placeholder {
  height: 21px;
  margin-top: 0;
}
.upload-request-templates__document {
  font-size: 1.2rem;
  margin-top: 0.5rem;
}
.upload-request-templates__document span {
  color: #941f40;
  cursor: pointer;
}
.upload-request-templates__document span:hover {
  color: #ef4e37;
}