/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
.widget-shareholder-information .field-info {
  font-size: 1.2rem;
  line-height: 2;
}
.widget-shareholder-information .field-info .name {
  font-weight: 700;
}
.widget-shareholder-information .content-columns {
  display: flex;
  margin: 1.2rem 0;
}
.widget-shareholder-information .content-column {
  flex-basis: 50%;
  flex-grow: 1;
}
.widget-shareholder-information .content-column:not(:first-child) {
  margin-left: 2.4rem;
}
.widget-shareholder-information .content-column .field-info {
  display: flex;
  align-items: center;
  height: 2.4rem;
  line-height: 1.5;
  border-bottom: 1px solid #e9e3dc;
}
.widget-shareholder-information .content-column .field-info .name {
  flex: 1;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.widget-shareholder-information.one-column {
  width: 50%;
}
.widget-shareholder-information.one-column .content-column {
  flex-basis: 100%;
}