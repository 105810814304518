$env: false;
@import '../../../styles/colors';
@import '../../../styles/variables';

.widget {
    position: relative;
    height: 100%;
    min-height: 20rem;
    display: flex;
    flex-direction: column;

    &-header-container {
        display: flex;
    }

    &-header {
        flex: 1 1 auto;
        font-size: $font-size-large;
        font-weight: $font-weight-bold;
        line-height: normal;
        text-align: left;
        color: $color-widget-header;
    }

    &-control-panel {
        flex: 0 0 auto;

        .font-icon {
            font-size: $font-size-x-large;
            cursor: pointer;

            &:not(.inactive).deactivated {
                opacity: $icon-inactive-opacity;
                cursor: pointer;
            }
        }

        .widget-menu {
            display: inline-block;
        }
    }

    &-fetching {
        height: 100%;
    }

    &-no-data,
    &-error {
        height: 100%;
    }

    .no-data-text,
    .error-message {
        width: 18.4rem;
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &-content {
        position: relative;
        padding-top: 1rem;
        flex-grow: 100;
        min-height: 10rem; // IE fix

        .widget-with-pie-chart {
            height: 100%;
            display: flex;
        }
    }

    .pie-container {
        position: relative;
        flex-basis: 38%;

        &:before {
            content: '';
            display: block;
            padding-top: 100%;
        }

        .pie-content {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            padding: 1rem 0;
        }
    }

    &:not(.shareholders-table) .highcharts-container {
        overflow: visible !important;
        z-index: auto !important;
    }

    .legend-container {
        flex-basis: 62%;
        padding: 1rem 0 .1rem;
        z-index: 0;
    }

    .no-results {
        white-space: pre-wrap;
        padding: 2.5rem 0;
        text-align: center;

        span {
            display: inline-block;
            max-width: 40%;
        }
    }

    .ast-highcharts-tooltip {
        background-color: rgba(247, 247, 247, .85);
        border: 1px solid;
        padding: .4rem 1rem;
        border-radius: .2rem;
    }

    [data-highcharts-chart] {
        overflow: visible !important;
    }
}
