/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
.registered-overview-page {
  display: flex;
  flex-direction: column;
}
.registered-overview-page .content {
  padding: 2.4rem 0 1.2rem 2.4rem;
}
.registered-overview-page .institutional-overview-page-header__title {
  margin-top: 2rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
}
.registered-overview-page .institutional-overview-page-header__title .title {
  margin: 0;
}