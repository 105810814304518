$env: false;
@import 'src/styles/variables';

.user-secure-upload-page {
    padding: 2.4rem;

    .user-secure-upload_page-header {
        margin: 0;
    }

    .user-secure-upload-list-action {
        margin: 2.4rem 0 0;
    }

    .icon-cell {
        justify-content: center;
    }

    .no-results {
        margin: $padding-base-vertical 0;
    }
}
