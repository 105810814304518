$env: false;
@import '../../styles/variables';
@import '../../styles/colors';

.static-layout-empty {
    min-height: 20rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: $font-size-x-large;
    font-weight: $font-weight-semi-bold;
    color: $_color-wenge;
}
