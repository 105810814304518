$env: false;
@import 'src/styles/variables';

.slot-tabs {
    width: 100%;

    .tabs .tab {
        .active {
            text-shadow: none;
        }
    }

    .tabs-group {
        margin-bottom: 2rem;
    }
}
