$env: false;
@import '../../../../../styles/colors';
@import '../../../../../styles/variables';

.authorized-users {
    display: flex;
    padding: 1.4rem 2.4rem;

    &-link {
        line-height: 2.4rem;

        &.active {
            text-shadow: $link-active-text-shadow;
            color: $color-tab-active;
            cursor: default;
            pointer-events: none;
        }
    }

    .action-icon {
        margin-right: .8rem;
        font-size: $font-size-x-large;
    }
}
