$env: false;
@import '../../../../../../styles/colors';
@import '../../../../../../styles/variables';

.saved-reports-section {
    padding: 2.4rem;

    .saved-reports-title {
        margin-bottom: 2.4rem;
        font-size: $font-size-large;
        font-weight: bold;
        color: $color-saved-report-title;
    }

    .saved-reports-legend {
        margin-bottom: 2.4rem;

        .legend-row {
            display: flex;
        }

        .legend-container {
            display: flex;
            align-items: center;

            &:not(:last-child) {
                margin-right: 2.4rem;
            }

            .legend-name {
                font-size: $font-size-small;
            }

            .font-icon {
                margin-right: .8rem;
                font-size: $font-size-x-large;
            }
        }

        .message-note {
            margin-top: 1.5rem;
        }
    }
}

.saved-reports-list {
    .report {
        display: flex;
        align-items: stretch;
        margin-bottom: 1.2rem;

        &:last-child {
            margin-bottom: 0;
        }

        &-action-icons {
            flex-shrink: 0; //IE 11 fix
        }
    }

    .report-name {
        color: $color-primary;
        cursor: pointer;

        &:hover {
            color: $color-link-hover;
        }

        &.disabled {
            color: $color-primary;
            opacity: .65;
        }
    }

    .report-type-icon {
        margin-right: .8rem;
        font-size: $font-size-x-large;
    }
}
