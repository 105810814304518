$env: false;
@import '../../../../styles/variables';
@import '../../../../styles/colors';

.export-report-modal {
    width: 41rem;

    .format-container {
        margin: 2.4rem 0;
    }
}
