$env: false;
@import '../../../styles/variables';

.widget-authorized-shares {
    .aggregate-row {
        font-weight: $font-weight-bold;
    }

    .pie-content-container {
        position: relative;
        padding: .5rem 0;
        height: 16rem;
    }

    .active-link {
        cursor: pointer;
        color: $color-link;

        &:hover {
            color: $color-link-hover;
        }
    }

    .sign-color-default {
        &:before,
        &:after {
            color: $color-text;
        }
    }

    .overfilling-row-container > a {
        display: flex;
    }

    .legend-container {
        .overfilling-row {
            min-width: 17.5rem;
        }
    }

    .table-container {
        .overfilling-row {
            min-width: 19.5rem;
        }
    }
}
