/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
.home-page {
  min-height: 100%;
}
.home-page-slot.col-3 {
  display: grid;
  -ms-grid-rows: auto 0 1fr;
  grid-template-rows: auto 1fr;
  grid-column-gap: 3.33333%;
  width: 100%;
}
.home-page-slot.col-3 .slot {
  max-width: inherit;
}
.home-page-slot.col-3 .slot:nth-child(2).col-2 {
  grid-column-start: 2;
  grid-column-end: 3;
}
.home-page-slot.col-3 .slot.col-2 {
  grid-row-start: 1;
  grid-row-end: 3;
}
.home-page-slot.perf-left {
  -ms-grid-columns: 63.33333% 3.33333% 30%;
  grid-template-columns: 63.33333% 30%;
}
.home-page-slot.perf-left .slot:nth-child(1).col-2 {
  -ms-grid-row: 1;
  -ms-grid-row-span: 3;
  -ms-grid-column: 1;
}
.home-page-slot.perf-left .slot:nth-child(2).col-1 {
  -ms-grid-row: 1;
  -ms-grid-column: 3;
}
.home-page-slot.perf-left .slot:nth-child(3).col-1 {
  -ms-grid-row: 3;
  -ms-grid-column: 3;
}
.home-page-slot.perf-right {
  -ms-grid-columns: 30% 3.33333% 63.33333%;
  grid-template-columns: 30% 63.33333%;
}
.home-page-slot.perf-right .slot:nth-child(1).col-1 {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
}
.home-page-slot.perf-right .slot:nth-child(2).col-2 {
  -ms-grid-row: 1;
  -ms-grid-row-span: 3;
  -ms-grid-column: 3;
}
.home-page-slot.perf-right .slot:nth-child(3).col-1 {
  -ms-grid-row: 3;
}
.home-page-slot .widget-box-empty-container {
  position: relative;
  max-height: 41.8rem;
}
@media all and (max-width: 1458px) {
  .home-page-slot.col-1 {
    max-width: 46.66667%;
  }
  .home-page-slot.col-3 {
    -ms-grid-columns: 46.66667% 3.33333% 46.66667%;
    grid-template-columns: 46.66667% 46.66667%;
  }
  .home-page-slot.col-3 .slot.home-page-slot.col-2 {
    grid-column-start: 1;
    grid-column-end: 3;
  }
  .home-page-slot.perf-left .slot:nth-child(1).col-2 {
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 4;
  }
  .home-page-slot.perf-left .slot:nth-child(2).col-1 {
    -ms-grid-row: 3;
    -ms-grid-column: 1;
  }
  .home-page-slot.perf-left .slot:nth-child(3).col-1 {
    -ms-grid-row: 3;
    -ms-grid-column: 3;
  }
  .home-page-slot.perf-right .slot:nth-child(1).col-1 {
    -ms-grid-row: 3;
    -ms-grid-column: 1;
  }
  .home-page-slot.perf-right .slot:nth-child(2).col-2 {
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 4;
  }
  .home-page-slot.perf-right .slot:nth-child(3).col-1 {
    -ms-grid-row: 3;
    -ms-grid-column: 3;
  }
}