/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
.widget-box-empty {
  border: 3px dashed #e9e3dc;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.widget-box-empty-container {
  min-height: 41.8rem;
}
.widget-box-empty .add-label-container {
  display: inline-flex;
  margin: 0 2.4rem;
  align-items: center;
  color: #941f40;
  flex-shrink: 1;
}
.widget-box-empty .add-label-container,
.widget-box-empty .add-label-container .font-icon {
  font-size: 2.4rem;
}
.widget-box-empty .add-label-container .font-icon-add-circle-outline {
  cursor: pointer;
}
.widget-box-empty .add-label-container .add-label {
  padding-left: 1rem;
}
.widget-box-empty .add-label-container .inactive + .add-label {
  opacity: 0.65;
  cursor: default;
}

.popover.bottom.box-widget-menu {
  margin-top: -18rem;
}