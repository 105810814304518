$env: false;
.institutional-overview-page {
    padding: 2.4rem;

    .title-container {
        display: flex;
        align-items: center;
    }

    .institution-profile-link {
        margin-left: 3rem;
    }
}
