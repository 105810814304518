$env: false;
@import '../../../../styles/variables';
@import '../../../../styles/colors';

.save-changes-modal {
    max-width: 40rem;

    .btn-panel {
        border-top: $border;
        padding-top: 2.5rem;

        .btn {
            margin: 0 2rem 0 0;
        }
    }
}
