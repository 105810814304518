.watchlist-widget .action-panel {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}
.watchlist-widget .action-panel .actions-container {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
}
.watchlist-widget .action-panel .actions-group {
  margin-left: auto;
}
.watchlist-widget .action-panel .message-notes-container {
  display: flex;
  flex-direction: column;
}
.watchlist-widget .action-panel .font-icon {
  font-size: 2.4rem;
}
.watchlist-widget .action-panel .actions .font-icon {
  margin-left: 0.6rem;
  line-height: 3.6rem;
  vertical-align: middle;
}
.watchlist-widget .action-panel .actions .font-icon:not(.inactive) {
  cursor: pointer;
}
.watchlist-widget .watchlist-footer {
  margin-top: 0.5rem;
}

@media print {
  #print-el.watchlist-widget {
    display: block;
    position: relative;
    width: 100%;
    min-height: 20rem;
  }
  #print-el.watchlist-widget .watchlist-table.ReactTable .rt-tr {
    height: inherit;
  }
  #print-el.watchlist-widget .watchlist-table__delete-column,
#print-el.watchlist-widget .watchlist-table__delete-cell {
    display: none;
  }
  #print-el.watchlist-widget .action-panel .actions-group {
    display: none;
  }
  #print-el.watchlist-widget .watchlist__header {
    display: block;
  }
  #print-el.watchlist-widget .watchlist-instruction {
    display: none;
  }
}