/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
.radio-component {
  padding: 0.5rem;
}
.radio-component.disabled {
  opacity: 0.4;
  cursor: default;
}
.radio-component.inline {
  display: inline-block;
}
.radio-component.block {
  display: block;
}
.radio-input {
  display: none;
}
.radio-input ~ .font-icon {
  font-size: 2rem;
  vertical-align: middle;
}
.radio-input ~ .font-icon-radio-no-selected {
  display: inline-block;
}
.radio-input ~ .font-icon-radio-selected {
  display: none;
}
.radio-input:checked ~ .font-icon-radio-no-selected {
  display: none;
}
.radio-input:checked ~ .font-icon-radio-selected {
  display: inline-block;
}
.radio-label {
  margin-left: 0.5rem;
}