/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
.top-25-holdings-table .table-view-four {
  overflow-x: auto;
}
.top-25-holdings-table .heading-cell {
  text-align: center;
}
.top-25-holdings-table .heading-cell-position-change {
  display: flex;
  align-items: center;
}
.top-25-holdings-table .heading-cell-position-change .dropdown-wrapper {
  display: flex;
}
.top-25-holdings-table .heading-cell-position-change .dropdown-wrapper .dropdown {
  margin-left: 0.8rem;
  font-weight: normal;
}
.top-25-holdings-table .heading-cell-position-change .dropdown-wrapper .dropdown .font-icon {
  font-size: 1.6rem;
}
.top-25-holdings-table .cell-position-change,
.top-25-holdings-table .cell-os-change {
  background-color: rgba(248, 246, 244, 0.5);
}
.top-25-holdings-table .current-company {
  font-weight: 700;
}
.top-25-holdings-table .not-selected-period {
  font-weight: normal;
  margin-left: 1rem;
}
.top-25-holdings-table .ReactTable.without-scroll {
  overflow: visible;
}