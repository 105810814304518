$env: false;
@import '../../../../styles/colors';
@import '../../../../styles/variables';

/*  This is the general outline for all reporting pages
 *  The idea is to create some generic styles for all
 *  of the pages to inherit, each page has its own
 *  style to overwrite any of the below values if needed
 *
 *  reporting-page: reporting-nav (sidebar) and reporting-content
 *  reporting-content: is a wrapper around reporting-{name-of-page-here}-page
 *  which contains a possible reporting-header and form
 *  The form consists of reporting-section and reporting-actions
 *    reporting-section is a collection of reporting-section-header and reporting-section-body
 *        reporting-section-header: name of fields representing in reporting-section-body ie: `Share Type`
 *        reporting-section-body: collection of fields to collect user-input, etc ie: `RadioButtons, TextInput, etc`
 *    reporting-actions: a single or multiple call to action buttons to process form data ie: `Save`
 *
 */

.reporting-page {
    display: flex;
    height: 100%;

    .reporting-nav {
        flex: 0 0 $reporting-nav-width;
        max-width: $reporting-nav-width;
        background-color: $color-reporting-nav-bg;
    }

    .reporting-nav-section {
        border-bottom: 1px solid $color-reporting-nav-separator;
    }

    .reporting-content {
        position: relative;
        max-width: calc(100% - #{$reporting-nav-width});
        flex: 1 0;
        padding: $reporting-content-padding;
    }

    .reporting-header {
        margin: 0;
        padding: 0;
    }

    .reporting-edit {
        margin-bottom: 2.4rem;
        display: flex;
        align-items: center;

        &-title {
            font-weight: $font-weight-bold;
            line-height: 2;
            color: $_color-black;

            &-input input {
                width: 25.2rem;
            }
        }
    }

    .reporting-section-header {
        font-weight: $font-weight-bold;
        line-height: 2;
        color: $color-default;

        &.disabled {
            opacity: .65;
        }
    }

    .reporting-section-body {
        margin: 1.2rem 2.4rem;

        .checkbox-label {
            color: $_color-black;
        }

        .radio-component {
            margin: 0;
            padding: 0;
            color: $_color-black;
        }

        .radio-label {
            line-height: 2.57;
            font-weight: $font-weight-regular;
            margin: 0 2.6rem 0 .8rem;
        }

        .radio-buttons {
            margin-bottom: 2.4rem;
        }

        .radio-buttons-column {
            display: inline-block;
            margin-right: 5rem;
            vertical-align: top;

            &:last-child {
                margin-right: 0;
            }

            .radio-label {
                margin-right: 0;
            }
        }

        .text-input-label {
            margin-bottom: .6rem;
        }

        .date-picker-component {
            &.block {
                margin-left: 1rem;
            }
        }

        .selector {
            min-width: 23.8rem;

            .Select-control {
                height: 4rem;

                .Select-value,
                .Select-placeholder {
                    line-height: 3.8rem;
                }
            }
        }
    }

    .reporting-actions {
        border-top: $border;
        padding: 2.4rem 0 0;
        margin-top: 4.8rem;

        .btn {
            margin: 0 2rem 0 0;
        }
    }
}

.report-action-icons {
    display: flex;
    align-items: center;
    margin-left: auto;

    .action-icon {
        font-size: $font-size-x-large;

        &:not(:last-child) {
            margin-right: .3rem;
        }

        &:not(.inactive) {
            cursor: pointer;

            &:hover {
                color: $color-link-hover;
            }
        }
    }
}
