$env: false;
@import '../../../../styles/variables';

.info-modal {
    max-width: 38rem;

    .modal-note.message-note {
        font-size: $font-size-medium;
    }
}
