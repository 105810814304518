/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
.widget-authorized-shares .aggregate-row {
  font-weight: 700;
}
.widget-authorized-shares .pie-content-container {
  position: relative;
  padding: 0.5rem 0;
  height: 16rem;
}
.widget-authorized-shares .active-link {
  cursor: pointer;
  color: #941f40;
}
.widget-authorized-shares .active-link:hover {
  color: #ef4e37;
}
.widget-authorized-shares .sign-color-default:before, .widget-authorized-shares .sign-color-default:after {
  color: #000;
}
.widget-authorized-shares .overfilling-row-container > a {
  display: flex;
}
.widget-authorized-shares .legend-container .overfilling-row {
  min-width: 17.5rem;
}
.widget-authorized-shares .table-container .overfilling-row {
  min-width: 19.5rem;
}