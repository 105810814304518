$env: false;
@import '../../../styles/variables';

.widget-registered-holder-type {
    .os-total {
        font-size: $font-size-small;
        padding: 0 0 1rem 1.2rem;

        &-text {
            font-weight: $font-weight-bold;
            margin-right: .5rem;
        }
    }

    .table-container {
        padding-top: 1rem;
    }

    .pie-content-container {
        padding: .5rem 0;
        height: 16rem;
    }
}
