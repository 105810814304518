$env: false;
@import '../../../../styles/colors';
@import '../../../../styles/variables';

.period-action-panel {
    display: flex;
    align-items: center;
    padding-top: 1rem;

    .message-note {
        margin-left: 1rem;
    }
}
