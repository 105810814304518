$env: false;
@import '../../../../styles/variables';
@import '../../../../styles/colors';

.widget-shareholder-information {
    .field-info {
        font-size: $font-size-small;
        line-height: 2;

        .name {
            font-weight: $font-weight-bold;
        }
    }

    .content {
        &-columns {
            display: flex;
            margin: 1.2rem 0;
        }

        &-column {
            flex-basis: 50%;
            flex-grow: 1;

            &:not(:first-child) {
                margin-left: 2.4rem;
            }

            .field-info {
                display: flex;
                align-items: center;
                height: 2.4rem;
                line-height: $line-height-base;
                border-bottom: 1px solid $_color-pale-grey;

                .name {
                    flex: 1;
                    font-weight: $font-weight-regular;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }

    &.one-column {
        width: 50%;

        .content {
            &-column {
                flex-basis: 100%;
            }
        }
    }
}
