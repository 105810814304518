$env: false;
@import '../../../../styles/variables';
@import '../../../../styles/colors';

.registered-overview-tabs {
    display: block; // IE 10 fix
    flex: 1 1 auto; // IE fix

    .high-level-tabs {
        display: flex;
        flex-direction: row;
        text-align: center;
        width: 100%;
        padding-bottom: 1rem;

        &:after {
            display: block; // IE 10 fix
            content: '';
            flex-grow: 1;
            border-bottom: $border;
        }

        .tab {
            padding: 2rem 1.7rem;
            cursor: pointer;
            color: $_color_marron;
            border-bottom: $border;
            white-space: nowrap;

            &:hover {
                color: $color-link-hover;
            }

            &.active {
                border: $border;
                border-bottom: none;
                text-shadow: .3px 0 0, -.3px 0 0;
                color: $color-tab-active;
                // Fix border offset
                margin-right: -1px;
                margin-top: -1px;
            }
        }
    }

    .sub-level-tabs {
        padding-bottom: 2.5rem;
    }
}
