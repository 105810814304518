$env: false;
@import '../../../styles/variables';

.common-columns-manager-view {
    $checkbox-list-width: 41rem;

    display: flex;
    flex-direction: column;
    max-height: 42rem;

    .checkbox-list {
        margin: 1.4rem 0;
        overflow: hidden;

        .checkbox-component {
            padding-right: 2.5rem;
            min-width: $checkbox-list-width / 2;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .checkbox-label {
            font-weight: normal;
            color: $color-default;
        }
    }

    .validate-block {
        position: relative;
        min-height: 5.8rem;

        &:empty {
            min-height: 2.4rem;
        }

        .form-error-summary {
            display: inline-block;

            .arrow {
                left: 4rem;
            }
        }
    }

    .btn-panel {
        border-top: $border;

        .btn {
            margin-right: 2rem;
            font-size: 1.6rem;
            font-weight: $font-weight-semi-bold;
        }
    }
}
