$env: false;
@import '../../styles/variables';
@import '../../styles/colors';

.combine-shareholders-page {
    display: flex;
    min-height: 100%;

    .nav-panel {
        flex: 0 0 30rem;
        max-width: 30rem;
        background-color: $color-aside-bg;
    }

    .page-content {
        padding: 2.4rem;
    }

    .content-header {
        font-weight: $font-weight-bold;
        font-size: $font-size-small;
        margin-bottom: 1.5rem;
    }
}
