$env: false;
@import '~tippy.js/dist/tippy.css';
@import '../../styles/variables';

.tippy-popper {
    .ast-tooltip-theme {
        max-width: $tooltip-max-width;
        z-index: $zindex-tooltip;
        font-size: $font-size-small;
        word-wrap: break-word;
        text-align: left;
        background-color: $tooltip-color;
        background-clip: padding-box;
        border: $tooltip-border-width solid $tooltip-border-color;
        border-radius: $border-radius;
        opacity: $tooltip-opacity;
        padding: $tooltip-padding-y $tooltip-padding-x;
        color: $color-text;
        box-shadow: none;
    }
}
