$env: false;
@import '../../../../../styles/colors';
@import '../../../../../styles/variables';

.funds-table {
    background: $color-table-header-bg-active;

    .rt-thead {
        display: none;
    }

    .rt-tr {
        border: none;
    }

    .error,
    .no-data {
        display: flex;
        align-items: center;
    }

    .rt-td.rt-expandable {
        width: 45px;
        justify-content: center;
    }

    .show-more {
        padding: 0 $table-cell-horizontal-padding;
        line-height: $table-body-row-height;
        color: $color-primary;
        cursor: pointer;

        &:hover {
            color: $color-link-hover;
        }
    }
}
