/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
.table-easy {
  font-size: 1.2rem;
}
.table-easy-with-footer {
  margin-bottom: 3rem;
}
.table-easy .text-right {
  justify-content: flex-end;
}
.table-easy .text-center {
  justify-content: center;
}
.table-easy .ReactVirtualized__Table__headerRow {
  text-transform: none;
  background-color: #f4f1ed;
  font-weight: 700;
  border: 0;
}
.table-easy .ReactVirtualized__Table__headerColumn {
  border-right: 1px solid #e9e3dc;
}
.table-easy .ReactVirtualized__Table__headerColumn:first-child {
  margin: 0;
}
.table-easy .ReactVirtualized__Table__headerColumn:last-child {
  border: 0;
}
.table-easy .ReactVirtualized__Table__headerTruncatedText {
  vertical-align: middle;
}
.table-easy .ReactVirtualized__Table__headerColumn, .table-easy .ReactVirtualized__Table__rowColumn {
  margin: 0;
  padding: 0 0.8rem;
  z-index: 1;
}
.table-easy .ReactVirtualized__Table__rowColumn {
  border-right: 1px #f4f1ed solid;
  transform: translateZ(1px);
}
.table-easy .ReactVirtualized__Table__rowColumn:first-of-type {
  margin: 0;
}
.table-easy .ReactVirtualized__Table__rowColumn:last-child {
  border: 0;
}
.table-easy .ReactVirtualized__Table__sortableHeaderColumn {
  outline: none;
  display: flex;
  align-items: center;
  position: relative;
}
.table-easy .ReactVirtualized__Table__sortableHeaderIcon {
  height: 22px;
}
.table-easy .ReactVirtualized__Table__sortableHeaderIcon.ReactVirtualized__Table__sortableHeaderIcon--ASC, .table-easy .ReactVirtualized__Table__sortableHeaderIcon.ReactVirtualized__Table__sortableHeaderIcon--DESC {
  fill: #941f40;
  font-size: 3.1rem;
}
.table-easy .ReactVirtualized__Table__row:hover {
  background-color: #f4f1ed;
}
.table-easy .ReactVirtualized__Table__row:hover .ReactVirtualized__Table__rowColumn {
  border-right: 1px solid #e9e3dc;
}
.table-easy .ReactVirtualized__Table__row:hover .ReactVirtualized__Table__rowColumn:last-child {
  border: 0;
}
.table-easy .virtualized-sticky-footer .ReactVirtualized__Table__rowColumn {
  transform: none;
}
.table-easy .virtualized-sticky-footer .total-hidden {
  visibility: hidden;
}
.table-easy .transparent-header .ReactVirtualized__Table__headerRow {
  background-color: transparent;
}
.table-easy .bar-chart {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 0;
  background: #accf83;
  width: 0;
  transform-origin: left;
  transition: transform 0.3s;
}
.table-easy .hide-bar-chart .bar-chart {
  transform: scaleX(0);
}
.table-easy .overfilling-row {
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
}
.table-easy .overfilling-row-container {
  display: flex;
  overflow: visible !important;
}