/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
.saved-reports-section {
  padding: 2.4rem;
}
.saved-reports-section .saved-reports-title {
  margin-bottom: 2.4rem;
  font-size: 1.8rem;
  font-weight: bold;
  color: #988a7d;
}
.saved-reports-section .saved-reports-legend {
  margin-bottom: 2.4rem;
}
.saved-reports-section .saved-reports-legend .legend-row {
  display: flex;
}
.saved-reports-section .saved-reports-legend .legend-container {
  display: flex;
  align-items: center;
}
.saved-reports-section .saved-reports-legend .legend-container:not(:last-child) {
  margin-right: 2.4rem;
}
.saved-reports-section .saved-reports-legend .legend-container .legend-name {
  font-size: 1.2rem;
}
.saved-reports-section .saved-reports-legend .legend-container .font-icon {
  margin-right: 0.8rem;
  font-size: 2.4rem;
}
.saved-reports-section .saved-reports-legend .message-note {
  margin-top: 1.5rem;
}

.saved-reports-list .report {
  display: flex;
  align-items: stretch;
  margin-bottom: 1.2rem;
}
.saved-reports-list .report:last-child {
  margin-bottom: 0;
}
.saved-reports-list .report-action-icons {
  flex-shrink: 0;
}
.saved-reports-list .report-name {
  color: #941f40;
  cursor: pointer;
}
.saved-reports-list .report-name:hover {
  color: #ef4e37;
}
.saved-reports-list .report-name.disabled {
  color: #941f40;
  opacity: 0.65;
}
.saved-reports-list .report-type-icon {
  margin-right: 0.8rem;
  font-size: 2.4rem;
}