$env: false;
@import '../../../../../../styles/colors';
@import '../../../../../../styles/variables';

.colored-checkbox-label {
    display: inline-flex;
    align-items: center;

    .text {
        font-size: $font-size-small;
        color: $color-default;
        margin-right: 1rem;
    }
}
