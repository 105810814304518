/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
/*  This is the general outline for all reporting pages
 *  The idea is to create some generic styles for all
 *  of the pages to inherit, each page has its own
 *  style to overwrite any of the below values if needed
 *
 *  reporting-page: reporting-nav (sidebar) and reporting-content
 *  reporting-content: is a wrapper around reporting-{name-of-page-here}-page
 *  which contains a possible reporting-header and form
 *  The form consists of reporting-section and reporting-actions
 *    reporting-section is a collection of reporting-section-header and reporting-section-body
 *        reporting-section-header: name of fields representing in reporting-section-body ie: `Share Type`
 *        reporting-section-body: collection of fields to collect user-input, etc ie: `RadioButtons, TextInput, etc`
 *    reporting-actions: a single or multiple call to action buttons to process form data ie: `Save`
 *
 */
.reporting-page {
  display: flex;
  height: 100%;
}
.reporting-page .reporting-nav {
  flex: 0 0 32rem;
  max-width: 32rem;
  background-color: #f8f6f4;
}
.reporting-page .reporting-nav-section {
  border-bottom: 1px solid #efeae5;
}
.reporting-page .reporting-content {
  position: relative;
  max-width: calc(100% - 32rem);
  flex: 1 0;
  padding: 2.4rem;
}
.reporting-page .reporting-header {
  margin: 0;
  padding: 0;
}
.reporting-page .reporting-edit {
  margin-bottom: 2.4rem;
  display: flex;
  align-items: center;
}
.reporting-page .reporting-edit-title {
  font-weight: 700;
  line-height: 2;
  color: #000;
}
.reporting-page .reporting-edit-title-input input {
  width: 25.2rem;
}
.reporting-page .reporting-section-header {
  font-weight: 700;
  line-height: 2;
  color: #000;
}
.reporting-page .reporting-section-header.disabled {
  opacity: 0.65;
}
.reporting-page .reporting-section-body {
  margin: 1.2rem 2.4rem;
}
.reporting-page .reporting-section-body .checkbox-label {
  color: #000;
}
.reporting-page .reporting-section-body .radio-component {
  margin: 0;
  padding: 0;
  color: #000;
}
.reporting-page .reporting-section-body .radio-label {
  line-height: 2.57;
  font-weight: 400;
  margin: 0 2.6rem 0 0.8rem;
}
.reporting-page .reporting-section-body .radio-buttons {
  margin-bottom: 2.4rem;
}
.reporting-page .reporting-section-body .radio-buttons-column {
  display: inline-block;
  margin-right: 5rem;
  vertical-align: top;
}
.reporting-page .reporting-section-body .radio-buttons-column:last-child {
  margin-right: 0;
}
.reporting-page .reporting-section-body .radio-buttons-column .radio-label {
  margin-right: 0;
}
.reporting-page .reporting-section-body .text-input-label {
  margin-bottom: 0.6rem;
}
.reporting-page .reporting-section-body .date-picker-component.block {
  margin-left: 1rem;
}
.reporting-page .reporting-section-body .selector {
  min-width: 23.8rem;
}
.reporting-page .reporting-section-body .selector .Select-control {
  height: 4rem;
}
.reporting-page .reporting-section-body .selector .Select-control .Select-value,
.reporting-page .reporting-section-body .selector .Select-control .Select-placeholder {
  line-height: 3.8rem;
}
.reporting-page .reporting-actions {
  border-top: 1px solid #e9e3dc;
  padding: 2.4rem 0 0;
  margin-top: 4.8rem;
}
.reporting-page .reporting-actions .btn {
  margin: 0 2rem 0 0;
}

.report-action-icons {
  display: flex;
  align-items: center;
  margin-left: auto;
}
.report-action-icons .action-icon {
  font-size: 2.4rem;
}
.report-action-icons .action-icon:not(:last-child) {
  margin-right: 0.3rem;
}
.report-action-icons .action-icon:not(.inactive) {
  cursor: pointer;
}
.report-action-icons .action-icon:not(.inactive):hover {
  color: #ef4e37;
}