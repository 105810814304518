/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
.search-result-context-panel .accordion-header {
  display: flex;
  line-height: 2.4rem;
}
.search-result-context-panel .panel-header {
  display: flex;
  width: 100%;
}
.search-result-context-panel .panel-header .header-context-type {
  flex: 11 0 auto;
  width: 220px;
  padding: 0 8px;
}
.search-result-context-panel .panel-header .header-issuer {
  flex: 5 0 auto;
  width: 100px;
  padding: 0 8px;
}
.search-result-context-panel .panel-header .header-security {
  flex: 6 0 auto;
  width: 120px;
  padding: 0 8px;
}
.search-result-context-panel .view-more-item {
  padding: 1.8rem 5.8rem;
}