$env: false;
@import 'src/styles/colors';
@import 'src/styles/variables';

.meeting-planner-tracker {
    .meeting-planner-tracker-content {
        display: flex;

        .meeting-planner-tracker__document-list {
            flex-basis: 38rem;
            flex-shrink: 0;
            padding-right: 2rem;
        }

        .meeting-planner-tracker__document-group {
            margin-bottom: 2rem;
        }

        .meeting-planner-tracker__document-group-header {
            font-weight: $font-weight-bold;
            margin: $padding-small-vertical 0;
        }

        .meeting-planner-tracker__document {
            color: $color-link;
            cursor: pointer;
            font-size: $font-size-small;

            &:hover {
                color: $color-link-hover;
            }
        }

        .meeting-planner-tracker__document + .meeting-planner-tracker__document {
            margin-top: $padding-small-vertical;
        }

        .meeting-planner-tracker__main {
            flex: 1;
        }

        .meeting-planner-tracker__time-line {
            text-align: center;
        }

        .meeting-planner-tracker__form {
            display: flex;
            align-items: flex-end;
            justify-content: center;
            margin-bottom: $padding-large-horizontal;

            .form-title {
                line-height: 4rem;
                margin: 0 8rem 0 0;

                .font-icon {
                    font-size: 2.2rem;
                    vertical-align: text-top;
                }

                .ast-popover {
                    margin-left: .5rem;
                }
            }

            .controls {
                gap: 2rem;
            }

            .field-control {
                position: relative;

                .input-container {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .text-input-label.disabled {
                        opacity: 1;
                        font-weight: 700;
                    }
                }

                .field-suffix {
                    height: 4rem;
                    display: flex;
                    align-items: center;
                    position: absolute;
                    right: 50%;
                    transform: translateX(2.5rem);
                    font-weight: $font-weight-bold;
                    color: $color-text-label;
                    font-size: 18px;
                }

                .text-input {
                    width: 6rem;
                    font-size: 18px;
                }

                .text-input input {
                    width: 100%;
                    opacity: 1;
                    text-align: center;
                    font-weight: $font-weight-bold;
                }
            }
        }
    }
}

.meeting-planner-popover {
    width: 60rem;

    .popover-content {
        font-size: $font-size-small;
    }
}
