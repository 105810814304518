@import 'variables';

// Generated by
// https://google-webfonts-helper.herokuapp.com/fonts/open-sans?subsets=latin-ext,latin

$font-path: '/assets/fonts/OpenSans/' !default;
$base-url: $font-path + 'open-sans-v15-latin_latin-ext-';

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    src:
        local('Open Sans Light'),
        local('OpenSans-Light'),
        url($base-url + '300.woff') format('woff');
}

@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 300;
    src:
        local('Open Sans Light Italic'),
        local('OpenSans-LightItalic'),
        url($base-url + '300italic.woff') format('woff');
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src:
        local('Open Sans Regular'),
        local('OpenSans-Regular'),
        url($base-url + 'regular.woff') format('woff');
}

@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 400;
    src:
        local('Open Sans Italic'),
        local('OpenSans-Italic'),
        url($base-url + 'italic.woff') format('woff');
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src:
        local('Open Sans SemiBold'),
        local('OpenSans-SemiBold'),
        url($base-url + '600.woff') format('woff');
}

@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 600;
    src:
        local('Open Sans SemiBold Italic'),
        local('OpenSans-SemiBoldItalic'),
        url($base-url + '600italic.woff') format('woff');
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    src:
        local('Open Sans Bold'),
        local('OpenSans-Bold'),
        url($base-url + '700.woff') format('woff');
}

@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 700;
    src:
        local('Open Sans Bold Italic'),
        local('OpenSans-BoldItalic'),
        url($base-url + '700italic.woff') format('woff');
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 800;
    src:
        local('Open Sans ExtraBold'),
        local('OpenSans-ExtraBold'),
        url($base-url + '800.woff') format('woff');
}

@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 800;
    src:
        local('Open Sans ExtraBold Italic'),
        local('OpenSans-ExtraBoldItalic'),
        url($base-url + '800italic.woff') format('woff');
}

$inter-base-url: '/assets/fonts/Inter/Inter-';

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    src: url($inter-base-url + 'Light.ttf') format('truetype');
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    src: url($inter-base-url + 'Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: url($inter-base-url + 'SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    src: url($inter-base-url + 'Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 800;
    src: url($inter-base-url + 'ExtraBold.ttf') format('truetype');
}
