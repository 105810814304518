$env: false;
@import '../../../../../styles/colors';
@import '../../../../../styles/variables';

.shareholder-positions-container {
    height: $position-history-height;
    position: relative;
}

.dataviz-slot {
    .shareholder-positions-container {
        max-width:
            calc(
                100vw - #{$reporting-nav-width} - 2 * #{$reporting-content-padding} -
                (100vw - #{$reporting-nav-width}) / 100 * 3.333 - 2 * #{$layout-dataviz-widget-padding}
            );
    }
}

.shareholder-positions {
    background: $color-aside-bg;
    position: absolute;
    left: 0;
    right: 0;
    height: $position-history-height;
    min-width: 120rem;

    & .close-icon {
        position: absolute;
        top: .5rem;
        right: 1rem;
        z-index: 2;

        .font-icon.font-icon-clear {
            cursor: pointer;
        }
    }

    .error,
    .no-data {
        line-height: $table-body-row-height;
        padding-left: $table-expander-size;
    }
}

.shareholder-positions-list {
    width: 100%;
    height: $position-history-height;
}

.highcharts-xaxis-labels span {
    overflow: visible !important;
}

.positions-list {
    width: 43.5rem;
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-shrink: 0;

    &-controls {
        padding: .8rem;
        font-size: 1.4rem;
        flex-shrink: 2;
    }

    .position-info {
        margin-left: 3rem;
        color: $color-text-info;
        font-style: italic;
    }

    .xignite-link {
        display: block;
        margin: 1rem 0 0 3rem;
    }

    &-table {
        position: relative;
        flex-basis: 100%;
        display: flex;
        justify-content: flex-end;
        overflow-y: scroll;
        height: 100%;

        .position-source {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        & table {
            border-collapse: collapse;
            font-size: 1.2rem;

            & td {
                padding: 0 1.2rem;
                min-width: 8rem;

                &:nth-of-type(2) {
                    text-align: right;
                }

                &:nth-of-type(3) {
                    color: $color-primary;
                    text-align: right;
                    padding-right: $table-cell-horizontal-padding;
                }
            }

            & tr {
                border-bottom: $border;
                height: $table-body-row-height;

                &.muted {
                    & td {
                        color: $color-obsolete-position;
                    }
                }
            }
        }
    }
}

.positions-chart {
    display: inline-block;
    position: absolute;
    top: 0;

    .highcharts-title,
    .highcharts-legend-item {
        tspan {
            cursor: default;
            fill: $color-text;
            font-size: $font-size-medium;
            font-style: italic;
            font-weight: $font-weight-regular;
            font-family: $font-name;
        }
    }

    & .chart-position {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: black;
        line-height: 1.7rem;
        transform: translateX(-50%);

        & .position-date {
            color: $shareholder-positions-date;
            display: inline-block; // IE 10 fix
        }

        & .no-volume {
            font-style: italic;
        }
    }
}
