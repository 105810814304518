$env: false;
@import '../../../../styles/variables';

.select-peers-popover {
    display: flex;
    flex-direction: column;

    .checkbox-label {
        font: {
            weight: $font-weight-regular;
            size: $font-size-small;
        }

        .unavailable-peer {
            font-style: italic;
        }
    }

    .close-btn {
        text-align: right;

        .font-icon {
            font-size: 2.1rem;
            cursor: pointer;
        }
    }

    .btn-panel {
        padding-top: 1.3rem;
    }

    .btn {
        box-shadow: $shadow-default;
    }

    .checkbox-list {
        overflow: hidden;
    }
}
