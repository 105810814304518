$env: false;
@import '../../../styles/variables';
@import '../../../styles/colors';

.accordion {
    .accordion-header {
        background-color: $color-aside-bg;
        border-bottom: $border;

        @if ($env) {
            border-color: #dcdfe4;
        }
        padding: .8rem 1.2rem;
        font-weight: $font-weight-bold;
        cursor: pointer;

        .font-icon-paging-right {
            font-size: 1.2rem;
            height: 1.2rem;
            align-self: center;
            margin: 0 2rem 0 1rem;
            padding: 0;
        }
    }

    .collapse {
        display: none;

        &.in {
            display: block;
        }
    }

    .collapsing {
        position: relative;
        height: 0;
        overflow: hidden;
        transition-property: height, visibility;
        transition-duration: $animation-duration;
        transition-timing-function: ease;
    }
}
