$env: false;
@import '../../styles/colors';
@import '../../styles/variables.scss';

.terms-text {
    height: 100%;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: $color-default;
    }

    ul {
        list-style: disc;
    }

    ol {
        list-style: lower-alpha;
    }

    ol,
    ul {
        margin-left: 4rem;

        li {
            padding: 1rem;
        }
    }

    .underline {
        text-decoration: underline;
    }

    .center {
        text-align: center;
    }

    .scroll {
        font-size: $font-size-large;
    }
}
