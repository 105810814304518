@import 'colors';
@import 'variables';

html {
    font-size: $font-size-root;
}

body {
    font-family: $font-name, sans-serif;
    font-size: $font-size-medium;
    line-height: $line-height-base;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: $color-header;
}

h1 {
    font-size: $font-size-xx-large;
    font-weight: $font-weight-bold;
}

h2 {
    font-size: $font-size-x-large;
    font-weight: $font-weight-bold;
}

h3 {
    font-size: $font-size-large;
    font-weight: $font-weight-bold;
}

h4 {
    font-size: $font-size-medium;
}

h5 {
    font-size: $font-size-small;
}

h6 {
    font-size: $font-size-x-small;
}
