@import 'colors';

$images-path: '/assets/images';

$icon-font-path: '/assets/fonts/icon-font/' !default;
$icon-font-name: 'ast-font' !default;
$icon-inactive-opacity: .65;

$shadow-default: 0 2px 2px 0 $color-btn-shadow !default;

$font-name:                 'Open Sans';

@if ($env) {
    $font-name:             'Inter';
}

$font-weight-light:         300 !default;
$font-weight-regular:       400 !default;
$font-weight-semi-bold:     600 !default;
$font-weight-bold:          700 !default;
$font-weight-extra-bold:    800 !default;

$font-size-root:            10px !default; // 1 rem = 10px;
$font-size-base:            1.4rem !default;
$font-size-x-small:         1rem !default;
$font-size-small:           1.2rem !default;
$font-size-medium:          1.4rem !default;
$font-size-large:           1.8rem !default;
$font-size-x-large:         2.4rem !default;
$font-size-xx-large:        3.2rem !default;

$line-height-base:          1.5 !default;
$line-height-header:        3.6 !default;

/* Border */
$border:                    1px solid $color-border !default;
$border-dark:               1px solid $color-border-dark !default;
$border-radius:             .3rem !default;

/* Buttons */
$btn-border-radius-base:    .3rem;
$btn-border-width:          1px;
$btn-margin:                .5rem;
$btn-font-weight:           $font-weight-bold;

/* Icons */
$icon-base-size:            $font-size-medium !default;
$icon-large-size:           $font-size-x-large !default;

/* Modals */
$modal-inner-padding:       1.5rem 2.4rem !default;

$modal-overlay-opacity:     .5 !default;
$modal-header-padding:      1.5rem !default;
$modal-transition:          transform .3s ease-out !default;

/* Padding */
$padding-base-vertical:     .8rem !default;
$padding-base-horizontal:   2rem !default;

$padding-large-vertical:    1rem !default;
$padding-large-horizontal:  3rem !default;

$padding-small-vertical:    .5rem !default;
$padding-small-horizontal:  1rem !default;

$cursor-disabled:           not-allowed !default;

/* z-index */
$zindex-chart-legend:         1 !default;
$zindex-spinner:              1000 !default;
$zindex-buttons-group:        1060 !default;
$zindex-popover:              1090 !default;
$zindex-dropdown:             1095 !default;
$zindex-loading-block:        1110 !default;
$zindex-important:            1115 !default;
$zindex-tooltip:              1125 !default;
$zindex-datepicker:           1130 !default;
$zindex-modal-overlay:        1135 !default;
$zindex-spinner-blocker:      1140 !default;

/* Tooltip */
$tooltip-max-width:           25rem !default;
$tooltip-color:               $_color-white !default;
$tooltip-opacity:             1 !default;
$tooltip-padding-y:           .7rem !default;
$tooltip-padding-x:           1rem !default;
$tooltip-border-width:        .1rem !default;
$tooltip-border-color:        rgba($_color-black, .2) !default;
$tooltip-margin:              .3rem;

/* Popover */
$border-radius-lg: .3rem !default;
$popover-inner-padding: .1rem !default;
$popover-bg: $_color-white !default;
$popover-max-width: 80rem !default;
$popover-border-width: .1rem !default;
$popover-border-color: rgba($_color-black, .2) !default;

$popover-title-bg: darken($popover-bg, 3%) !default;
$popover-title-padding-x: 1.4rem !default;
$popover-title-padding-y: .8rem !default;

$popover-content-padding-x: 1.4rem !default;
$popover-content-padding-y: .9rem !default;

$popover-arrow-width: 1rem !default;
$popover-arrow-color: $_color-white !default;

$popover-arrow-outer-width: ($popover-arrow-width + .1rem) !default;
$popover-arrow-outer-color: fade-in($popover-border-color, .05) !default;
$popover-min-width: 20rem !default;

/* Dropdown */
$dropdown-min-width: 16rem;

/* Responsive */
$responsive-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px
) !default;

/* Animation */
$animation-duration: .3s;

/* Nav */
$nav-link-text-size: $font-size-small;

/* Link */
$link-active-text-shadow: .1px 0 0, -.1px 0 0;

/* Form */
$height-base: 4rem !default;
$field-disabled-opacity: .4 !default;

/* Table */
$table-icons-size: 2.2rem !default;
$table-expander-size: 3.5rem !default;

$table-heading-row-height: 6.4rem !default;

$table-body-row-height: 4rem !default;
$table-body-row-border: 1px solid $_color-pale-grey !default;

$table-cell-horizontal-padding: .8rem !default;

/* User image */
$user-image-size:       16rem !default;

/* Position History */
$position-history-height: 32rem;

/* Shareholder table */
$shareholder-table-action-panel-tooltip-height: 3.6rem;

/* Institutional Shareholders */
$institutional-shareholders-widgets-content-height: 30.6rem;

/* Slot */
$column-width-max: 30%;
$column-width-min: 41.8rem;
$column-margin-right: 3.33333%;
$column-margin-bottom: 2.4rem;

/* Reporting page */
$reporting-nav-width: 32rem;
$reporting-content-padding: 2.4rem;
$layout-dataviz-widget-padding: 2.1rem;
$layout-dataviz-widget-top-padding: 2.1rem;

/* Header */
$app-header-height: 12.8rem;

/* Footer */
$app-footer-height: 7.5rem;
