$env: false;
@import '../../../styles/colors';
@import '../../../styles/variables';

.shareholder-information {
    .address-type {
        text-transform: capitalize;
    }

    .message-note:not(:last-child) {
        margin-bottom: 1.2rem;
    }

    .widget {
        &-content {
            padding: 2.2rem;
            background: $color-aside-bg;
            height: auto;
        }

        &-accordion {
            padding-top: 1.2rem;
        }

        &-title-container {
            .header-info {
                font-size: $font-size-large;
                margin-bottom: 1.2rem;
            }

            .title,
            .info {
                font-weight: $font-weight-bold;
            }

            .tax-category {
                display: flex;
            }

            .ssn-tax-container {
                margin-top: 1rem;
            }
        }
    }

    .accordion {
        &-header {
            display: flex;
            border: none;
            padding: 0;
            font-weight: inherit;
            line-height: 2rem;
            color: $color-primary;

            .font-icon-paging-right {
                margin: 0 1.3rem 0 0;
            }

            .font-icon-arrow-drop-down {
                font-size: $font-size-x-large;
                margin: 0 0 0 -.5rem;
            }
        }
    }

    .bad-address-container,
    .last-contact-date-container,
    .email-container {
        margin-top: 1.2rem;

        .title {
            font-weight: $font-weight-bold;
        }
    }

    .last-contact-date-container {
        .title {
            margin-right: .5rem;
        }
    }

    .position {
        &-block {
            padding-bottom: 2rem;
        }

        &-row {
            display: flex;
            flex-direction: row;
            border-bottom: $border;
            line-height: 3rem;

            &.drp-row {
                line-height: 2rem;
                padding-bottom: .5rem;
            }
        }

        &-title {
            margin-right: auto;
            flex-shrink: 0;
        }

        &-value {
            display: inline-block; // IE10 fix
            font-weight: $font-weight-bold;
            text-align: right;
            padding-left: .5rem;
        }
    }

    .note-mark {
        font-weight: $font-weight-regular;
    }
}
