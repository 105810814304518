$env: false;
@import '../../../styles/variables';
@import '../../../styles/colors';

.period-range {
    display: flex;
    flex-wrap: wrap;

    &.small {
        width: 20.5rem;
        height: 6.8rem;
    }

    .period {
        box-sizing: border-box;
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 3.5rem;
        height: 3.5rem;
        border: $border;
        color: $color-primary;
        font-size: $font-size-small;
        margin: 0 0 -.1rem -.1rem;
        cursor: pointer;

        &.active {
            color: $color-intervals-active-text;
            background-color: $color-intervals-active-bg;
            cursor: default;
        }

        &:not(.active):hover {
            color: $color-link-hover;
        }

        .font-icon {
            font-size: 2rem;
        }
    }
}
