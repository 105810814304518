/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
.company-information {
  margin-bottom: 2.5rem;
}
.company-information .company-data {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 1.8rem;
}
.company-information .company-data .company-name {
  font-weight: 700;
  white-space: nowrap;
}
.company-information .company-data .stock-exchange {
  margin-right: 3.9rem;
  margin-left: 0.5rem;
}
.company-information .company-data .current-price,
.company-information .company-data .change-price {
  margin-right: 1.2rem;
  font-weight: 700;
}
.company-information .company-data .change-price.positive:before {
  content: "+";
}
.company-information .company-data .change-price.negative:before {
  content: "-";
}
.company-information .company-data .change-price.neutral {
  color: #599f08;
}
.company-information .company-data .full-quote {
  font-size: 1.2rem;
  color: #941f40;
  cursor: pointer;
  white-space: nowrap;
  margin-left: 0.5rem;
}
.company-information .company-data .full-quote:hover {
  color: #ef4e37;
}
.company-information .company-data .no-data-message {
  font-size: 1.4rem;
  margin-right: 1.2rem;
}
.company-information .message-note {
  margin: 1.2rem 0;
}