@import '../colors';
@import '../variables';

.toastr {
    &.rrt-info.notification {
        &-info {
            background-color: $color-bar-chart;
        }
    }

    .rrt-middle-container {
        .rrt-text {
            word-wrap: break-word;
        }
    }

    .error-message {
        font-size: 1.6rem;
    }
}
