/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
.ownership-page .page-content {
  padding: 2.4rem;
}
.ownership-page .shareholders-table .ReactTable {
  overflow-x: visible;
}
.ownership-page .ownership-nav {
  display: flex;
  border-bottom: 1px solid #e9e3dc;
}
.ownership-page .ownership-nav .nav.horizontal {
  border-bottom: 0;
}
.ownership-page .ownership-nav .treasury-accounts-link {
  padding: 1.1rem 2.4rem;
  margin-left: auto;
}