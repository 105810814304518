/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
.cell-with-ellipsis {
  cursor: pointer;
  word-break: break-all;
}

.multi-row-ellipsis {
  position: relative;
  height: 3.8rem;
  overflow: hidden;
}
.multi-row-ellipsis:after {
  content: "...";
  position: absolute;
  top: 1.8rem;
  right: 0;
  background-color: #fff;
}

.rt-tr:hover .multi-row-ellipsis:after {
  background-color: #f4f1ed;
}