$env: false;
@import '../../../../styles/variables';

.registered-overview-control-panel {
    position: absolute;
    top: 1.5rem;
    right: 0;

    .font-icon {
        font-size: $icon-large-size;
        margin-left: .6rem;
        line-height: 3.6rem;
        vertical-align: middle;

        &:not(.inactive) {
            cursor: pointer;
        }
    }

    .first-slot & {
        z-index: 1;
        right: 2rem;
    }
}
