$env: false;
@import '../../../../styles/colors';
@import '../../../../styles/variables';

.reporting-geographic-analysis-page-header {
    display: flex;
    align-items: center;
    margin-bottom: 2.4rem;

    .message-note {
        margin-left: 1.2rem;
    }

    .title {
        margin: 0;
    }
}
