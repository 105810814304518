$env: false;
@import '../../styles/colors';
@import '../../styles/variables';

.form-error {
    &.popover {
        background-color: $color-error-background;
        border: none;
        top: 100%; // top: 4.1rem

        .popover-content {
            padding: .6rem 1rem;
            min-width: $popover-min-width;
        }

        &.bottom > .arrow {
            border-bottom-color: $color-error-background;

            &:after {
                border-bottom-color: $color-error-background;
            }
        }
    }
}
