$env: false;
@import '../../../../styles/variables';
@import '../../../../styles/colors';

.analysis-chart {
    .highcharts-axis-line {
        display: none;
    }

    .highcharts-xaxis {
        display: none;
    }
}
