$env: false;
@import '../../../styles/variables';

.registered-shareholder-client-uploads {
    &.widget-registered-shareholder-table {
        .description {
            margin-bottom: 0;
        }
    }

    .font-icon-download {
        &:not(.inactive) {
            cursor: pointer;
        }
        font-size: 2.4rem;
        padding: 0;
        justify-content: center;
    }

    .td-description {
        justify-content: flex-start;
    }
}
