$env: false;
@import 'src/styles/variables';
$column-count: 3;

.layout-three-columns {
    flex-direction: column;

    .row {
        width: 100%;
        margin-bottom: 3.8rem;
    }

    .column {
        //width: calc(100% / #{$column-count});
        width: 0;
        flex-grow: 1;
    }

    .three-column-container {
        border-bottom: $border;
        padding-bottom: 2.4rem;

        .column:not(:last-child) {
            margin-right: 5rem;
        }
    }
}
