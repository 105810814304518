$env: false;
@import '../../../../styles/colors';
@import '../../../../styles/variables';

.auto-suggest {
    &-label {
        display: inline-block;
        font-weight: $font-weight-regular;
        font-size: $font-size-medium;
        color: $color-text;
        white-space: nowrap;
        margin-bottom: .5rem;
    }

    .Select {
        height: $height-base;

        &-value {
            padding-right: 2rem !important;
        }

        &-control,
        &-arrow-zone,
        &-loading-zone {
            display: flex;
            align-items: center;
        }

        &-loading-zone {
            width: auto;
        }

        &-control,
        &-input,
        &-multi-value-wrapper {
            height: 100%;
            width: 100%;
        }

        &-value-label,
        &-placeholder {
            line-height: $height-base;
        }

        &-input {
            > input {
                padding: .8rem 1.2rem .8rem 0;
            }
        }

        &-arrow-zone {
            cursor: default;
            pointer-events: none;

            .font-icon {
                vertical-align: middle;
            }
        }

        &-option {
            &:not(:last-child) {
                border-bottom: $border;
            }

            &.is-focused {
                background-color: initial;
            }
        }

        // emulate "outline: none;"
        &.is-focused:not(.is-open) > .Select-control {
            border-color: $color-border;
            box-shadow: none;
        }
    }
}
