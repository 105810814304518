$env: false;
@import '../../../../styles/variables';

.alerts-section {
    position: relative;

    .font-icon {
        font-size: $table-icons-size;
        vertical-align: text-top;
        cursor: pointer;

        &.inactive {
            cursor: default;
        }
    }

    .badge {
        position: absolute;
        bottom: 65%;
        left: 50%;
    }
}
