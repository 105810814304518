$env: false;
@import '../../../../styles/variables';
@import '../../../../styles/colors';

.reporting-share-range-analysis-results-page {
    .widget-header {
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;

        & > * {
            margin-right: 2.7rem;
        }
    }

    .cell-with-number {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }

    .cell-with-share-range {
        display: flex;
        width: 100%;

        .share-range {
            width: 50%;
            max-width: 10rem;
            padding-right: .5rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    .reporting-share-range-analysis-results-table {
        margin-top: 3.2rem;

        .ReactTable {
            overflow-x: visible;
        }

        .rt-thead {
            height: 4rem;
        }

        .rt-th:not(:first-child) {
            justify-content: center;
            text-align: center;
        }

        .rt-tfoot {
            .rt-td:not(:first-of-type) {
                justify-content: flex-end;
            }
        }
    }

    .message-note {
        font-weight: $font-weight-regular;
    }
}
