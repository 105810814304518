$env: false;
@import '../../../styles/colors';
@import '../../../styles/variables';

.profile-menu {
    display: flex;
    flex-shrink: 1;
    align-items: center;
    height: 4rem;
    min-width: 0;

    .profile-avatar {
        $image-size: 2.4rem;

        height: 2.4rem;
        width: 2.4rem;
        max-width: $image-size;
        max-height: $image-size;
        border-radius: 50%;
        border: 1px solid $color-avatar-outline;
    }

    .profile-avatar-container {
        position: relative;

        .badge {
            position: absolute;
            bottom: 65%;
            left: 50%;
        }
    }

    .font-icon {
        font-size: $icon-large-size;
        padding-right: .4rem;
        vertical-align: middle;
        cursor: pointer;
    }

    .user-name {
        flex: 1 1 auto;
        min-width: 10rem;
        text-align: right;
        padding: 0 1rem 0 0;
    }

    .toggle {
        display: inline-block;
        height: 100%;
    }

    .menu-list {
        min-width: 28rem;
        margin-top: 1.9rem;
        padding-top: 0;

        a {
            position: relative;
            padding: 1.2rem;
            display: flex;
            align-items: center;
        }

        .badge {
            margin-left: auto; // right align
        }

        .profile-avatar {
            height: 2.1rem;
            width: 2.1rem;
            margin: 0 .4rem;
            border-radius: 50%;
            border: 1px solid $color-avatar-outline;
        }
    }
}
