$env: false;
@import '../../../../../styles/variables';

.add-note-link {
    display: inline-block;
    font-size: $font-size-medium;
    color: $color-link;
    cursor: pointer;
    margin: 1.2rem 1rem;

    &:hover {
        color: $color-link-hover;
    }
}
