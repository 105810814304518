/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
.analysis-table.ReactTable {
  overflow-x: visible;
}
.analysis-table .rt-table .-headerGroups {
  height: 8.4rem;
  background-color: transparent;
}
.analysis-table .rt-table .-headerGroups .additional-component {
  padding: 0;
}
.analysis-table .rt-table .header-shareholder-name {
  padding-left: 3.5rem;
}
.analysis-table .rt-table .no-group-header-cell .label {
  font-style: italic;
  font-weight: normal;
}
.analysis-table .rt-table .position,
.analysis-table .rt-table .os,
.analysis-table .rt-table .mkt-val {
  justify-content: center;
  text-align: center;
  padding: 0 1.7rem 0 0.5rem;
}
.analysis-table .rt-table .position.rt-td,
.analysis-table .rt-table .os.rt-td,
.analysis-table .rt-table .mkt-val.rt-td {
  padding: 0 0.8rem;
}
.analysis-table .rt-table .position div,
.analysis-table .rt-table .os div,
.analysis-table .rt-table .mkt-val div {
  flex-shrink: 1;
}
.analysis-table .rt-table .position .sort-icon,
.analysis-table .rt-table .os .sort-icon,
.analysis-table .rt-table .mkt-val .sort-icon {
  right: 0;
}
.analysis-table .rt-table .rt-td:nth-child(6n-4),
.analysis-table .rt-table .rt-td:nth-child(6n-3),
.analysis-table .rt-table .rt-td:nth-child(6n-2),
.analysis-table .rt-table .rt-th.position:nth-child(even),
.analysis-table .rt-table .rt-th.os:nth-child(odd),
.analysis-table .rt-table .rt-th.mkt-val:nth-child(even) {
  background-color: rgba(203, 196, 190, 0.3);
}
.analysis-table .group-header-cell {
  display: flex;
  justify-content: center;
}
.analysis-table .group-header-cell .wrapper {
  display: flex;
  flex-shrink: 1;
}
.analysis-table .group-header-cell .wrapper .label {
  display: inline-block;
  font-weight: bold;
  flex-shrink: 1;
}
.analysis-table .group-header-cell .wrapper .delete-icon {
  margin-left: 1rem;
  cursor: pointer;
}
.analysis-table .border-top {
  border-top: 1px solid #e9e3dc;
}
.analysis-table .cell-with-shareholder-info {
  display: flex;
  align-items: center;
  width: 100%;
}
.analysis-table .cell-with-shareholder-info .shareholder-name {
  margin-left: 0.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-shrink: 1;
  display: block;
}
.analysis-table .cell-with-shareholder-info .font-icon-insider {
  cursor: auto;
}
.analysis-table .arrow-scroll-block {
  width: 5rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.analysis-table .additional-group-cell {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  font-weight: normal;
}
.analysis-table .additional-group-cell .scroll-block {
  height: 100%;
}
.analysis-table .additional-group-cell .additional-block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  flex-basis: 0;
  margin: 0.5rem 0;
}
.analysis-table .additional-group-cell .additional-block-top {
  display: flex;
  justify-content: space-between;
}
.analysis-table .additional-group-cell .additional-block .message-note {
  padding-right: 0.5rem;
}
.analysis-table .additional-group-cell .additional-block .xignite-link {
  font-size: 1.4rem;
  flex-shrink: 0;
}
.analysis-table .main-column-heading {
  display: flex;
  align-items: center;
  line-height: 1rem;
}
.analysis-table .main-column-heading .search-icon {
  display: inline-block;
  padding-left: 1rem;
}
.analysis-table .main-column-heading .search-icon.disabled .font-icon {
  cursor: inherit;
  color: #7f7369;
}

.ReactTable .rt-td.rt-td--display-none,
.rt-th.group-header-cell--display-none.-cursor-pointer {
  display: none;
}