/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
.share-report-template__message-note {
  margin-top: 2.4rem;
  margin-bottom: 1.5rem;
  font-size: 1.2rem;
  font-style: italic;
  color: #6b6056;
  line-height: 1.5;
}
.share-report-template__message-note .color-primary {
  color: #941f40;
  font-weight: 700;
}
.share-report-template__user-list-header {
  display: flex;
  align-items: center;
  padding-left: 4rem;
  background-color: #f4f1ed;
  font-weight: 700;
  color: #6b6056;
  height: 3.5rem;
}
.share-report-template__user-list {
  border: 0.2rem solid #e9e3dc;
}
.share-report-template__user-list .list-item {
  display: flex;
  align-items: center;
  color: #6b6056;
  padding-left: 1.5rem;
  border-bottom: 0.1rem solid #e9e3dc;
}
.share-report-template__user-list .list-item label {
  font-weight: 400;
}
.share-report-template .modal-actions {
  margin-top: 2.5rem;
}
.share-report-template .modal-actions:before {
  display: none;
}