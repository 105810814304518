$env: false;
@import '../../styles/colors';
@import '../../styles/variables';

.page-header {
    position: relative;
    min-height: 8rem;
    display: flex;
    padding: .4rem 0;
    border-bottom: 2px solid $color-border;

    .app-version {
        position: absolute;
        bottom: 0;
        left: 1.5rem;
    }

    .section {
        display: flex;
        flex-shrink: 0;
        margin-top: 1rem;
    }

    .section-grow {
        flex: 1 1;
        margin-top: 1rem;
        min-width: 61rem;
    }

    .logo-container {
        margin-top: .5rem;
    }

    .ast-logo {
        // ratio: 39/16
        height: 5rem;
        width: 12.2rem;

        &-link {
            margin: 0 1rem;
        }
    }

    .profile-section {
        display: flex;
        flex: 0 1 auto;
        min-width: 0;
        padding: 0 2.4rem 0 2rem;
    }
}

.--demo {
    .page-header {
        .ast-logo {
            height: 3.2rem;
            width: 3.2rem;
        }
    }
}
