/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
.meeting-planner-tracker .meeting-planner-tracker-content {
  display: flex;
}
.meeting-planner-tracker .meeting-planner-tracker-content .meeting-planner-tracker__document-list {
  flex-basis: 38rem;
  flex-shrink: 0;
  padding-right: 2rem;
}
.meeting-planner-tracker .meeting-planner-tracker-content .meeting-planner-tracker__document-group {
  margin-bottom: 2rem;
}
.meeting-planner-tracker .meeting-planner-tracker-content .meeting-planner-tracker__document-group-header {
  font-weight: 700;
  margin: 0.5rem 0;
}
.meeting-planner-tracker .meeting-planner-tracker-content .meeting-planner-tracker__document {
  color: #941f40;
  cursor: pointer;
  font-size: 1.2rem;
}
.meeting-planner-tracker .meeting-planner-tracker-content .meeting-planner-tracker__document:hover {
  color: #ef4e37;
}
.meeting-planner-tracker .meeting-planner-tracker-content .meeting-planner-tracker__document + .meeting-planner-tracker__document {
  margin-top: 0.5rem;
}
.meeting-planner-tracker .meeting-planner-tracker-content .meeting-planner-tracker__main {
  flex: 1;
}
.meeting-planner-tracker .meeting-planner-tracker-content .meeting-planner-tracker__time-line {
  text-align: center;
}
.meeting-planner-tracker .meeting-planner-tracker-content .meeting-planner-tracker__form {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: 3rem;
}
.meeting-planner-tracker .meeting-planner-tracker-content .meeting-planner-tracker__form .form-title {
  line-height: 4rem;
  margin: 0 8rem 0 0;
}
.meeting-planner-tracker .meeting-planner-tracker-content .meeting-planner-tracker__form .form-title .font-icon {
  font-size: 2.2rem;
  vertical-align: text-top;
}
.meeting-planner-tracker .meeting-planner-tracker-content .meeting-planner-tracker__form .form-title .ast-popover {
  margin-left: 0.5rem;
}
.meeting-planner-tracker .meeting-planner-tracker-content .meeting-planner-tracker__form .controls {
  gap: 2rem;
}
.meeting-planner-tracker .meeting-planner-tracker-content .meeting-planner-tracker__form .field-control {
  position: relative;
}
.meeting-planner-tracker .meeting-planner-tracker-content .meeting-planner-tracker__form .field-control .input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.meeting-planner-tracker .meeting-planner-tracker-content .meeting-planner-tracker__form .field-control .input-container .text-input-label.disabled {
  opacity: 1;
  font-weight: 700;
}
.meeting-planner-tracker .meeting-planner-tracker-content .meeting-planner-tracker__form .field-control .field-suffix {
  height: 4rem;
  display: flex;
  align-items: center;
  position: absolute;
  right: 50%;
  transform: translateX(2.5rem);
  font-weight: 700;
  color: #6b6056;
  font-size: 18px;
}
.meeting-planner-tracker .meeting-planner-tracker-content .meeting-planner-tracker__form .field-control .text-input {
  width: 6rem;
  font-size: 18px;
}
.meeting-planner-tracker .meeting-planner-tracker-content .meeting-planner-tracker__form .field-control .text-input input {
  width: 100%;
  opacity: 1;
  text-align: center;
  font-weight: 700;
}

.meeting-planner-popover {
  width: 60rem;
}
.meeting-planner-popover .popover-content {
  font-size: 1.2rem;
}