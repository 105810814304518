$env: false;
@import '../../styles/variables';
@import '../../styles/colors';

.search-by-security-selector {
    width: 100%;
    padding-bottom: 5rem;

    .radio-buttons {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .search-input {
        display: flex;

        .form-error-summary {
            margin: .5rem 0;
            padding: 0 0 0 1rem;

            .inner {
                padding: .6rem;
            }

            .arrow {
                position: absolute;
                top: 32%;
                left: 0;
                margin-left: .6rem;
                border-width: 5px 5px 5px 0;
                border-right-color: #ef4e37;
                border-top-color: transparent;
            }
        }
    }

    .search-input-field {
        margin-right: .8rem;
        flex: auto;
        max-width: 42rem;
        position: relative;

        .spinner-container {
            position: absolute;
            right: 1rem;
            line-height: 1;
            top: 50%;
            transform: translateY(-50%);
        }

        .text-input-label.horizontal {
            margin-right: 0;
        }

        .text-input {
            &,
            input {
                width: 100%;
            }

            input {
                padding-right: 3.2rem;
            }
        }
    }
}
