$env: false;
@import '../../styles/variables';
@import '../../styles/colors';

.search-page {
    min-height: 100%;
    padding: 2.4rem 2.4rem 0 8rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .search-across-title {
        margin: 0 0 .4rem;
    }

    .title {
        margin-bottom: .4rem;
    }

    .multiple-security-selector-header {
        display: flex;
        align-items: baseline;

        .title {
            margin-top: 2rem;
        }

        .message-note {
            margin-left: .6rem;
        }
    }

    .search-across-selector {
        height: 3.2rem; // ie fix checkbox's jumping
    }
}
