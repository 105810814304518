$env: false;
@import '../../../../../styles/variables';

.control-books-tabs {
    display: flex;
    flex-direction: row;
    text-align: center;
    margin-top: 2rem;

    .control-books-tab {
        border: 1px solid transparent;
        border-bottom: $border;
        padding: 1.5rem;
        cursor: pointer;
        color: $color-primary;
        font-weight: $font-weight-regular;
        white-space: nowrap;

        &:hover {
            color: $color-link-hover;
        }

        &.active {
            border: $border;
            border-bottom: 1px solid transparent;
            color: $color-tab-active;
            text-shadow: .3px 0 0, -.3px 0 0;
            cursor: default;
        }
    }

    &-overlay {
        flex: 1;
        border-bottom: $border;
    }
}
