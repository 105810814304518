$env: false;
@import '../../../../../styles/colors';
@import '../../../../../styles/variables';
@import '../../../../../styles/mixins';

.views-group {
    display: inline-block;
    vertical-align: top;
    text-align: left;

    .selector {
        padding: 0;
        margin-left: -1px;
        vertical-align: top;

        .Select {
            min-width: 14rem;

            &-control {
                @include border-left-radius(0);
            }

            &-placeholder {
                font-size: $font-size-small;
                color: $color-default;
            }

            &-no-result {
                font-size: $font-size-small;
            }

            .create-link {
                color: $color-link;
                text-decoration: none;
                cursor: pointer;
                font-size: $font-size-small;

                &:hover {
                    color: $color-link-hover;
                }
            }
        }
    }
}
