@import '../styles/variables';

$zoom: 55%;

@media print {
    html {
        zoom: $zoom;
        position: relative;
    }

    body {
        height: auto !important;
        min-width: auto !important;

        .control-panel .font-icon-clear {
            display: none !important;
        }

        &.moz {
            .layout-easy {
                display: block !important;
            }

            .compare-shareholders-page {
                .layout-with-tabs-panel {
                    display: block !important;
                }
            }

            .ReactVirtualized__Table__row {
                border-top: $border !important;
            }

            .geographic-analysis {
                .ReactVirtualized__Grid__innerScrollContainer {
                    height: 100% !important;
                }

                .ReactVirtualized__Table__row {
                    position: static !important;
                    border-top: none !important;
                }
            }

            .layout-dataviz {
                .table-easy-with-footer {
                    div.virtualized-sticky-footer {
                        border-top-width: 2px !important;
                    }
                }
            }
        }

        &.ie {
            .registered-overview-page {
                .widget-content > div,
                .widget-content > div > div {
                    width: 100% !important;
                }
            }

            .widget-registered-shareholder-table {
                &[data-widget-type=registeredShareholder1099DIV] {
                    zoom: 90%;
                }
            }

            .compare-shareholders-page {
                .widget-registered-shareholder-table {
                    zoom: 90%;

                    &[data-widget-type=compareShareholdersAccountInfo] {
                        zoom: 75%;
                    }

                    &[data-widget-type=compareShareholders1099DIV] {
                        zoom: 65%;
                    }
                }
            }

            .geographic-analysis {
                .table-easy-with-footer {
                    .ReactVirtualized__Table__row,
                    .ReactVirtualized__Table__headerRow {
                        max-width: 120rem;
                    }
                }
            }

            .holder-investor-type-analysis {
                .rt-th:not(:first-child),
                .rt-td:not(:first-child) {
                    flex: 1 1 200px !important;
                }

                .rt-th:first-child {
                    display: block !important;

                    & + .rt-th {
                        padding-left: 8px !important;
                    }
                }
            }

            .layout-dataviz {
                .slot {
                    &.col-2 {
                        max-width: 65% !important;
                    }
                }

                .content-column {
                    flex-shrink: 1 !important; //IE 10 fix
                }

                .rt-thead,
                .rt-tbody,
                .rt-tr,
                .rt-tr-group {
                    min-width: auto !important;

                    .rt-td,
                    .rt-th {
                        flex-shrink: 1 !important;
                    }
                }
            }
        }

        &.edge,
        &.ie {
            .resize-triggers {
                display: none;
            }
        }

        &.moz,
        &.ie {
            .layout-dataviz {
                width: 100% !important;

                .slot {
                    &.col-1 {
                        max-width: 46% !important;
                    }
                }

                .performance-content-small {
                    .table-sticky-column,
                    .table-sticky-column > div,
                    .table-sticky-column > div > div,
                    .table-sticky-column > div > div > div:nth-child(2),
                    .ReactVirtualized__Grid,
                    .ReactVirtualized__Grid__innerScrollContainer {
                        height: auto !important;
                    }

                    .ReactVirtualized__Grid {
                        .cell {
                            position: relative !important;
                            top: 0 !important;
                            left: 0 !important;
                            display: inline-flex !important;
                            align-items: center;
                        }

                        &:first-child {
                            position: relative !important;
                        }

                        &:nth-child(2) {
                            top: 0 !important;

                            .cell {
                                justify-content: flex-end;

                                &.header-cell {
                                    justify-content: center;
                                }
                            }
                        }
                    }
                }
            }

            .holder-investor-type-analysis {
                .shareholder-type-option .font-icon {
                    padding-top: .3rem;
                }
            }
        }

        &.safari {
            .registeredShareholderInformation {
                .content {
                    .title {
                        font-size: 3rem !important;
                    }
                }

                .widget-content {
                    font-size: 2.2rem !important;

                    .message-note {
                        font-size: 2rem !important;
                    }
                }
            }

            .layout-dataviz {
                .slot.col-1 {
                    zoom: 100% + $zoom;
                    max-width: 46% !important;
                }
            }
        }

        &.chrome {
            .layout-dataviz {
                .analysis-legend {
                    height: auto !important;
                }

                .historical-analysis-chart {
                    height: 29.4rem !important;
                }
            }

            .widget-registered-shareholder-table {
                .ReactTable {
                    .rt-th {
                        padding: 0 20px;
                    }
                }
            }
        }

        &.ReactModal__Body--open {
            overflow: hidden;

            .modal-overlay {
                position: relative;
            }

            #app {
                display: none;
            }
        }
    }

    .app {
        display: block !important;
        padding-bottom: 0 !important;
    }

    .pie-content-container,
    .pie-content-container > div,
    .pie-content-container > div > div {
        width: 100% !important;
    }

    .highcharts-container {
        margin: 0 auto !important;
    }

    .actions-group {
        display: flex;

        .views-group {
            display: flex;
        }
    }

    .buttons-group {
        display: flex;
        justify-content: flex-end;

        .btn {
            display: none;

            &.active {
                display: inline-block;
                background-color: $_color-white;
                color: $_color-marron;
                border-color: $_color-pale-grey;
            }
        }
    }

    .shareholders-table .ReactTable {
        .rt-th {
            padding: 0 .8rem !important;

            &:first-child {
                display: flex !important;

                & + .rt-th {
                    width: 335px !important;
                }
            }

            &:last-child {
                margin-right: 0 !important;
            }
        }

        .rt-td {
            padding: 0 .8rem !important;
        }
    }

    .slot {
        &:not(:first-child),
        .range-container {
            page-break-inside: avoid;
        }
    }

    .ReactTable {
        overflow-x: visible !important;
    }

    .ReactVirtualized__Grid__innerScrollContainer,
    .ReactVirtualized__Table__row {
        position: relative !important;
    }

    .ReactVirtualized__Grid__innerScrollContainer {
        max-height: none !important;
    }

    .rt-tr,
    .ReactVirtualized__Table__row {
        page-break-inside: avoid;
        top: auto !important;
    }

    .app-header,
    .app-footer,
    .back-arrow,
    .view-contacts-link,
    .peer-selector,
    .reporting-nav,
    .nav-panel,
    .widget-control-panel,
    .widget-box-empty,
    .registered-overview-control-panel,
    .contacts-table-control-panel,
    .action-icons-container,
    .control-panel-actions,
    .control-panel-actions-container,
    .control-panel-note,
    .redux-toastr,
    .icon-remove {
        display: none !important;
    }

    @if ($env) {
        .equiniti-footer {
            display: none !important;
        }
    }

    .registeredShareholderTransactionHistory,
    .registeredShareholderClosedSaleType,
    .registeredShareholderDRDSPP,
    .registeredShareholderPayments,
    .registeredShareholderDocuments,
    .registeredShareholder1099DIV,
    .registeredShareholder1099INT,
    .registeredShareholder1099B,
    .registeredShareholder1099Misc,
    .registeredShareholder1042S,
    .registeredShareholderClosedOtherType,
    .registeredShareholderPriorTADRSHistory,
    .registeredShareholderCurrent {
        .first-slot {
            display: none !important;
        }

        .description,
        .right-aside {
            display: none;
        }
    }

    .registeredShareholderCurrent {
        .widget-content {
            .rt-table {
                margin-bottom: auto;
            }

            & > div:not(.expanded) {
                display: none !important;
            }
        }
    }

    .shareholders-table-rt,
    .performance-content-table-rt,
    .custom-react-table,
    .widget-contacts-table {
        .rt-tbody {
            max-height: none !important;
        }
    }

    .table-sticky-column {
        .ReactVirtualized__Grid {
            overflow: visible !important;
        }
    }

    .page-main-section,
    .reporting-page {
        display: block !important;

        .reporting-content {
            max-width: none;
        }
    }

    .widget {
        display: block !important;
        height: auto !important;
        overflow-y: visible !important;
    }

    .ellipsis {
        white-space: normal !important;
        word-wrap: break-word !important;
    }

    .colored-square {
        border-width: 7px !important;
        -webkit-print-color-adjust: exact !important;
        color-adjust: exact !important;
    }

    .legend-container .ReactVirtualized__Table__row {
        min-height: 2.4rem !important;
        height: auto !important;
    }

    .table-easy {
        .bar-chart {
            -webkit-print-color-adjust: exact !important;
            color-adjust: exact !important;

            &:before {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                z-index: -1;
                border-bottom: 10rem solid $color-bar-chart;
            }
        }

        & > div {
            width: 100% !important;

            & > div {
                height: auto !important;
            }

            .ReactVirtualized__Table__headerRow,
            .ReactVirtualized__Table__row {
                width: 100% !important;
            }

            .ReactVirtualized__Grid {
                height: auto !important;
                width: 100% !important;
                overflow: visible !important;

                .ReactVirtualized__Grid__innerScrollContainer {
                    max-width: 100% !important;
                    overflow: visible !important;
                }
            }
        }
    }

    .reporting-outstanding-shares-results-page {
        margin-top: 0 !important;
    }

    .reporting-content {
        max-width: 100% !important;
        padding: 0 1rem !important;
    }

    .geographic-analysis {
        .content-footer {
            margin-top: 0 !important;
        }

        .table-easy-with-footer {
            padding-bottom: 1rem !important;
        }
    }

    .table-easy-with-footer {
        margin-bottom: 0;

        .ReactVirtualized__Grid__innerScrollContainer {
            height: auto !important;
        }

        div.virtualized-sticky-footer {
            height: 2.4rem;
        }
    }

    .registered-overview-page {
        &,
        .layout-with-left-aside {
            display: block !important;

            .first-slot {
                width: 42rem;
            }
        }
    }

    .registeredShareholderInformation {
        .widget-content {
            font-size: 1.6rem !important;

            .message-note {
                font-size: 1.4rem !important;
            }
        }

        .second-slot {
            display: none !important;
        }
    }

    .contact-details-page {
        .widget-box,
        .container {
            display: block !important;
        }

        .column {
            float: right;

            &.main {
                float: left;
            }
        }
    }

    .layout-dataviz {
        display: block !important;

        .reporting-content {
            max-width: none !important;
        }

        .slot {
            min-height: 0 !important;
            margin: 2.4rem 0 0 !important;

            &.col-3 {
                width: 100% !important;
            }

            &:first-child {
                margin-top: 0 !important;
            }
        }

        .widget-box-empty-container {
            display: none !important;
        }

        .scrollable-react-table {
            .rt-th:last-child {
                margin-right: 0 !important;
            }

            .funds-table {
                .rt-tr {
                    padding-left: 0 !important;

                    .rt-td:first-child {
                        display: block !important;
                        flex: 35 1 auto !important;
                        width: 35px !important;
                        max-width: 35px !important;
                    }
                }
            }
        }

        .historical-analysis-content,
        .peer-analysis-content {
            .cell-with-percent {
                word-wrap: normal !important;
                overflow: visible !important;
            }
        }

        .widget-bad-addresses .legend-container .ReactVirtualized__Table__headerColumn.text-right {
            min-width: 220px !important;
        }
    }

    .institution-profile-page {
        padding-bottom: 0 !important;

        .single-slot {
            flex: 1 1 auto !important;

            .widget-box {
                width: 100% !important;
            }
        }

        .widget-content {
            .segment:last-child {
                margin-bottom: 0 !important;
            }
        }
    }

    .historical-analysis-content,
    .peer-analysis-content {
        min-width: 100% !important;
    }

    .registered-filter-panel {
        .form-error-message,
        .get-data-btn,
        .form-error.popover.bottom > .arrow {
            display: none !important;
        }
    }

    .holder-investor-type-analysis {
        .shareholder-type-option .font-icon {
            padding-top: .6rem;
        }
    }

    .reporting-control-books-page {
        .control-books-note {
            margin-bottom: 1.5rem !important;
        }

        form {
            display: none !important;
        }
    }

    .shareholder-contacts-modal {
        zoom: 100% + $zoom;

        h2,
        h3 {
            color: $color-default;
        }
    }
}

@page {
    margin: 35px 20px;
}
