@import '../variables';

.popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: $zindex-popover;
    display: none;
    font-size: $font-size-base;
    word-wrap: break-word;
    background-color: $popover-bg;
    background-clip: padding-box;
    border: $popover-border-width solid $popover-border-color;
    border-radius: $border-radius-lg;
    box-shadow: $shadow-default;

    .popover-content {
        padding: $popover-content-padding-y $popover-content-padding-x;
    }

    &.top {
        margin-top: -$popover-arrow-width;
    }

    &.right {
        margin-left: $popover-arrow-width;
    }

    &.bottom {
        margin-top: $popover-arrow-width;
    }

    &.left {
        margin-left: -$popover-arrow-width;
    }

    .arrow,
    .arrow:after {
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        border-color: transparent;
        border-style: solid;
    }

    .arrow {
        border-width: $popover-arrow-outer-width;

        &:after {
            border-width: $popover-arrow-width;
            content: '';
        }
    }

    &.top > .arrow {
        left: 50%;
        margin-left: -$popover-arrow-outer-width;
        border-bottom-width: 0;
        border-top-color: $popover-arrow-outer-color;
        bottom: -$popover-arrow-outer-width;

        &:after {
            content: ' ';
            bottom: 1px;
            margin-left: -$popover-arrow-width;
            border-bottom-width: 0;
            border-top-color: $popover-arrow-color;
        }
    }

    &.right > .arrow {
        top: 50%;
        left: -$popover-arrow-outer-width;
        margin-top: -$popover-arrow-outer-width;
        border-left-width: 0;
        border-right-color: $popover-arrow-outer-color;

        &:after {
            content: ' ';
            left: 1px;
            bottom: -$popover-arrow-width;
            border-left-width: 0;
            border-right-color: $popover-arrow-color;
        }
    }

    &.bottom > .arrow {
        left: 50%;
        margin-left: -$popover-arrow-outer-width;
        border-top-width: 0;
        border-bottom-color: $popover-arrow-outer-color;
        top: -$popover-arrow-outer-width;

        &:after {
            content: ' ';
            top: 1px;
            margin-left: -$popover-arrow-width;
            border-top-width: 0;
            border-bottom-color: $popover-arrow-color;
        }
    }

    &.left > .arrow {
        top: 50%;
        right: -$popover-arrow-outer-width;
        margin-top: -$popover-arrow-outer-width;
        border-right-width: 0;
        border-left-color: $popover-arrow-outer-color;

        &:after {
            content: ' ';
            right: 1px;
            border-right-width: 0;
            border-left-color: $popover-arrow-color;
            bottom: -$popover-arrow-width;
        }
    }
}
