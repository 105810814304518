$env: false;
@import '../../../../../../styles/colors';
@import '../../../../../../styles/variables';

.started-dataviz-report {
    display: flex;
    position: relative;
    justify-content: space-between;
    padding: 2.4rem;

    .started-dataviz-link {
        display: flex;
        align-items: center;
        height: 2.4rem;
        font-size: 12pt;
        font-weight: bold;
        outline: none;

        &.active {
            color: $_color-black;
            cursor: default;
            background-color: transparent;

            &:before {
                content: '';
                position: absolute;
                background-color: $color-link;
                top: 1.6rem;
                bottom: 1.6rem;
                left: 0;
                width: 3px;
            }
        }
    }
}
