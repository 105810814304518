$env: false;
@import '../../../styles/variables';

.authorized-users-widget {
    .widget-header-container {
        align-items: center;

        .securities-container {
            display: flex;
            align-items: center;
            width: 50rem;
            margin-right: auto;

            & > span {
                font-weight: $font-weight-bold;
                margin-right: 1rem;
            }

            .securities-selector {
                min-width: 45rem;
            }

            .securities-container__checkbox {
                margin-left: 1rem;
            }
        }
    }

    .ReactTable {
        .rt {
            &-thead {
                height: auto;
            }

            &-th {
                border: 0;
                padding-bottom: 1rem;
                padding-top: 1rem;
                padding-right: 2.2rem;
                justify-content: center;
            }

            &-td {
                border-right: 0;
                border-top: .1rem solid $color-border;
            }

            &-tr-group .rt-tr:hover .rt-td {
                border-right: 0;
            }

            &-tfoot {
                font-weight: $font-weight-regular;
            }
        }
    }
}
