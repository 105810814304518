/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
.notes .widget-content {
  padding-top: 0;
}
.notes .widget-content-header {
  display: flex;
  align-items: center;
  padding-right: 5rem;
}
.notes .widget-content-header .total-records {
  margin-left: auto;
  color: #6b6056;
  font-size: 1.2rem;
}
.notes .notes-header {
  line-height: 1.2;
  color: #000;
  margin: 0;
}
.notes .ReactTable .rt-thead .rt-th:not(:first-child) {
  justify-content: center;
}
.notes .ReactTable .icon-cell {
  justify-content: center;
}
.notes .td-description {
  justify-content: flex-start;
}

.modal.delete-note-modal {
  min-width: 31rem;
}

.contact-details-page .notes,
.institution-profile-page .notes {
  flex: 1;
  min-width: 87rem;
  padding: 2.4rem 3.6rem;
}
.contact-details-page .notes .widget-header-container,
.institution-profile-page .notes .widget-header-container {
  margin-bottom: 1rem;
}

.institution-profile-page .notes {
  padding-left: 1rem;
}