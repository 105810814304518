$env: false;
@import '../../../../../styles/colors';
@import '../../../../../styles/variables';

.classic-as-of-date-report {
    display: flex;
    padding: 1.4rem 2.4rem;

    .classic-pdf-link {
        color: $color-link;
        cursor: pointer;

        &:hover {
            color: $color-link-hover;
        }
    }

    .action-icon {
        margin-right: .8rem;
        font-size: $font-size-x-large;
    }
}
