$env: false;
@import '../../../../styles/variables';

.analysis-legend {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 31rem;
    padding-right: 1rem;
    overflow-y: auto;

    .item {
        display: flex;
        align-items: center;
        height: 3.1rem;
        width: 100%;
        position: relative;

        .label {
            margin-left: .5rem;
            font-size: 1.2rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            flex-shrink: 1;
            display: block;
        }

        .font-icon {
            margin-left: auto;
            font-size: 2.2rem;
            cursor: pointer;
        }
    }

    .no-data-text {
        font-size: $font-size-small;
        width: auto;
        font-style: italic;
        line-height: 2rem;
    }
}
