/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
.watchlist-table.ReactTable .rt-th {
  white-space: pre-wrap;
  padding: 0 2.2rem;
}
.watchlist-table.ReactTable .rt-th.watchlist-table__delete-column {
  padding: 0;
}
.watchlist-table.ReactTable .rt-th:first-child {
  display: none;
}
.watchlist-table.ReactTable .rt-th:first-child + .rt-th {
  width: 380px !important;
  max-width: 38rem;
  padding-left: 5.3rem;
}
.watchlist-table.ReactTable .rt-tr .rt-td:nth-child(2) {
  max-width: 33.5rem;
}
.watchlist-table.ReactTable .rt-td:first-child {
  border-right: 0;
  justify-content: center;
}
.watchlist-table.ReactTable .empty {
  width: 2.4rem;
  height: 2.4rem;
}
.watchlist-table.ReactTable .cell-with-align-right,
.watchlist-table.ReactTable .cell-with-change,
.watchlist-table.ReactTable .cell-with-currency-usd,
.watchlist-table.ReactTable .cell-with-percent,
.watchlist-table.ReactTable .cell-with-position,
.watchlist-table.ReactTable .cell-with-mkt-val,
.watchlist-table.ReactTable .cell-with-date,
.watchlist-table.ReactTable .cell-with-number {
  width: 100%;
  text-align: right;
}
.watchlist-table.ReactTable .heading-cell-with-filtration .trigger-wrapper {
  display: inline;
  position: absolute;
  bottom: 1.8rem;
  padding: 0 0.5rem;
  vertical-align: sub;
}
.watchlist-table.ReactTable .heading-cell-with-date .position-date {
  font-size: 1rem;
  font-weight: 400;
}
.watchlist-table.ReactTable .heading-cell,
.watchlist-table.ReactTable .heading-cell-with-date {
  width: 100%;
  text-align: center;
}
.watchlist-table.ReactTable .action-panel {
  justify-content: space-between;
  display: flex;
}
.watchlist-table.ReactTable .action-panel > .actions {
  flex: 0 0 auto;
  margin: 0 0 0 auto;
  padding-left: 1rem;
}
.watchlist-table.ReactTable .action-panel .additional-info {
  line-height: 3.6rem;
}
.watchlist-table.ReactTable .action-panel .additional-info .show-cede-and-co {
  padding: 0 2rem 0 0;
  font-weight: 400;
}
.watchlist-table.ReactTable .action-panel .additional-info .message-note {
  line-height: 3.6rem;
}
.watchlist-table.ReactTable .action-panel .additional-info .message-note:not(:last-child) {
  padding-right: 2rem;
}
.watchlist-table.ReactTable .action-panel .registered-table-note {
  display: flex;
  line-height: 2.8rem;
  overflow: hidden;
  align-items: flex-start;
}
.watchlist-table.ReactTable .action-panel .registered-table-note .message-note {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 0.9rem 0 0;
  padding: 0;
  line-height: 2.8rem;
}
.watchlist-table.ReactTable .action-panel .registered-table-note .font-icon {
  font-size: 2.4rem;
  vertical-align: text-top;
}
.watchlist-table.ReactTable .rt-tbody .font-icon {
  cursor: auto;
}
.watchlist-table.ReactTable .rt-tbody .font-icon.font-icon-delete {
  cursor: pointer;
}
.watchlist-table.ReactTable .rt-tbody .font-icon.font-icon-delete.inactive {
  cursor: not-allowed;
}
.watchlist-table.ReactTable .rt-tbody .font-icon-collapse,
.watchlist-table.ReactTable .rt-tbody .font-icon-expand {
  cursor: pointer;
}
.watchlist-table.ReactTable .rt-tbody .cell-with-link {
  flex-shrink: 1;
  display: block;
}
.watchlist-table.ReactTable .position-value:not(.active) {
  cursor: default;
}
.watchlist-table.ReactTable-popover-tip-content {
  width: 28rem;
  font-size: 1.2rem;
}
.watchlist-table.ReactTable .-inaccessible {
  color: #7f7369;
}
.watchlist-table.ReactTable .-inaccessible :not(.watchlist-table__delete-cell) .font-icon {
  color: #7f7369;
}

.watchlist-top-accounts-tooltip {
  width: 15rem;
}