/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
.ReactTable .rt-table .no-dividend-content {
  display: none;
}
.ReactTable .rt-td.no-dividend-container .no-dividend-content,
.ReactTable .rt-th.no-dividend-container .no-dividend-content {
  display: flex;
  position: absolute;
  top: 0;
  right: 1px;
  background-color: white;
  font-style: italic;
  color: #7f7f7f;
  text-align: left;
  font-weight: normal;
  padding: 0 0.8rem;
  line-height: 4rem;
}
.ReactTable .rt-tr {
  position: relative;
}
.ReactTable .rt-th.no-dividend-container {
  position: static;
  line-height: 4rem;
}
.ReactTable .rt-th.no-dividend-container .no-dividend-content {
  line-height: 6.4rem;
  background-color: #f4f1ed;
}
.ReactTable .rt-tr:hover .no-dividend-container .no-dividend-content {
  background-color: #f4f1ed;
  cursor: default;
}

.registered-shareholder-client-uploads .drag-and-drop-overlay {
  display: block;
  z-index: 1090;
  background-color: #fff;
  opacity: 0.65;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.registered-shareholder-client-uploads .widget-content.drag-active .ReactTable {
  border: 0.2rem dashed #941f40;
  z-index: 1115;
  position: relative;
}