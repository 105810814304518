$env: false;
@import '../../styles/variables';

.downloads-panel {
    flex: 0 0 32rem;
    max-width: 32rem;
    background-color: $color-aside-bg;

    .header {
        position: relative;
        padding: 2.4rem;
        font-size: $font-size-large;
        font-weight: bold;
        border-bottom: 1px solid $color-reporting-nav-separator;

        &:before {
            content: '';
            position: absolute;
            background-color: $color-link;
            top: 1.6rem;
            bottom: 1.6rem;
            left: 0;
            width: 3px;
        }
    }
}
