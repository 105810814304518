$env: false;
@import '../../../styles/variables';

.custom-react-table.ReactTable {
    .rt-tr-group {
        .rt-tr:hover {
            .rt-td:first-child {
                border-right: $border;
            }
        }
    }

    .rt-th {
        &:not(:first-child) {
            justify-content: center;
        }

        &.-cursor-pointer {
            padding-right: 2.8rem;
        }
    }
}
