$env: false;
@import '../../../styles/variables';
@import '../../../styles/colors';

.user-profile-page {
    display: flex;
    align-items: flex-start;
    padding: 0 1.2rem;

    .user-profile-container {
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, .25);
        margin: 2.4rem 1.2rem;
        padding: 1.8rem 2.4rem;

        h2 {
            margin: {
                top: 0;
                bottom: 0;
            };
        }
    }

    .column {
        width: 50%;
    }

    .font-icon {
        &-edit,
        &-download,
        &-delete {
            font-size: $icon-large-size;
            cursor: pointer;
        }

        &-edit {
            padding-left: 1.5rem;
        }
    }

    .info {
        font-style: italic;
        color: $color-text-info;
    }

    .user-alerts {
        &-header {
            display: flex;
            align-items: center;

            .info {
                padding-left: 2.4rem;
            }
        }

        .alerts-item {
            font-weight: normal;
            color: $color-default;
        }
    }
}
