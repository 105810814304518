$env: false;
@import '../../../styles/variables';

.widget-historical-analysis-table {
    .analysis-table {
        &.ReactTable {
            overflow-x: visible;
        }
    }

    .widget {
        &-content {
            padding-top: 1rem;

            .historical-analysis-content {
                min-width: 120rem;
            }
        }

        &-header {
            display: flex;
            align-items: center;

            &-container {
                padding-bottom: 2.4rem;
                border-bottom: $border;
            }
        }

        &-control-panel {
            display: flex;
            align-items: center;
        }

        &-title {
            font-size: $font-size-x-large;
        }
    }

    .historical-analysis-chart {
        position: relative;
        height: 31.8rem;

        &-legend {
            position: absolute;
            top: 0;
            left: 0;
            z-index: $zindex-chart-legend;
            max-width: 40rem;
        }

        .colored-square {
            background-color: $color-square;
            border: 0 solid $color-square;
        }

        .legend-label {
            margin-left: .5rem;
            font-size: 1.2rem;
            font-weight: normal;
        }
    }

    .additional-component {
        display: flex;
        align-items: center;

        .label {
            margin-left: .5rem;
            font-size: 1.2rem;
            font-weight: normal;
        }
    }
}
