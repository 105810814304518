$env: false;
@import 'src/styles/variables';

.user-secure-upload-details-page {
    padding: 2.4rem;

    .user-secure-upload-details__term {
        font-weight: bold;
        margin-bottom: .5rem;
    }

    .user-secure-upload-details__description {
        margin-left: 0;
        margin-bottom: 1rem;
    }

    .cell-with-status {
        display: flex;
        align-items: center;

        & > span {
            margin-right: $padding-small-vertical;
        }

        .font-icon {
            display: block;
        }
    }
}

.subject-line-button-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.subject-line {
    width: 50%;
}
