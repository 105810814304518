$env: false;
@import '../../styles/colors';
@import '../../styles/variables';

.form-warning {
    .popover {
        background-color: $color-warning-background;

        &.bottom > .arrow {
            border-bottom-color: $color-warning-background;

            &:after {
                border-bottom-color: $color-warning-background;
            }
        }
    }
}
