$env: false;
@import '../../../styles/variables';
@import '../../../styles/colors';
@import '../../../styles/mixins';

.widget-tax-certification-status {
    .pie-content-container {
        position: relative;
        padding: .5rem 0;
        height: 16rem;
    }

    .legend-container {
        padding: 0;
    }

    .table-container {
        padding: 1rem 0 0;
    }

    .tax-certification-status-action-panel {
        display: flex;
        justify-content: space-between;

        .period-action-panel {
            flex-wrap: wrap;
        }

        .buttons-group {
            padding: 1rem 0 0 .5rem;
            min-width: 14.1rem;

            .btn {
                padding: .8rem 1rem;
            }
        }

        .level-selector.selector {
            padding: 1rem 0 0;

            .Select {
                width: 11rem;
                min-width: 11rem;
            }

            .Select-control {
                height: 4rem;

                @include border-rounded($border-dark);

                .Select-value,
                .Select-placeholder {
                    line-height: 3.8rem;
                }
            }
        }
    }
}
