@import '../colors';
@import '../variables';

.nav {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    list-style: none;
    line-height: normal;

    &-item {
        a {
            position: relative;
            width: 100%;
            padding: 1.4rem 2.4rem;
            display: inline-block;
            line-height: 1.9rem;
            outline: none;

            &:before {
                position: absolute;
                border-left: 3px solid $color-link;
                top: 0;
                bottom: 0;
                left: 0;
            }

            &.active {
                //text-shadow: $link-active-text-shadow; // alternative of font-weight
                color: $color-link-hover;
                cursor: default;
                background-color: transparent;
                pointer-events: none;

                &:before {
                    content: '';
                }
            }
        }

        &-bold {
            font-weight: $font-weight-bold;
        }

        &-middle {
            margin: 0 auto;
        }
    }

    &-item.nav-item-middle {
        a.active {
            text-shadow: none;
            cursor: default;
            background-color: transparent;
            pointer-events: none;

            &:before {
                content: none;
            }
        }
    }

    &.horizontal {
        flex-direction: row;
        border-bottom: 1px solid $color-nav-border;

        .nav-item {
            &.push {
                margin-left: auto;
            }

            a {
                text-align: center;
                outline: none;

                &:before {
                    top: auto;
                    bottom: -1px;
                    right: 0;
                    border-bottom: 3px solid $color-link;
                    width: auto;
                }

                &.active {
                    border-left: 0;
                }
            }
        }
    }

    &.secondary {
        font-size: $nav-link-text-size;

        .nav-item a {
            padding: 1.1rem 2.4rem 1.2rem;
            line-height: 1.7rem;
            outline: none;
        }
    }
}
