.create-note-modal {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}
.create-note-modal .modal-textarea {
  width: 40rem;
  resize: none;
}
.create-note-modal .note-length-counter {
  align-self: flex-end;
  margin: 0.5rem 0;
}
.create-note-modal .modal-actions:before {
  display: none;
}