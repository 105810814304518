$env: false;
@import '../../../../../styles/colors';
@import '../../../../../styles/mixins';

.date-last-contact-action-panel {
    display: flex;
    justify-content: space-between;

    .period-years-selector {
        padding: 1rem 0 0;

        .Select {
            min-width: 10.5rem;
        }

        .Select-control {
            height: 4rem;

            @include border-rounded($border-dark);

            .Select-value,
            .Select-placeholder {
                line-height: 3.8rem;
            }
        }
    }
}
