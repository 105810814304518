$env: false;
@import '../../../styles/variables';

.app-version {
    font-size: $font-size-small;

    .layer {
        font-style: italic;
    }

    .version {
        font-weight: $font-weight-bold;
    }
}
