$env: false;
@import '../../styles/colors';
@import '../../styles/variables';

.multiple-security-selector {
    position: relative;

    .list-container {
        display: flex;
        height: 36rem;
    }

    .security-filter {
        position: absolute;
        bottom: 100%;
        right: 0;
        display: flex;
        align-items: center;
        padding-bottom: .6rem;

        .font-icon-search {
            position: absolute;
            right: .5rem;
            top: .8rem;
            font-size: $font-size-x-large;

            &.disabled {
                cursor: inherit;
                color: $color-text-muted;
            }
        }

        &-input input {
            width: 23rem;
            padding-right: 3rem;
        }
    }

    .issuers-list {
        width: 41rem;
        margin-right: 1.7rem;
    }

    .securities-list {
        width: 65rem;
    }

    .error-container {
        width: 46rem;
        position: relative;
    }

    .check-boxes-list {
        border: 1px solid $_color-dark-gray;
        color: $color-text-label;

        &-item {
            border-top: $border;
            padding: 0 0 0 .8rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &:not(.title):hover,
            &.checked {
                background-color: $color-item-highlight;
            }

            &.select-all {
                border: 0;
            }

            &.title {
                line-height: 3.6rem;
                padding-left: 2.8rem;
                font-weight: 700;
                color: $color-default;
            }

            .label {
                padding: .5rem;
            }
        }
    }
}
