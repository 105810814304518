.registered-filter-panel {
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
}
.registered-filter-panel-wrapper {
  display: flex;
  flex-direction: column;
}
.registered-filter-panel .right-aside {
  font-size: 1.2rem;
  display: flex;
  align-items: center;
}
.registered-filter-panel .date-range-wrapper {
  display: flex;
}
.registered-filter-panel .input-date-range-picker .date-picker-component {
  margin-right: 1rem;
}