$env: false;
@import '../../../../../../styles/colors';

.registered-pagination {
    margin-bottom: 1rem;
    flex-direction: row-reverse;
    display: flex;

    .right-aside {
        font-size: 1.2rem;
        color: $color-text-info;
        display: flex;
        align-items: center;
    }
}
