$env: false;
@import '../../../../../../styles/colors';
@import '../../../../../../styles/variables';

.analysis-turnover-legend-modal-view {
    max-width: 57rem;
    color: $color-text-info;

    .table {
        width: 30rem;
        font-size: $font-size-base;
        margin: 1.5rem 0;

        .row {
            display: flex;
            align-items: center;
            height: 2.9rem;

            &:not(:last-child) {
                border-bottom: $border;
            }

            .label {
                width: 50%;
            }
        }
    }

    .description-block {
        font-size: $font-size-small;
        white-space: pre-wrap;
    }
}
