$env: false;
@import '../../../../styles/variables';
@import '../../../../styles/colors';

.export-registered-overview-modal {
    width: 41rem;

    .format-container {
        margin: 2.4rem 0;

        .amount-of-records-list {
            display: flex;
            flex-direction: column;
        }

        .form-section-title {
            font-weight: $font-weight-bold;
            margin: 2.5rem 0 1rem;
        }

        .radio-component {
            padding: .5rem 0;
        }

        .radio-label,
        .checkbox-label {
            font-weight: $font-weight-regular;
            color: $color-default;
        }
    }
}
