$env: false;
@import '../../../../../styles/variables';

.save-custom-view {
    padding: 2rem .4rem .4rem;

    .text-input-label {
        font-size: $font-size-small;
    }

    .btn-panel {
        border-top: $border;
        margin-top: 3rem;

        .btn {
            margin: 2.4rem 1rem 0;
            font-size: 1.6rem;
            font-weight: 600;

            &:first-child {
                margin-left: 0;
            }
        }
    }
}
