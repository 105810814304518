$env: false;
@import '../../../styles/colors';
@import '../../../styles/variables';

.holder-investor-type-analysis {
    .widget-header-container {
        display: block;
        margin-bottom: 3rem;
    }

    .holder-type-analysis-control-panel {
        display: flex;
        align-items: center;

        .selector {
            margin-right: 1.4rem;
            min-width: 23.8rem;

            .is-disabled .Select-control {
                opacity: 1;
            }
        }

        .legend-item {
            display: flex;
            align-items: center;
            margin-right: 2.4rem;

            &-icon {
                font-size: $font-size-large;
                color: $color-text-label;
                margin-right: 1.2rem;
            }

            &-text {
                font-size: $font-size-small;
            }
        }

        .action-icons-container {
            display: flex;
            margin-left: auto;

            .action-icon {
                font-size: $font-size-x-large;

                &:not(.inactive) {
                    cursor: pointer;
                }
            }
        }
    }

    .holder-type-analysis-content {
        .shareholder-type-icon {
            font-size: $font-size-large;
            color: $color-text-label;
            cursor: default;
        }

        .message-note {
            padding: 0 0 1.2rem;
        }
    }

    .ReactTable {
        overflow-x: visible;

        .rt-thead {
            height: 4rem;
        }

        .rt-th:first-child {
            display: none;

            & + .rt-th {
                padding-left: 48px; // zoom fix ASTTSTRT-19271
                justify-content: flex-start;
            }
        }

        .rt-td:first-child,
        .rt-th:first-child {
            border-right: 0;
            padding: 0;
            align-items: center;
            justify-content: center;
        }

        .rt-th:not(:first-child) {
            justify-content: center;
        }

        .cell-with-description {
            width: 100%;

            .text-ellipsis {
                width: auto;
                max-width: 99%;
            }
        }
    }

    .shareholder-type-option {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .font-icon {
            font-size: $font-size-large;
        }
    }
}
