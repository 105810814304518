@import '../colors';
@import '../variables';

.badge {
    display: inline-block;
    padding: .3rem .5rem;
    font-size: $font-size-x-small;
    font-weight: $font-weight-bold;
    line-height: 1;
    color: $color-text-inverse;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    background-color: $color-notification-badge-bg;
    border-radius: 50%;

    &:empty {
        display: none;
    }
}
