$env: false;
@import '../../styles/colors';
@import '../../styles/variables';

.equiniti-footer {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    padding: .7rem 2rem;
    color: $color-text-inverse;
    height: $app-footer-height;
    line-height: 3rem;
    background: $color-footer-background;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .footer-links {
        display: inline-flex;
        gap: 40px;
    }

    .footer-link {
        color: $color-text-inverse;
        cursor: pointer;

        &:hover {
            color: inherit;
            text-decoration: underline;
        }
    }

    .footer-copyright {
        color: #a1a8b4;
    }
}
