/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
.started-dataviz-report {
  display: flex;
  position: relative;
  justify-content: space-between;
  padding: 2.4rem;
}
.started-dataviz-report .started-dataviz-link {
  display: flex;
  align-items: center;
  height: 2.4rem;
  font-size: 12pt;
  font-weight: bold;
  outline: none;
}
.started-dataviz-report .started-dataviz-link.active {
  color: #000;
  cursor: default;
  background-color: transparent;
}
.started-dataviz-report .started-dataviz-link.active:before {
  content: "";
  position: absolute;
  background-color: #941f40;
  top: 1.6rem;
  bottom: 1.6rem;
  left: 0;
  width: 3px;
}