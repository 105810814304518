/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
.errors-details {
  display: flex;
  flex-flow: column nowrap;
}
.errors-details .error-item:not(:first-child) {
  padding-top: 5rem;
  border-top: 3px dashed #cbc4be;
}
.errors-details .error-item:not(:last-child) {
  padding-bottom: 5rem;
}
.errors-details .error-item .error-item-header {
  display: flex;
  text-align: left;
  flex-flow: column nowrap;
}
.errors-details .error-item .error-item-header .header-info {
  margin-right: 1rem;
}
.errors-details .error-item .error-item-header .header-info-title {
  font-weight: 700;
}
.errors-details .error-item-content {
  width: 100%;
  min-height: 30rem;
  text-align: left;
  border: 1px solid #cbc4be;
  border-radius: 0.3rem;
  white-space: pre-wrap;
  padding: 2rem;
  word-wrap: break-word;
}