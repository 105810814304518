$env: false;
@import '../../../../styles/variables';
@import '../../../../styles/colors';

.widget-menu {
    cursor: pointer;
    flex-shrink: 1; // IE 10 fix

    .ast-popover {
        display: block;
        position: relative;
    }
}

.widget-list {
    font-size: $font-size-small;

    .widget-item {
        position: relative;
        padding: 1rem 1rem 1rem 0;
        cursor: pointer;
        color: $color-link;
        white-space: nowrap;

        .font-icon {
            position: absolute;
            font-size: $font-size-x-large;
        }

        &.disabled {
            cursor: default;
            color: $color-text-muted;

            .font-icon {
                color: $color-text-muted;
            }
        }

        &:not(:last-child) {
            border-bottom: $border;
        }

        &:hover:not(.disabled) {
            color: $color-link-hover;
        }

        .widget-name {
            margin-left: 2.6rem;
            vertical-align: middle;
        }
    }
}
