$env: false;
@import '../../../styles/variables';

.ownership-page {
    .page-content {
        padding: 2.4rem;
    }

    .shareholders-table {
        .ReactTable {
            overflow-x: visible;
        }
    }

    .ownership-nav {
        display: flex;
        border-bottom: 1px solid $color-nav-border;

        .nav.horizontal {
            border-bottom: 0;
        }

        .treasury-accounts-link {
            padding: 1.1rem 2.4rem;
            margin-left: auto;
        }
    }
}
