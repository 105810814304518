/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
.reporting-share-range-analysis-results-page .widget-header {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
}
.reporting-share-range-analysis-results-page .widget-header > * {
  margin-right: 2.7rem;
}
.reporting-share-range-analysis-results-page .cell-with-number {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.reporting-share-range-analysis-results-page .cell-with-share-range {
  display: flex;
  width: 100%;
}
.reporting-share-range-analysis-results-page .cell-with-share-range .share-range {
  width: 50%;
  max-width: 10rem;
  padding-right: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.reporting-share-range-analysis-results-page .reporting-share-range-analysis-results-table {
  margin-top: 3.2rem;
}
.reporting-share-range-analysis-results-page .reporting-share-range-analysis-results-table .ReactTable {
  overflow-x: visible;
}
.reporting-share-range-analysis-results-page .reporting-share-range-analysis-results-table .rt-thead {
  height: 4rem;
}
.reporting-share-range-analysis-results-page .reporting-share-range-analysis-results-table .rt-th:not(:first-child) {
  justify-content: center;
  text-align: center;
}
.reporting-share-range-analysis-results-page .reporting-share-range-analysis-results-table .rt-tfoot .rt-td:not(:first-of-type) {
  justify-content: flex-end;
}
.reporting-share-range-analysis-results-page .message-note {
  font-weight: 400;
}