$env: false;
@import '../../../../styles/variables';
@import '../../../../styles/colors';

.reporting-downloads-page {
    .reporting-header {
        line-height: 4.8rem;
    }

    .reporting-section {
        .ReactVirtualized__Table__headerColumn.ReactVirtualized__Table__sortableHeaderColumn {
            justify-content: center;
        }
    }

    .message-note {
        margin: 0 0 .5rem;
    }

    .report-description:not(.inactive) {
        cursor: pointer;
    }

    .font-icon-download,
    .font-icon-delete {
        font-size: $font-size-x-large;

        &:not(.inactive) {
            cursor: pointer;
        }
    }
}
