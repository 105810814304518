$env: false;
@import 'src/styles/variables';

.secure-upload-document-name {
    color: $color-link;
    cursor: pointer;
    font-size: $font-size-small;

    &:hover {
        color: $color-link-hover;
    }
}
