@import '../variables';

.positive {
    color: $color-positive;
}

.negative {
    color: $color-negative;
}

.date {
    color: $color-text-muted;
    font-size: $font-size-small;
    line-height: 2;
}

.uppercase {
    text-transform: uppercase;
}

.text-muted {
    color: $color-text-muted;
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.back-arrow {
    &:before {
        content: '<';
        padding-right: .6rem;
    }
}

.ellipsis {
    display: block !important;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    flex-shrink: 1; // IE 10 fix
}

.visually-hidden {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
}

.info-message {
    color: $color-text-info;
    font-size: $font-size-small;
}

// DISCLAIMER/HELP/FOOTNOTE TEXT
// See StyleGuide:  https://app.zeplin.io/project/58ac54ff751f43368056490f/screen/58b85c42907e92759e37cbb1
.message-note {
    font-size: $font-size-small;
    font-style: italic;
    color: $color-text;
    line-height: 1.7rem;

    &:not(:last-child) {
        padding-bottom: .5rem;
    }
}
