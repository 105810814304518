$env: false;
@import '../../../styles/variables';
@import '../../../styles/colors';

.search-filter {
    .filter-header {
        display: flex;
        align-items: baseline;

        h3 {
            margin-top: 0;
        }

        .expand-all-tool {
            margin-left: 1.8rem;
            color: $color-link;
            cursor: pointer;
        }
    }

    .criterion {
        display: block;
        padding: .8rem 0;

        &-total {
            color: $color-text;
        }
    }

    .expanded-filter {
        display: flex;
        align-items: center;

        .font-icon-arrow-drop-down,
        .font-icon-arrow-up {
            margin: 0 0 0 1rem;
            cursor: pointer;
        }
    }

    .second-layer {
        margin-left: 1.8rem;
    }

    .checkbox-label {
        display: inline;
        font-weight: $font-weight-regular;
        color: $color-link;
    }

    .no-criterion {
        padding: 1rem 0;
        color: $color-link;
    }

    .selector {
        padding: 1rem 0;
        width: 20rem;
    }

    .panel-collapse {
        background-color: $color-collapse-panel-bg;
        margin: 0 -2.4rem;
        padding-left: 2.4rem;
    }

    .collapse {
        display: none;

        &.in {
            display: block;
        }
    }

    .collapsing {
        position: relative;
        height: 0;
        overflow: hidden;
        transition-property: height, visibility;
        transition-duration: $animation-duration;
        transition-timing-function: ease;
    }
}

.search-result-recent-search {
    margin-top: 2rem;

    .recent-search__title {
        font-size: 1.8rem;
        font-weight: 700;
    }

    .recent-search__header {
        .font-icon {
            margin-right: 0;
        }
    }

    .recent-search-list {
        padding: 0 2.4rem 0 1.7rem;
        margin: 0 -2.4rem;
        background-color: $color-collapse-panel-bg;
    }
}
