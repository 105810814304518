$env: false;
@import '../../styles/mixins';

.month-selector {
    &.selector {
        width: 16rem;
        padding: 0;

        .Select-control {
            height: 4rem;

            @include border-rounded($border-dark);

            .Select-value,
            .Select-placeholder {
                line-height: 3.8rem;
            }
        }
    }
}
