$env: false;
@import '../../../styles/colors';
@import '../../../styles/variables';

.layout-dataviz {
    .widget {
        border: 3px dashed $color-border;
        padding: $layout-dataviz-widget-top-padding $layout-dataviz-widget-padding $layout-dataviz-widget-padding; // dimensions minus the size of the border
    }

    .col-3 {
        min-width: initial;
    }
}
