$env: false;
@import '../../../styles/variables';
@import '../../../styles/colors';

.save-new-scheduled-report-modal {
    width: 38rem;

    .input-container {
        margin: 2.4rem 0;

        .text-input-label {
            font-size: $font-size-small;
        }

        .text-input,
        input {
            width: 100%;

            &::placeholder {
                font-style: italic;
                color: $color-text-placeholder;
            }
        }
    }

    .text-message {
        margin: 2.5rem 0;
        font-style: italic;
        color: $color-text-label;
    }

    .btn-panel {
        border-top: $border;
        padding-top: 2.5rem;

        .btn {
            margin: 0 2rem 0 0;
        }
    }
}
