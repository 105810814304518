$env: false;
@import '../../../../styles/variables';

.widget-analysis-geographic {
    .table-container {
        padding: 1rem 0;
    }

    .full-report-link {
        padding-right: .2rem;
    }
}
