.textarea-component {
  display: inline-block;
  padding: 0.5rem;
}
.textarea-component .textarea-label.vertical {
  display: block;
  margin-bottom: 0.5rem;
}
.textarea-component .textarea-label.horizontal {
  display: inline-block;
  vertical-align: top;
  margin-right: 0.5rem;
  margin-top: 0.5rem;
}