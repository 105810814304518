@import '../variables';

.sign {
    &-asterisk {
        &-before:before,
        &-after:after {
            content: '*';
            position: relative;
        }

        &-before:before {
            left: 0;
        }

        &-after:after {
            right: 0;
        }
    }

    &-dagger {
        &-before:before,
        &-after:after {
            content: '†';
            position: relative;
            top: -.6rem;
            font-size: $font-size-x-small;
        }

        &-before:before {
            left: 0;
        }

        &-after:after {
            right: 0;
        }
    }

    &-double-dagger {
        &-before:before,
        &-after:after {
            content: '‡';
            position: relative;
            top: -.6rem;
            font-size: $font-size-x-small;
        }

        &-before:before {
            left: 0;
        }

        &-after:after {
            right: 0;
        }
    }

    &-double-vertical-line {
        &-before:before,
        &-after:after {
            content: '‖';
            position: relative;
            top: -.6rem;
            font-size: $font-size-x-small;
        }

        &-before:before {
            left: 0;
        }

        &-after:after {
            right: 0;
        }
    }

    &-section {
        &-before:before,
        &-after:after {
            content: '§';
            position: relative;
            top: -.6rem;
            font-size: $font-size-x-small;
        }

        &-before:before {
            left: 0;
        }

        &-after:after {
            right: 0;
        }
    }

    &-pilcrow {
        &-before:before,
        &-after:after {
            content: '¶';
            position: relative;
            top: -.6rem;
            font-size: $font-size-x-small;
        }

        &-before:before {
            left: 0;
        }

        &-after:after {
            right: 0;
        }
    }

    &-double-asterisk {
        &-before:before,
        &-after:after {
            content: '**';
            position: relative;
        }

        &-before:before {
            left: 0;
        }

        &-after:after {
            right: 0;
        }
    }
}
