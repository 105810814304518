$env: false;
.text-ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    // need for text ellipsis
    display: inline-block !important;
    vertical-align: middle; // inline-block shifts to up
    width: 100%;
}
