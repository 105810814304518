/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
.common-columns-manager-view {
  display: flex;
  flex-direction: column;
  max-height: 42rem;
}
.common-columns-manager-view .checkbox-list {
  margin: 1.4rem 0;
  overflow: hidden;
}
.common-columns-manager-view .checkbox-list .checkbox-component {
  padding-right: 2.5rem;
  min-width: 20.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.common-columns-manager-view .checkbox-list .checkbox-label {
  font-weight: normal;
  color: #000;
}
.common-columns-manager-view .validate-block {
  position: relative;
  min-height: 5.8rem;
}
.common-columns-manager-view .validate-block:empty {
  min-height: 2.4rem;
}
.common-columns-manager-view .validate-block .form-error-summary {
  display: inline-block;
}
.common-columns-manager-view .validate-block .form-error-summary .arrow {
  left: 4rem;
}
.common-columns-manager-view .btn-panel {
  border-top: 1px solid #e9e3dc;
}
.common-columns-manager-view .btn-panel .btn {
  margin-right: 2rem;
  font-size: 1.6rem;
  font-weight: 600;
}