$env: false;
@import '../../styles/colors';
@import '../../styles/variables';

.contract-page {
    display: flex;
    flex-direction: column;

    .contract-container {
        background-color: $color-aside-bg;
        display: flex;
        flex-direction: column;
        align-items: center;
        align-self: center;
        padding: 2rem;
        width: 64rem;
    }

    .contract-content {
        background-color: $color-tab-bg;
        width: 100%;
        padding: 2rem;
        max-height: 55vh;
        overflow-y: auto;
    }

    .btn-panel {
        .btn {
            margin: 2rem 1rem;
        }
    }
}
