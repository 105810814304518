$env: false;
@import '../../styles/colors';

.access-denied {
    &-page {
        position: absolute;
        z-index: 1000;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;
        justify-content: center;

        .ast-logo {
            height: 9rem;
        }
    }

    &-title {
        font-size: 2.4rem;
        font-weight: bold;
        line-height: 2.8rem;
        color: $color-error-message;
        text-transform: uppercase;
        margin-top: 20rem;
    }

    &-text {
        line-height: 2.4rem;
        margin-top: 4.8rem;
        color: $color-error-message;
        text-align: center;

        p {
            margin: 0;
        }
    }

    &-action {
        margin-top: 4.8rem;
    }
}
