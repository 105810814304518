/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
.search-result-page {
  display: flex;
  min-height: 100%;
}
.search-result-page .aside-panel {
  background-color: #f8f6f4;
  max-width: 32rem;
  flex: 0 0 32rem;
  padding: 2.8rem 2.4rem;
}
.search-result-page .main-content {
  flex: 1 1 auto;
  min-width: 0;
  width: 100%;
  padding: 0 2.4rem;
}
.search-result-page .font-icon {
  height: 2.4rem;
  font-size: 2.4rem;
  margin-right: 1.2rem;
  padding: 0.2rem 0;
}

body.ie .search-result-page .aside-panel {
  min-height: calc(100vh - 7.5rem - 12.8rem);
}