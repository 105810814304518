$env: false;
@import '../../styles/colors';
@import '../../styles/variables';
@import '../../styles/mixins';

.selector {
    display: inline-block;
    padding: .5rem;

    &.open-top {
        &.is-open > .Select-control {
            border-radius: 0 0 4px 4px;
        }

        .Select-menu-outer {
            top: auto;
            bottom: 100%;
            margin-top: 0;
            margin-bottom: -1px;
            border-radius: 4px 4px 0 0;
            box-shadow: none;
        }
    }

    .Select {
        min-width: 12rem;

        &-control {
            height: 3.6rem;

            @include border-rounded;
        }

        &-value-label {
            color: $color-text;
        }

        &-menu-outer {
            z-index: $zindex-dropdown;
        }

        &.is-disabled > .Select-control {
            background-color: $color-background-disabled;
            opacity: .5;
        }

        // emulate "outline: none;"
        &.is-focused:not(.is-open) > .Select-control {
            border-color: $color-border;
            box-shadow: none;
        }
    }

    .Select--single > .Select-control {
        .Select-value {
            padding-right: 2.5rem; // Select-arrow-zone size
        }
    }

    // Firefox fix

    // for input with autosize property
    .selector-input-container > input {
        min-height: 20px;
    }

    // for input without autosize property
    input.selector-input-container {
        min-height: 39px;
    }
}

body.ie {
    .Select.is-disabled .Select-value-label {
        opacity: .5;
    }
}
