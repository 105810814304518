$env: false;
@import '../../styles/colors';
@import '../../styles/variables';
@import '../../styles/animations';

.ast-popover {
    display: inline-block;
    position: relative;

    .trigger {
        position: relative;
        cursor: pointer;
        top: .5rem;
    }
}

.ast-popover.ast-popover--block {
    display: block;
    width: 100%;
}
