$env: false;
@import '../../../../styles/variables';
@import '../../../../styles/colors';

.export-shareholder-table-modal {
    width: 41rem;

    .format-container {
        margin: 2.4rem 0;

        .form-section-title {
            font-weight: $font-weight-bold;
            margin: 2.5rem 0 1rem;
        }

        .radio-component {
            padding: .5rem 0;
        }

        .radio-label,
        .checkbox-label {
            font-weight: $font-weight-regular;
            color: $color-default;
        }

        .records-input {
            display: inline-block;

            .text-input {
                margin-left: 1rem;

                input[type='text'] {
                    max-width: 10rem;
                }
            }
        }

        .disabled {
            opacity: .65;
        }
    }
}
