$env: false;
@import '../../../styles/variables';

.control-books-result {
    .widget-header {
        margin-bottom: 1rem;

        .widget-info-container {
            span:not(:first-child) {
                margin-left: .5rem;
            }
        }

        .balance-cob-message {
            font-weight: normal;
            font-size: 1.4rem;
            margin-top: 1.5rem;
            color: $color-text-info;
        }
    }

    .widget-control-panel.bordered {
        display: flex;
        align-items: flex-end;
        padding-bottom: 1rem;
        border-bottom: $border;
        margin-bottom: 1rem;
    }

    .ReactTable {
        margin-top: 1rem;
    }
}

.control-books-column-manager.common-columns-manager-view {
    max-height: 46rem;
}
