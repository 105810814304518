$env: false;
.watchlist-widget {
    .action-panel {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 1rem;

        .actions-container {
            display: flex;
            flex: 1;
            flex-wrap: wrap;
        }

        .actions-group {
            margin-left: auto;
        }

        .message-notes-container {
            display: flex;
            flex-direction: column;
        }
    }

    .action-panel .font-icon {
        font-size: 2.4rem;
    }

    .action-panel .actions .font-icon {
        margin-left: .6rem;
        line-height: 3.6rem;
        vertical-align: middle;

        &:not(.inactive) {
            cursor: pointer;
        }
    }

    .watchlist-footer {
        margin-top: .5rem;
    }
}

@media print {
    #print-el.watchlist-widget {
        display: block;
        position: relative;
        width: 100%;
        min-height: 20rem;

        .watchlist-table.ReactTable .rt-tr {
            height: inherit;
        }

        .watchlist-table__delete-column,
        .watchlist-table__delete-cell {
            display: none;
        }

        .action-panel .actions-group {
            display: none;
        }

        .watchlist__header {
            display: block;
        }

        .watchlist-instruction {
            display: none;
        }
    }
}
