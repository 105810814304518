@import '../colors';
@import '../variables';

.tabs {
    $border: 2px solid $color-border;
    display: flex;
    align-items: flex-end;

    position: relative;
    width: 100%;
    height: 6.4rem;
    white-space: nowrap;

    &:after {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        content: '';
        border-bottom: $border;
    }

    &:after,
    &:before {
        z-index: 1;
    }

    &-header {
        padding: 0 1.9rem;
    }

    &-group {
        list-style: none;
        vertical-align: bottom;
        margin: 0;
        padding: 0;
    }

    .tab {
        display: inline-block;

        a {
            position: relative;
            z-index: 0;
            display: inline-block;
            padding: 2rem;
            border: $border;
            border-color: transparent;

            &.active {
                z-index: 2;
                border: $border;
                border-bottom-color: $color-tab-bg;

                color: $color-tab-active;
                //font-weight: $font-weight-bold;
                text-shadow: .1rem 0 0, -.1rem 0 0; // alternative of font-weight
            }
        }
    }
}
