$env: false;
.layout-with-aside {
    .first-slot {
        width: 70%;
    }

    .second-slot {
        width: 30%;
    }
}
