//@import 'colors';
@import 'variables';

body,
#app,
#app-root,
.application-wrapper {
    height: 100%;
    min-width: 100%;
    margin: 0;
}

.break-word {
    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;

    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;

    /* Instead use this non-standard one: */
    word-break: break-word;

    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    hyphens: auto;
}

.rt-th.group-header-cell.border-top:nth-child(even) {
    background-color: $color-table-column-bg;
}

html {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
}

// Hide the overflow in IE
svg:not(:root) {
    overflow: hidden;
}

body {
    position: relative;
    min-width: 128rem;
    -webkit-tap-highlight-color: transparent; // remove highlight after add cursor point in Safari(See fix in app.root.js)
}

* {
    box-sizing: border-box;
}

.in-progress {
    * {
        cursor: progress !important;

        &.btn[disabled],
        &.btn.disabled,
        fieldset[disabled] .btn {
            cursor: $cursor-disabled !important;

            * {
                cursor: $cursor-disabled !important;
            }
        }
    }
}

a {
    color: $color-link;
    text-decoration: none;
    cursor: pointer;

    &:hover {
        color: $color-link-hover;
    }
}

ol,
ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

input::-ms-clear {
    display: none;
}

::-webkit-scrollbar {
    width: .5rem;
    height: .5rem;
    -webkit-appearance: none;
}

::-webkit-scrollbar-thumb {
    background-color: $color-scroll;
    border-radius: .5rem;
}

::-webkit-scrollbar-track {
    background-color: $color-scroll-track;
}
