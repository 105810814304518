$env: false;
.reporting-share-detail-page {
    .reporting-section {
        margin: 2.4rem 0;
    }

    .date-range-wrapper {
        margin-left: 0;
    }

    .shares-date-range {
        margin: 0 1.4rem 0 1.8rem;
    }
}
