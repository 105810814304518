/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
.access-denied-page {
  position: absolute;
  z-index: 1000;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
}
.access-denied-page .ast-logo {
  height: 9rem;
}
.access-denied-title {
  font-size: 2.4rem;
  font-weight: bold;
  line-height: 2.8rem;
  color: #000;
  text-transform: uppercase;
  margin-top: 20rem;
}
.access-denied-text {
  line-height: 2.4rem;
  margin-top: 4.8rem;
  color: #000;
  text-align: center;
}
.access-denied-text p {
  margin: 0;
}
.access-denied-action {
  margin-top: 4.8rem;
}