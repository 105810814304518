$env: false;
@import '../../styles/variables';
@import '../../styles/colors';

.issuer-resources-page {
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 100%;
    padding: 0 2.4rem 1rem;

    .title {
        margin-bottom: .4rem;
    }

    &-content {
        display: flex;

        .resource {
            &-column {
                padding-right: 2rem;
                min-width: 38rem;
            }

            &-group {
                &:not(:last-child) {
                    margin-bottom: 5rem;
                }

                &-header {
                    font-weight: $font-weight-bold;
                }
            }

            &-item {
                margin: .5rem 0;

                &-label {
                    color: $color-link;
                    cursor: pointer;
                    font-size: $font-size-small;

                    &:hover {
                        color: $color-link-hover;
                    }
                }
            }
        }
    }
}
