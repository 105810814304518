/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
.shareholder-positions-container {
  height: 32rem;
  position: relative;
}

.dataviz-slot .shareholder-positions-container {
  max-width: calc( 100vw - 32rem - 2 * 2.4rem - (100vw - 32rem) / 100 * 3.333 - 2 * 2.1rem );
}

.shareholder-positions {
  background: #f8f6f4;
  position: absolute;
  left: 0;
  right: 0;
  height: 32rem;
  min-width: 120rem;
}
.shareholder-positions .close-icon {
  position: absolute;
  top: 0.5rem;
  right: 1rem;
  z-index: 2;
}
.shareholder-positions .close-icon .font-icon.font-icon-clear {
  cursor: pointer;
}
.shareholder-positions .error,
.shareholder-positions .no-data {
  line-height: 4rem;
  padding-left: 3.5rem;
}

.shareholder-positions-list {
  width: 100%;
  height: 32rem;
}

.highcharts-xaxis-labels span {
  overflow: visible !important;
}

.positions-list {
  width: 43.5rem;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
}
.positions-list-controls {
  padding: 0.8rem;
  font-size: 1.4rem;
  flex-shrink: 2;
}
.positions-list .position-info {
  margin-left: 3rem;
  color: #6b6056;
  font-style: italic;
}
.positions-list .xignite-link {
  display: block;
  margin: 1rem 0 0 3rem;
}
.positions-list-table {
  position: relative;
  flex-basis: 100%;
  display: flex;
  justify-content: flex-end;
  overflow-y: scroll;
  height: 100%;
}
.positions-list-table .position-source {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.positions-list-table table {
  border-collapse: collapse;
  font-size: 1.2rem;
}
.positions-list-table table td {
  padding: 0 1.2rem;
  min-width: 8rem;
}
.positions-list-table table td:nth-of-type(2) {
  text-align: right;
}
.positions-list-table table td:nth-of-type(3) {
  color: #941f40;
  text-align: right;
  padding-right: 0.8rem;
}
.positions-list-table table tr {
  border-bottom: 1px solid #e9e3dc;
  height: 4rem;
}
.positions-list-table table tr.muted td {
  color: #b2a89d;
}

.positions-chart {
  display: inline-block;
  position: absolute;
  top: 0;
}
.positions-chart .highcharts-title tspan,
.positions-chart .highcharts-legend-item tspan {
  cursor: default;
  fill: #000;
  font-size: 1.4rem;
  font-style: italic;
  font-weight: 400;
  font-family: "Open Sans";
}
.positions-chart .chart-position {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
  line-height: 1.7rem;
  transform: translateX(-50%);
}
.positions-chart .chart-position .position-date {
  color: #6b6056;
  display: inline-block;
}
.positions-chart .chart-position .no-volume {
  font-style: italic;
}