$env: false;
@import '../../../../../styles/colors';

.dataviz-report-link {
    color: $color-text-inverse;
    text-decoration: underline;

    &:hover {
        color: $color-text-inverse;
    }
}
