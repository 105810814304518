$env: false;
@import '../../styles/colors';
@import '../../styles/variables';

.app-footer {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    padding: .7rem 2rem;
    color: $color-text-inverse;
    height: $app-footer-height;
    line-height: 3rem;
    background: $color-footer-background;

    .footer-link {
        color: $color-text-inverse;
        cursor: pointer;

        &:hover {
            color: inherit;
            text-decoration: underline;
        }
    }
}
