/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
.widget {
  position: relative;
  height: 100%;
  min-height: 20rem;
  display: flex;
  flex-direction: column;
}
.widget-header-container {
  display: flex;
}
.widget-header {
  flex: 1 1 auto;
  font-size: 1.8rem;
  font-weight: 700;
  line-height: normal;
  text-align: left;
  color: #6b6056;
}
.widget-control-panel {
  flex: 0 0 auto;
}
.widget-control-panel .font-icon {
  font-size: 2.4rem;
  cursor: pointer;
}
.widget-control-panel .font-icon:not(.inactive).deactivated {
  opacity: 0.65;
  cursor: pointer;
}
.widget-control-panel .widget-menu {
  display: inline-block;
}
.widget-fetching {
  height: 100%;
}
.widget-no-data, .widget-error {
  height: 100%;
}
.widget .no-data-text,
.widget .error-message {
  width: 18.4rem;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.widget-content {
  position: relative;
  padding-top: 1rem;
  flex-grow: 100;
  min-height: 10rem;
}
.widget-content .widget-with-pie-chart {
  height: 100%;
  display: flex;
}
.widget .pie-container {
  position: relative;
  flex-basis: 38%;
}
.widget .pie-container:before {
  content: "";
  display: block;
  padding-top: 100%;
}
.widget .pie-container .pie-content {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 1rem 0;
}
.widget:not(.shareholders-table) .highcharts-container {
  overflow: visible !important;
  z-index: auto !important;
}
.widget .legend-container {
  flex-basis: 62%;
  padding: 1rem 0 0.1rem;
  z-index: 0;
}
.widget .no-results {
  white-space: pre-wrap;
  padding: 2.5rem 0;
  text-align: center;
}
.widget .no-results span {
  display: inline-block;
  max-width: 40%;
}
.widget .ast-highcharts-tooltip {
  background-color: rgba(247, 247, 247, 0.85);
  border: 1px solid;
  padding: 0.4rem 1rem;
  border-radius: 0.2rem;
}
.widget [data-highcharts-chart] {
  overflow: visible !important;
}