/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
.reporting-shareholder-list-search-page .reporting-edit {
  margin-bottom: 2.4rem;
}
.reporting-shareholder-list-search-page .securities-shareholders-container {
  display: flex;
  gap: 64px;
}
.reporting-shareholder-list-search-page .reporting-section {
  margin-bottom: 10px;
}
.reporting-shareholder-list-search-page .reporting-section-body {
  margin: 0;
}
.reporting-shareholder-list-search-page .reporting-section-body .selector.securities-selector {
  min-width: 45rem;
}
.reporting-shareholder-list-search-page .reporting-section-records {
  display: flex;
  align-items: center;
  margin: 2.4rem 0;
}
.reporting-shareholder-list-search-page .reporting-section-records .text-input {
  margin-left: 0.8rem;
}
.reporting-shareholder-list-search-page .reporting-section-records .text-input input[type=text] {
  max-width: 10rem;
}
.reporting-shareholder-list-search-page .expand-collapse-all-section {
  margin-bottom: 1rem;
}
.reporting-shareholder-list-search-page .expand-collapse-all-text {
  display: flex;
  align-items: center;
  color: #941f40;
  cursor: pointer;
  width: fit-content;
}
.reporting-shareholder-list-search-page .expand-collapse-all-text i {
  margin-top: 4px;
  cursor: pointer;
  font-size: 2rem;
}
.reporting-shareholder-list-search-page .shares-owned-range .checkbox-label {
  font-weight: 400;
  margin: 0 2.4rem 0 0.8rem;
}
.reporting-shareholder-list-search-page .shares-owned-range .input-container {
  display: inline-block;
}
.reporting-shareholder-list-search-page .shares-owned-range .input-container input[type=text] {
  max-width: 16.4rem;
}
.reporting-shareholder-list-search-page .shares-owned-range .input-container:not(:last-child) {
  margin-right: 1.2rem;
}
.reporting-shareholder-list-search-page .shares-owned-range .text-input-label {
  margin-right: 0.6rem;
}
.reporting-shareholder-list-search-page .date-range {
  display: inline-block;
  margin-bottom: 2.4rem;
}
.reporting-shareholder-list-search-page .date-range .checkbox-component {
  margin-right: 3.2rem;
}
.reporting-shareholder-list-search-page .date-of-last-contact {
  display: flex;
  align-items: center;
}
.reporting-shareholder-list-search-page .date-of-last-contact .checkbox-label {
  font-weight: 400;
  margin: 0 2.4rem 0 0.8rem;
}
.reporting-shareholder-list-search-page .address-status {
  display: flex;
  flex-direction: column;
  width: 24rem;
}
.reporting-shareholder-list-search-page .address-status-label {
  font-size: 1.2rem;
}
.reporting-shareholder-list-search-page .address-status-label.disabled {
  opacity: 0.65;
  cursor: default;
}
.reporting-shareholder-list-search-page .address-status-selector {
  padding-left: 0;
}
.reporting-shareholder-list-search-page .reporting-include-section {
  display: flex;
  margin-top: 0.5rem;
}
.reporting-shareholder-list-search-page .reporting-include-column:not(:last-child) {
  margin-right: 4.8rem;
}
.reporting-shareholder-list-search-page .reporting-include-column .holder-columns-section-title {
  margin-bottom: 1.2rem;
  color: #6b6056;
  font-weight: bold;
}
.reporting-shareholder-list-search-page .reporting-include-column .holder-columns-section-title.disabled {
  opacity: 0.65;
}
.reporting-shareholder-list-search-page .reporting-include-column .select-all-checkbox {
  border-bottom: 1px solid #6b6056;
}
.reporting-shareholder-list-search-page .reporting-include-column .holder-columns {
  display: flex;
}
.reporting-shareholder-list-search-page .reporting-include-column .holder-column {
  margin-right: 4.8rem;
}
.reporting-shareholder-list-search-page .reporting-include-column .checkbox-label {
  font-weight: normal;
}
.reporting-shareholder-list-search-page .reporting-include-column .address-type {
  display: flex;
  align-items: center;
}
.reporting-shareholder-list-search-page .reporting-include-column .address-type-selector {
  min-width: 17.5rem;
  padding: 0 0.5rem;
}
.reporting-shareholder-list-search-page .reporting-section-sort {
  display: flex;
  align-items: center;
}
.reporting-shareholder-list-search-page .reporting-section-sort .reporting-section-sort-body {
  margin: 0 1.2rem;
}
.reporting-shareholder-list-search-page .reporting-section-sort .reporting-sort-field {
  display: flex;
  align-items: center;
}
.reporting-shareholder-list-search-page .reporting-section-sort .selector {
  margin-right: 2.6rem;
}
.reporting-shareholder-list-search-page .location-container .location-row {
  display: flex;
  align-items: center;
  margin: 20px 0;
}
.reporting-shareholder-list-search-page .location-container .checkbox-label {
  font-weight: 400;
  margin: 0 2.8rem 0 0.8rem;
}
.reporting-shareholder-list-search-page .location-container .location-cell:not(:first-child) {
  margin-left: 20px;
}
.reporting-shareholder-list-search-page .location-container .select-container .selector {
  padding-left: 0;
}
.reporting-shareholder-list-search-page .location-container .select-container .Select-loading-zone {
  cursor: default;
}
.reporting-shareholder-list-search-page .location-container .checkbox-container {
  margin-top: 20px;
}
.reporting-shareholder-list-search-page .location-container .location-label {
  font-size: 1.2rem;
}
.reporting-shareholder-list-search-page .location-container .location-text-input-container .text-input {
  padding: 5px 5px 5px 0;
  display: block;
  min-width: 23.8rem;
}
.reporting-shareholder-list-search-page .location-container .location-text-input-container .text-input > input {
  width: 100%;
}
.reporting-shareholder-list-search-page .location-container .location-select-wrapper {
  display: block;
  min-width: 23.8rem;
}
.reporting-shareholder-list-search-page .font-icon-paging-right {
  margin-left: 0.3rem;
  font-size: 1rem;
  cursor: pointer;
}
.reporting-shareholder-list-search-page .icon-rotate {
  transform: rotate(90deg);
}
.reporting-shareholder-list-search-page .expanded {
  display: none;
}
.reporting-shareholder-list-search-page .ast-popover {
  margin-top: 4px;
  height: 20px;
  width: 20px;
}
.reporting-shareholder-list-search-page .ast-popover i {
  font-size: 2rem;
}
.reporting-shareholder-list-search-page .shareholder-list-actions {
  border-top: 1px solid #e9e3dc;
  padding: 2.4rem 0 0;
  margin-top: 4.8rem;
  display: flex;
  align-items: center;
}
.reporting-shareholder-list-search-page .shareholder-list-actions .btn {
  margin: 0 2rem 0 0;
}
.reporting-shareholder-list-search-page .shareholder-list-section-header {
  font-weight: 700;
  line-height: 2;
  color: #000;
  display: flex;
  align-items: center;
}
.reporting-shareholder-list-search-page .shareholder-list-section-header .ast-popover {
  height: 20px;
  width: 20px;
  margin-top: 0;
  margin-right: 4px;
}
.reporting-shareholder-list-search-page .shareholder-list-section-header .ast-popover i {
  font-size: 2rem;
}