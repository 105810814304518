$env: false;
@import '../../../../styles/variables';
@import '../../../../styles/colors';

.analysis-table {
    &.ReactTable {
        overflow-x: visible;
    }

    .rt-table {
        .-headerGroups {
            height: 8.4rem;
            background-color: transparent;

            .additional-component {
                padding: 0;
            }
        }

        .header-shareholder-name {
            padding-left: 3.5rem;
        }

        .no-group-header-cell {
            .label {
                font-style: italic;
                font-weight: normal;
            }
        }

        .position,
        .os,
        .mkt-val {
            justify-content: center;
            text-align: center;
            padding: 0 1.7rem 0 .5rem;

            &.rt-td {
                padding: 0 .8rem;
            }

            div {
                flex-shrink: 1; // IE 10 fix
            }

            .sort-icon {
                right: 0;
            }
        }

        .rt-td:nth-child(6n-4),
        .rt-td:nth-child(6n-3),
        .rt-td:nth-child(6n-2),
        .rt-th.position:nth-child(even),
        .rt-th.os:nth-child(odd),
        .rt-th.mkt-val:nth-child(even) {
            background-color: $color-table-column-bg;
        }
    }

    .group-header-cell {
        display: flex;
        justify-content: center;

        .wrapper {
            display: flex;
            flex-shrink: 1; // IE 10 fix

            .label {
                display: inline-block;
                font-weight: bold;
                flex-shrink: 1; // IE 10 fix
            }

            .delete-icon {
                margin-left: 1rem;
                cursor: pointer;
            }
        }
    }

    .border-top {
        border-top: $border;
    }

    .cell-with-shareholder-info {
        display: flex;
        align-items: center;
        width: 100%;

        .shareholder-name {
            margin-left: .5rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            flex-shrink: 1;
            display: block;
        }

        .font-icon-insider {
            cursor: auto;
        }
    }

    .arrow-scroll-block {
        width: 5rem;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .additional-group-cell {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        font-weight: normal;

        .scroll-block {
            height: 100%;
        }

        .additional-block {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            flex-grow: 1;
            flex-basis: 0; // IE Fix
            margin: .5rem 0;

            &-top {
                display: flex;
                justify-content: space-between;
            }

            .message-note {
                padding-right: .5rem;
            }

            .xignite-link {
                font-size: $font-size-medium;
                flex-shrink: 0;
            }
        }
    }

    .main-column-heading {
        display: flex;
        align-items: center;
        line-height: 1rem;

        & .search-icon {
            display: inline-block; // IE 10 fix
            padding-left: 1rem;

            &.disabled .font-icon {
                cursor: inherit;
                color: $color-text-muted;
            }
        }
    }
}

.ReactTable .rt-td.rt-td--display-none,
.rt-th.group-header-cell--display-none.-cursor-pointer {
    display: none;
}
