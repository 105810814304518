$env: false;
.compare-shareholders-page {
    padding: 1.5rem 2.4rem 2.4rem;

    .compare-shareholders-page-header {
        .title {
            margin-top: 1rem;
        }
    }
}
