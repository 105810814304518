/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
.alerts-modal-header {
  position: absolute;
  top: 1.5rem;
  left: 4.8rem;
  right: 4.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.alerts-modal-header .header-text,
.alerts-modal-header .icon-pagination {
  font-size: 1.8rem;
  margin: 0 1rem;
}
.alerts-modal-header .icon-pagination-container {
  display: flex;
  align-items: center;
}
.alerts-modal-header .header-text {
  font-size: 2.4rem;
  font-weight: 700;
  line-height: normal;
  text-align: left;
  color: #6b6056;
}
.alerts-modal-content {
  margin-top: 2.2rem;
}
.alerts-modal-content .alert-upper-section {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #e9e3dc;
  border-bottom: 1px solid #e9e3dc;
  padding: 0.8rem 0;
  margin: 1rem 0;
}
.alerts-modal-content .alert-upper-section .label span:first-child {
  font-weight: 700;
  color: #6b6056;
}
.alerts-modal-content .alert-text-section {
  width: 50rem;
  min-height: 8.5rem;
  word-wrap: break-word;
}
.alerts-modal-content .alert-lower-section {
  border-top: 1px solid #e9e3dc;
  border-bottom: 1px solid #e9e3dc;
  padding: 0.8rem 0;
  margin: 1rem 0;
}
.alerts-modal-content .alert-lower-section .label:first-child {
  margin-bottom: 0.8rem;
}
.alerts-modal-content .alert-lower-section .label span:first-child {
  font-weight: 700;
  color: #6b6056;
}
.alerts-modal-actions {
  display: flex;
  justify-content: space-between;
}
.alerts-modal-actions .btn-custom {
  display: flex;
  align-items: center;
}
.alerts-modal-actions .btn-custom:hover {
  color: #fff;
}
.alerts-modal-actions .btn-custom:focus:before {
  left: 51%;
  right: 51%;
}