$env: false;
@import '../../styles/colors';
@import '../../styles/variables';

.spinner {
    width: 100%;
    height: 4rem;
    display: none;

    &.loading {
        &.blocker {
            position: absolute;
            height: 100%;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: $zindex-spinner;
            background-color: rgba(0, 0, 0, .5);

            .spinner-content {
                color: white;
            }
        }

        display: flex;
        align-items: center;
        justify-content: center;

        .spinner-content {
            display: flex;
            align-items: center;

            .spinner-block {
                margin-left: 1rem;
            }
        }
    }

    tr &.loading {
        border-bottom: none;
    }
}

.spinner-item {
    width: 25px;
    height: 25px;
    display: inline-block;
    border: 5px solid rgb(203, 196, 190);
    border-top-color: rgb(239, 78, 55);
    border-radius: 50%;
    animation: spin 2s linear infinite;
}
