$env: false;
@import '../../styles/variables';

.profile-avatar-container {
    position: relative;

    .profile-avatar {
        background: url('#{$images-path}/default-user-avatar.svg?v=1') no-repeat;
        background-size: 100% 100%;
    }
}
