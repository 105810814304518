$env: false;
.errors-logs-page {
    padding: 0 10rem;

    .errors-logs {
        &-header {
            display: flex;
            justify-content: space-between;
        }

        &-links {
            display: flex;
            align-items: center;

            a {
                margin-left: 1.5rem;
            }
        }
    }
}
