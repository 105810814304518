@import '~react-select/scss/select';
@import '../variables';

$select-loading-color: $color-spinner;
$select-loading-color-bg: $color-spinner_bg;

.Select-loading {
    width: $select-loading-size;
    height: $select-loading-size;
    border-radius: 50%;
    border: floor(($select-loading-size / 5)) solid $select-loading-color-bg;
    border-top-color: $select-loading-color;
    display: inline-block;
    position: relative;

    vertical-align: baseline;
    animation: spin 2s linear infinite;
}
