$env: false;
@import '../../../../../styles/variables';

.cell-with-ellipsis {
    cursor: pointer;
    word-break: break-all;
}

.multi-row-ellipsis {
    position: relative;
    height: 3.8rem;
    overflow: hidden;

    &:after {
        content: '...';
        position: absolute;
        top: 1.8rem;
        right: 0;
        background-color: $color-icon-inverse;
    }
}

.rt-tr:hover {
    .multi-row-ellipsis:after {
        background-color: $color-nav-item-hover;
    }
}
