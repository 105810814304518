/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
.holder-investor-type-analysis .widget-header-container {
  display: block;
  margin-bottom: 3rem;
}
.holder-investor-type-analysis .holder-type-analysis-control-panel {
  display: flex;
  align-items: center;
}
.holder-investor-type-analysis .holder-type-analysis-control-panel .selector {
  margin-right: 1.4rem;
  min-width: 23.8rem;
}
.holder-investor-type-analysis .holder-type-analysis-control-panel .selector .is-disabled .Select-control {
  opacity: 1;
}
.holder-investor-type-analysis .holder-type-analysis-control-panel .legend-item {
  display: flex;
  align-items: center;
  margin-right: 2.4rem;
}
.holder-investor-type-analysis .holder-type-analysis-control-panel .legend-item-icon {
  font-size: 1.8rem;
  color: #6b6056;
  margin-right: 1.2rem;
}
.holder-investor-type-analysis .holder-type-analysis-control-panel .legend-item-text {
  font-size: 1.2rem;
}
.holder-investor-type-analysis .holder-type-analysis-control-panel .action-icons-container {
  display: flex;
  margin-left: auto;
}
.holder-investor-type-analysis .holder-type-analysis-control-panel .action-icons-container .action-icon {
  font-size: 2.4rem;
}
.holder-investor-type-analysis .holder-type-analysis-control-panel .action-icons-container .action-icon:not(.inactive) {
  cursor: pointer;
}
.holder-investor-type-analysis .holder-type-analysis-content .shareholder-type-icon {
  font-size: 1.8rem;
  color: #6b6056;
  cursor: default;
}
.holder-investor-type-analysis .holder-type-analysis-content .message-note {
  padding: 0 0 1.2rem;
}
.holder-investor-type-analysis .ReactTable {
  overflow-x: visible;
}
.holder-investor-type-analysis .ReactTable .rt-thead {
  height: 4rem;
}
.holder-investor-type-analysis .ReactTable .rt-th:first-child {
  display: none;
}
.holder-investor-type-analysis .ReactTable .rt-th:first-child + .rt-th {
  padding-left: 48px;
  justify-content: flex-start;
}
.holder-investor-type-analysis .ReactTable .rt-td:first-child,
.holder-investor-type-analysis .ReactTable .rt-th:first-child {
  border-right: 0;
  padding: 0;
  align-items: center;
  justify-content: center;
}
.holder-investor-type-analysis .ReactTable .rt-th:not(:first-child) {
  justify-content: center;
}
.holder-investor-type-analysis .ReactTable .cell-with-description {
  width: 100%;
}
.holder-investor-type-analysis .ReactTable .cell-with-description .text-ellipsis {
  width: auto;
  max-width: 99%;
}
.holder-investor-type-analysis .shareholder-type-option {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.holder-investor-type-analysis .shareholder-type-option .font-icon {
  font-size: 1.8rem;
}