$env: false;
@import 'src/styles/variables';

.registered-shareholders-note {
    display: flex;
    line-height: 2.8rem;
    overflow: hidden;
    align-items: flex-start;

    .message-note {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin: 0 .9rem 0 0;
        padding: 0;
        line-height: 2.8rem;
    }

    .font-icon {
        font-size: $icon-large-size;
        vertical-align: text-top;
    }

    &__popover-tip-content {
        width: 28rem;
        font-size: $font-size-small;
    }
}
