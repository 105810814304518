$env: false;
.registered-filter-panel {
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;

    &-wrapper {
        display: flex;
        flex-direction: column;
    }

    .right-aside {
        font-size: 1.2rem;
        display: flex;
        align-items: center;
    }

    .date-range-wrapper {
        display: flex;
    }

    .input-date-range-picker .date-picker-component {
        margin-right: 1rem;
    }
}
