$env: false;
@import '../../../../../styles/colors';
@import '../../../../../styles/variables';

.date-range-info {
    margin-left: 1rem;
    vertical-align: middle;

    &.font-icon {
        font-size: $font-size-x-large;
    }

    &.inactive {
        opacity: $field-disabled-opacity;
        pointer-events: none;
    }
}
