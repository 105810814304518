/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
.widget-full-quote .widget-header {
  font-size: 2.4rem;
  font-weight: 700;
}
.widget-full-quote .widget-control-panel {
  display: flex;
  align-items: flex-end;
}

.full-quote-modal {
  width: 53rem;
  padding-top: 1.2rem;
}
.full-quote-modal .widget-header {
  flex-grow: 0;
  margin-right: 0.6rem;
}
.full-quote-modal .action-icon {
  font-size: 2.9rem;
}
.full-quote-modal .full-quote-content {
  min-width: 48rem;
}
.full-quote-modal .modal-header {
  position: absolute;
  right: 2rem;
  top: 2rem;
  z-index: 1;
}
.full-quote-modal .modal-header .modal-header-left {
  display: none;
}
.full-quote-modal .modal-header .close {
  left: auto;
}

.full-quote-content {
  margin: 2.4rem 0 1.2rem;
}
.full-quote-content .content-title {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  font-size: 1.8rem;
}
.full-quote-content .content-title-left {
  flex-shrink: 1;
  padding-right: 2rem;
}
.full-quote-content .content-title-left .title {
  font-weight: 700;
}
.full-quote-content .content-title-right .first-prop {
  font-weight: 700;
}
.full-quote-content .content-title-right .last-prop {
  margin-left: 1.2rem;
  font-weight: 700;
}
.full-quote-content .content-title-right .last-prop.positive:before {
  content: "+";
}
.full-quote-content .content-title-right .last-prop.negative:before {
  content: "-";
}
.full-quote-content .content-title-right .last-prop.neutral {
  color: #599f08;
}

.full-quote-table {
  display: flex;
  flex-flow: row wrap;
}
.full-quote-table .column {
  flex: 1 0 50%;
  max-width: 50%;
}
.full-quote-table .column:first-child {
  padding-right: 1rem;
}
.full-quote-table .column:not(:first-child) {
  padding-left: 1rem;
}
.full-quote-table .column-item {
  display: flex;
  justify-content: space-between;
  height: 2.9rem;
  border-bottom: 0.1rem solid #e9e3dc;
  align-items: center;
}
.full-quote-table .column-item-name {
  flex: 1 1 auto;
}
.full-quote-table .column-item-value {
  flex: 1 1 auto;
  font-weight: 700;
  display: inline-block;
  text-align: right;
  padding-left: 0.5rem;
}
.full-quote-table-footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 1.3rem;
}
.full-quote-table-footer .market-close-date {
  font-style: italic;
  color: #6b6056;
}