$env: false;
@import '../../../../styles/colors';
@import '../../../../styles/variables';

.reporting-share-range-search-page {
    .message-note {
        margin-top: 2.4rem;
    }

    .reporting-header ~ .message-note {
        font-weight: $font-weight-light;
        margin: 0;
        padding: 0;
    }

    .reporting-share-range-search-form {
        margin-top: 2.4rem;
    }

    .share-range-input-delimiter {
        display: inline-block;
        margin: 3.4rem 1.2rem;
    }

    .fields-container {
        margin-bottom: 4.8rem;
    }

    .share-range-input {
        display: inline-block;
        width: 23.8rem;
        margin-bottom: 4.8rem;

        &.block {
            display: block;
        }

        input {
            width: 23.8rem;
        }
    }
}
