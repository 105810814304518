$env: false;

.institution-profile-page {
    padding: 2.4rem;

    .header-title-container {
        display: flex;
        align-items: baseline;
    }

    .slot:first-child {
        .widget-box {
            width: 50%;
        }
    }
}
