/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
.personal-info-header {
  display: flex;
  justify-content: space-between;
}
.personal-info-content {
  display: flex;
  padding: 2.4rem 0 0.7rem;
}
.personal-info-content .profile-avatar {
  height: 16rem;
  width: 16rem;
  border-radius: 50%;
}
.personal-info-image {
  margin-right: 4rem;
  position: relative;
  width: 16rem;
  min-width: 16rem;
  height: 18.6rem;
}
.personal-info-image .profile-avatar {
  width: 16rem;
  height: 16rem;
}
.personal-info-data {
  min-width: 0;
  flex-shrink: 1;
  padding-bottom: 3rem;
}
.personal-info-data-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}
.personal-info .user-name, .personal-info .user-title, .personal-info .user-email, .personal-info .user-role {
  font-size: 1.8rem;
}
.personal-info .user-name {
  font-weight: 700;
  margin-bottom: 1.2rem;
}
.personal-info .user-email {
  display: flex;
  word-break: break-all;
}
.personal-info .user-email .edit-email-link {
  display: flex;
  align-items: center;
}
.personal-info .info-footer {
  padding-top: 1rem;
}
.personal-info .link {
  color: #941f40;
  cursor: pointer;
}
.personal-info .link:hover {
  color: #ef4e37;
}

body.ie .personal-info .info-message ol {
  margin-left: 1.8rem;
}