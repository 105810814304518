/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
.widget-performance .cell-with-number {
  width: 100%;
}
.widget-performance .cell-with-date {
  text-align: left;
}
.widget-performance .performance-content-table-rt .rt-thead {
  height: 4rem;
  min-height: 4rem;
}
.widget-performance .performance-content-table-rt .rt-th:not(:first-child) {
  justify-content: center;
}
.widget-performance .performance-content-table-rt .rt-td {
  word-break: break-all;
}
.widget-performance .performance-content-table-rt .rt-tr,
.widget-performance .performance-content-table-rt .rt-td {
  min-height: 3rem;
}
.widget-performance .performance-content-table-rt .rt-tr-group:hover .rt-td:first-child {
  border-right: 1px solid #e9e3dc;
}
.widget-performance .performance-content .performance-chart-container {
  height: 42.2rem;
  position: relative;
}
.widget-performance .performance-content .labels {
  font-size: 1.2rem;
}
.widget-performance .performance-content .performance-content-chart .labels {
  display: flex;
  justify-content: space-between;
  padding-right: 7.2rem;
}
.widget-performance .performance-content .volume-chart-container {
  height: 28.5rem;
  position: relative;
}
.widget-performance .performance-content .volume-chart-content {
  margin-top: 2.4rem;
}
.widget-performance .performance-content .volume-title {
  font-size: 1.8rem;
  font-weight: 700;
  color: #6b6056;
  margin-top: 1.2rem;
}
.widget-performance .performance-content .period-label {
  font-weight: 700;
}
.widget-performance .performance-content .chart-checkbox:not(:last-child) {
  margin-right: 4rem;
}
.widget-performance .performance-content .range-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}
.widget-performance .performance-content-table {
  position: relative;
  min-height: 10rem;
}
.widget-performance .performance-content-small .performance-content-table .table-sticky-column .cell {
  border-bottom: none;
}
.widget-performance .performance-content-small .performance-content-table .rt-th,
.widget-performance .performance-content-small .performance-content-table .rt-th-first {
  border-right: 1px #eae8e5 solid;
}
.widget-performance .performance-content-small .performance-content-table .rt-th,
.widget-performance .performance-content-small .performance-content-table .rt-th-last {
  text-align: center;
}
.widget-performance .performance-content-small .performance-content-table .rt-th-last {
  border-right: none;
}
.widget-performance .performance-content-small .performance-content-table .rt-td {
  border-right: 1px #eae8e5 solid;
}
.widget-performance .performance-content-small .performance-content-chart-small {
  position: relative;
  min-height: 10rem;
}
.widget-performance .performance-content-small .performance-chart-container {
  height: 18rem;
}
.widget-performance .performance-content-small .performance-chart-filter {
  margin: 0.8rem 0 1.4rem;
}
.widget-performance .performance-content-small .range-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
}
.widget-performance .performance-content-small .xignite-label {
  margin-top: 1rem;
}
.widget-performance .widget-header-with-date {
  flex: 1;
}