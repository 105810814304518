$env: false;
@import '../../styles/colors';

.xignite-link {
    color: $color-text-inverse;

    &:hover {
        color: inherit;
        text-decoration: underline;
    }
}
