$env: false;
@import '../../styles/variables';

.errors-details {
    display: flex;
    flex-flow: column nowrap;

    .error-item {
        &:not(:first-child) {
            padding-top: 5rem;
            border-top: 3px dashed $color-border-dark;
        }

        &:not(:last-child) {
            padding-bottom: 5rem;
        }

        .error-item-header {
            display: flex;
            text-align: left;
            flex-flow: column nowrap;

            .header-info {
                margin-right: 1rem;

                &-title {
                    font-weight: $font-weight-bold;
                }
            }
        }

        &-content {
            width: 100%;
            min-height: 30rem;
            text-align: left;
            border: $border-dark;
            border-radius: $border-radius;
            white-space: pre-wrap;
            padding: 2rem;
            word-wrap: break-word;
        }
    }
}
