.dataviz-export-page {
  pointer-events: none;
}
.dataviz-export-page .widget-institution-profile {
  max-width: 70rem;
}
.dataviz-export-page .widget {
  border: none;
  min-height: initial;
  height: initial;
}
.dataviz-export-page .slot.col-2 {
  max-width: 100%;
  width: initial;
}
.dataviz-export-page .slot.col-3 {
  display: flex;
}