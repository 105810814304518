$env: false;
@import '../../../../styles/variables';
@import '../../../../styles/colors';

.geographic-analysis {
    .table-easy-with-footer {
        padding-bottom: 5rem;
        margin-bottom: 0;
    }

    .font-icon.font-icon-filter {
        font-size: 2.2rem;
    }

    .custom-table-header {
        display: flex;
        flex: 1 1 auto;
        justify-content: space-between;
        align-items: center;
        margin-left: -1.2rem; // padding-left: 0.8rem imitation for IE 10

        &-title {
            display: flex;
            flex-wrap: wrap;
            flex-shrink: 1; // IE 10 fix
            align-items: center;
        }
    }

    .ReactVirtualized__Table__headerRow {
        align-items: stretch;
    }

    .ReactVirtualized__Table__headerColumn {
        padding: 0 2rem;

        .ReactVirtualized__Table__headerTruncatedText {
            text-overflow: clip;
            overflow: auto;
            white-space: normal;
        }
    }

    .ReactVirtualized__Table__row {
        align-items: stretch;

        .ReactVirtualized__Table__rowColumn {
            display: flex;
            align-items: center;
        }
    }

    .virtualized-sticky-footer {
        border-top: none;
        height: 4rem;
        padding: 0;

        .ReactVirtualized__Table__rowColumn {
            text-transform: uppercase;

            &:first-child {
                text-align: left;
            }
        }
    }

    .message-note {
        padding: 0 0 1.2rem;
    }

    .trigger-wrapper {
        display: flex;
        align-items: center;
        margin-left: .6rem;
    }

    .action-icons-container {
        .action-icon {
            font-size: $font-size-x-large;

            &:not(.inactive) {
                cursor: pointer;
            }
        }
    }

    .geographic-analysis-control-panel {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 2.4rem 0;

        .left-details-container {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            flex-shrink: 1; // IE 10 fix

            & > * {
                margin-right: 1.2rem;
            }
        }

        .action-icons-container {
            flex: none;
        }

        .checkbox-label {
            font-weight: $font-weight-regular;
            color: $color-default;
        }
    }

    .geographic-analysis-selector {
        min-width: 23.8rem;
        padding: 0;

        .is-disabled .Select-control {
            opacity: 1;
        }
    }

    .cell-with-number,
    .cell-with-percent {
        display: flex;
        justify-content: flex-end;
    }

    .widget {
        &-fetching,
        &-no-data,
        &-error {
            min-height: 31rem;
        }
    }

    .ReactVirtualized__Table__sortableHeaderIcon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
    }

    .cell-value {
        width: 100%;
    }

    .shareholder-type-option {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .font-icon {
            font-size: $font-size-large;
        }
    }

    .column-filter {
        top: .3rem;
    }

    .shares-number {
        margin: 0 .4rem;
    }
}
