$env: false;
@import 'src/styles/variables';

.share-report-template {
    &__message-note {
        margin-top: 2.4rem;
        margin-bottom: 1.5rem;
        font-size: $font-size-small;
        font-style: italic;
        color: $color-text-info;
        line-height: $line-height-base;

        .color-primary {
            color: $color-primary;
            font-weight: $font-weight-bold;
        }
    }

    &__user-list-header {
        display: flex;
        align-items: center;
        padding-left: 4rem;
        background-color: $color-table-header-bg;
        font-weight: $font-weight-bold;
        color: $color-header;
        height: 3.5rem;
    }

    &__user-list {
        border: .2rem solid #e9e3dc;

        .list-item {
            display: flex;
            align-items: center;
            color: $color-text-label;
            padding-left: 1.5rem;
            border-bottom: .1rem solid $color-border;

            label {
                font-weight: $font-weight-regular;
            }
        }
    }

    .modal-actions {
        margin-top: 2.5rem;
    }

    .modal-actions:before {
        display: none;
    }
}
