$env: false;
@import '../../../../../styles/variables';
@import '../../../../../styles/colors';

.prior-history-info-message {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 2.2rem;

    &-text {
        width: 100%;
    }

    &-link {
        cursor: pointer;
        color: $color-link;
        white-space: nowrap;

        &:hover {
            color: $color-link-hover;
        }
    }
}
