$env: false;
@import '../../../../../../styles/mixins';
@import '../../../../../../styles/variables';

.shareholders-types {
    display: flex;
    align-items: center;

    .shareholder-type-label {
        cursor: default;
    }

    .selector {
        padding: 0;
        margin-left: -1px;
        vertical-align: top;

        .Select {
            min-width: 18rem;
        }
    }
}
