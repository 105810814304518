$env: false;
@import '../../styles/variables';

.app {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    position: relative;
    padding-bottom: $app-footer-height;

    .page { // is used only as alias
        &-main-section {
            display: flex;
            flex-direction: row;
            flex-grow: 1;
            height: 100%;
        }

        &-content {
            width: 100%;
        }

        &-aside {
            flex: 1 auto;
        }
    }
}
