$env: false;
@import '../../styles/variables';

.securities-selector.selector {
    .Select-control {
        height: 4rem;

        .Select-value {
            line-height: 3.8rem;
        }
    }

    .Select-menu-outer {
        max-height: 45.2rem;

        .Select-menu {
            max-height: 45rem;

            .Select-option {
                max-height: 4.5rem;
                padding: .7rem 1rem;

                .checkbox-component.flex {
                    align-items: center;

                    .font-icon {
                        flex-shrink: 0;
                    }
                }

                .checkbox-label {
                    font-weight: normal;
                }

                &.option-default {
                    cursor: default;

                    .checkbox-component {
                        cursor: default;
                    }

                    .font-icon {
                        opacity: $field-disabled-opacity;
                    }
                }

                &.is-focused {
                    background-color: inherit;
                }

                &:hover {
                    background-color: $color-dropdown-bg-hover;
                }
            }
        }
    }
}
