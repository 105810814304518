/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
.top-shareholders-search-modal {
  overflow: visible;
}
.top-shareholders-search-modal-content {
  padding-top: 1rem;
  width: 41rem;
}
.top-shareholders-search-modal .arrow-role {
  font-size: 2rem;
  line-height: 3rem;
}
.top-shareholders-search-modal .Select-input {
  width: 100%;
}
.top-shareholders-search-modal .Select-option.is-disabled,
.top-shareholders-search-modal .Select-noresults {
  color: #7f7369;
}