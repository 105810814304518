/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
.reporting-control-books-page .reporting-header-container {
  display: flex;
  align-items: center;
  max-width: 97rem;
  justify-content: space-between;
}
.reporting-control-books-page .reporting-subheader {
  display: inline-block;
  color: #000;
}
.reporting-control-books-page .reporting-section {
  margin-bottom: 2.4rem;
}
.reporting-control-books-page .reporting-section-body {
  margin: 0;
}
.reporting-control-books-page .reporting-section .reporting-actions {
  border-top: none;
  display: inline-block;
  margin: 0 1.2rem;
  padding: 0;
}
.reporting-control-books-page .reporting-section .reporting-actions .btn-control-books-submit {
  width: 10rem;
}
.reporting-control-books-page .reporting-section .date-range-section {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.reporting-control-books-page .reporting-section .additional-options-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.reporting-control-books-page .reporting-section .additional-options-section .checkbox-section {
  height: 3.5rem;
}
.reporting-control-books-page .reporting-section .additional-options-section .checkbox-section .checkbox-component:not(:first-child) {
  margin-left: 3rem;
}
.reporting-control-books-page .reporting-section .additional-options-section .checkbox-label {
  font-weight: 400;
}
.reporting-control-books-page .reporting-section .additional-options-section .message-note.disabled {
  opacity: 0.5;
}
.reporting-control-books-page .subscribe-email {
  display: block;
  font-weight: 400;
  color: #000;
  margin: 4.8rem 0 0;
}
.reporting-control-books-page .reporting-header-alternative {
  margin: 0 0 0.5rem;
}
.reporting-control-books-page .securities-container {
  display: flex;
  align-items: center;
  width: 50rem;
  margin: 1rem 0 2rem;
}
.reporting-control-books-page .securities-container > span {
  color: #6b6056;
  font-weight: 700;
  margin-right: 1rem;
}
.reporting-control-books-page .securities-container .securities-selector {
  min-width: 45rem;
}
.reporting-control-books-page .control-books-note {
  width: 50rem;
  margin: 1.5rem 0 0 0.5rem;
}
.reporting-control-books-page .control-books-note .note-title {
  font-weight: 700;
}
.reporting-control-books-page .control-books-container {
  display: flex;
  align-items: center;
  height: 9.1rem;
}
.reporting-control-books-page .control-books-container .message-note {
  margin-left: 1.5rem;
}
.reporting-control-books-page .control-books-container .spinner.loading {
  justify-content: flex-start;
}
.reporting-control-books-page .total-outstanding-shares {
  max-width: 97rem;
}
.reporting-control-books-page .total-outstanding-shares .table-panel {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}
.reporting-control-books-page .total-outstanding-shares .cob-date-message {
  padding-left: 1rem;
  font-style: italic;
}
.reporting-control-books-page .total-outstanding-shares .ReactTable .rt-thead {
  height: 4rem;
}
.reporting-control-books-page .total-outstanding-shares .ReactTable .rt-thead .rt-th {
  border-right: 0;
}
.reporting-control-books-page .total-outstanding-shares .ReactTable .rt-thead .rt-th:focus {
  outline: none;
}
.reporting-control-books-page .total-outstanding-shares .ReactTable .rt-td {
  border-right: 0;
  border-top: 0.1rem solid #e9e3dc;
}
.reporting-control-books-page .treasury-accounts-link {
  font-size: 1.4rem;
}