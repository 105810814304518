$env: false;
@import '../../../styles/variables';
@import '../../../styles/colors';

$padding-left: .2rem;
//$body-row-height: 2.4rem;
$zindex-row-column: 1;
$zindex-bar-chart: 0;

.table-easy {
    font-size: $font-size-small;

    &-with-footer {
        margin-bottom: 3rem;
    }

    .text-right {
        justify-content: flex-end;
    }

    .text-center {
        justify-content: center;
    }

    %row {
        padding-left: $padding-left;

        &:not(:last-of-type) {
            border-bottom: $border;
        }
    }

    .ReactVirtualized__Table {
        &__header {
            &Row {
                text-transform: none;
                background-color: $color-table-header-bg;
                font-weight: $font-weight-bold;
                border: 0;
            }

            &Column {
                border-right: $border;

                &:first-child {
                    margin: 0;
                }

                &:last-child {
                    border: 0;
                }
            }

            &TruncatedText {
                vertical-align: middle;
            }
        }

        &__headerColumn,
        &__rowColumn {
            margin: 0;
            padding: 0 .8rem;
            z-index: $zindex-row-column;
        }

        &__rowColumn {
            border-right: 1px $color-table-header-bg solid;
            // safari fix
            transform: translateZ(1px);

            &:first-of-type {
                margin: 0;
            }

            &:last-child {
                border: 0;
            }
        }

        &__sortableHeader {
            &Column {
                outline: none;
                display: flex;
                align-items: center;
                position: relative;
            }

            &Icon {
                height: 22px;

                &.ReactVirtualized__Table__sortableHeaderIcon--ASC,
                &.ReactVirtualized__Table__sortableHeaderIcon--DESC {
                    fill: $color-primary;
                    font-size: 3.1rem;
                }
            }
        }

        &__row {
            &:hover {
                background-color: $color-table-header-bg;

                .ReactVirtualized__Table__rowColumn {
                    border-right: $border;

                    &:last-child {
                        border: 0;
                    }
                }
            }
        }
    }

    .virtualized-sticky-footer {
        .ReactVirtualized__Table__rowColumn {
            transform: none;
        }

        .total-hidden {
            visibility: hidden;
        }
    }

    .transparent-header .ReactVirtualized__Table__headerRow {
        background-color: transparent;
    }

    .bar-chart {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        z-index: $zindex-bar-chart;
        background: $color-bar-chart;
        width: 0;
        transform-origin: left;
        transition: transform $animation-duration;
    }

    .hide-bar-chart {
        .bar-chart {
            transform: scaleX(0);
        }
    }

    .overfilling-row {
        display: inline-block;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 100%;

        &-container {
            display: flex;
            overflow: visible !important;
        }
    }
}
