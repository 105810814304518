$env: false;
@import '../../../styles/variables';

.notes {
    .widget-content {
        padding-top: 0;

        &-header {
            display: flex;
            align-items: center;
            padding-right: 5rem;

            .total-records {
                margin-left: auto;
                color: $color-text-label;
                font-size: $font-size-small;
            }
        }
    }

    .notes-header {
        line-height: 1.2;
        color: $color-default;
        margin: 0;
    }

    .ReactTable {
        .rt-thead {
            .rt-th:not(:first-child) {
                justify-content: center;
            }
        }

        .icon-cell {
            justify-content: center;
        }
    }

    .td-description {
        justify-content: flex-start;
    }
}

.modal.delete-note-modal {
    min-width: 31rem;
}

.contact-details-page,
.institution-profile-page {
    .notes {
        flex: 1;
        min-width: 87rem;
        padding: 2.4rem 3.6rem;

        .widget-header-container {
            margin-bottom: 1rem;
        }
    }
}

.institution-profile-page {
    .notes {
        padding-left: 1rem;
    }
}
