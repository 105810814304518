$env: false;
@import '../../../../styles/variables';
@import '../../../../styles/colors';

.widget-performance {
    .cell-with-number {
        width: 100%;
    }

    .cell-with-date {
        text-align: left;
    }

    $table-row-height: 3rem;

    .performance-content-table-rt {
        .rt-thead {
            height: $table-body-row-height;
            min-height: $table-body-row-height; // IE hack
        }

        .rt-th:not(:first-child) {
            justify-content: center;
        }

        .rt-td {
            word-break: break-all;
        }

        .rt-tr,
        .rt-td {
            min-height: $table-row-height;
        }

        // overriding
        .rt-tr-group:hover {
            .rt-td:first-child {
                border-right: $border;
            }
        }
    }

    .performance-content {
        .performance-chart-container {
            height: 42.2rem;
            position: relative;
        }

        .labels {
            font-size: $font-size-small;
        }

        .performance-content-chart {
            .labels {
                display: flex;
                justify-content: space-between;
                padding-right: 7.2rem;
            }
        }

        .volume-chart-container {
            height: 28.5rem;
            position: relative;
        }

        .volume-chart-content {
            margin-top: 2.4rem;
        }

        .volume-title {
            font-size: $font-size-large;
            font-weight: $font-weight-bold;
            color: $color-text-label;
            margin-top: 1.2rem;
        }

        .period-label {
            font-weight: $font-weight-bold;
        }

        .chart-checkbox:not(:last-child) {
            margin-right: 4rem;
        }

        .range-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 1rem;
        }
    }

    .performance-content-table {
        position: relative;
        min-height: 10rem;
    }

    .performance-content-small {
        .performance-content-table {
            .table-sticky-column .cell {
                border-bottom: none;
            }

            .rt-th,
            .rt-th-first {
                border-right: 1px $color-table-inner-border solid;
            }

            .rt-th,
            .rt-th-last {
                text-align: center;
            }

            .rt-th-last {
                border-right: none;
            }

            .rt-td {
                border-right: 1px $color-table-inner-border solid;
            }
        }

        .performance-content-chart-small {
            position: relative;
            min-height: 10rem;
        }

        .performance-chart-container {
            height: 18rem;
        }

        .performance-chart-filter {
            margin: .8rem 0 1.4rem;
        }

        .range-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 1rem;
        }

        .xignite-label {
            margin-top: 1rem;
        }
    }

    .widget-header-with-date {
        flex: 1;
    }
}
