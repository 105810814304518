$env: false;
.widget-date-last-contact {
    .table-easy {
        padding: 1rem 0 0;
    }

    .overfilling-row {
        min-width: 18rem;
    }
}
