$env: false;
@import '../../../styles/colors';

.shareholder-contacts-modal {
    min-height: 20rem;

    .modal-header-left {
        padding-right: 10rem;
    }

    &__content {
        margin: 2.4rem 0;
    }

    &__actions {
        vertical-align: middle;

        .font-icon {
            font-size: 2.4rem;
            cursor: pointer;
            margin-left: 1rem;
        }
    }

    .shareholder-contact-details {
        border-bottom: 1px solid $color-border;
        break-inside: avoid;

        &:last-child {
            border-bottom: none;
        }
    }
}

@media print {
    .shareholder-contacts-modal {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        max-width: none;
        max-height: none;
        border: none;

        &__actions {
            display: none;
        }

        .modal-header .close {
            display: none;
        }
    }
}
