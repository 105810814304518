$env: false;
@import '../../../../../styles/variables';
@import '../../../../../styles/colors';

.widget {
    &.registered-overview {
        .registered-overview-control-panel {
            display: flex;
            align-items: center;
            align-self: flex-start;
            position: static;
            padding: 1rem 0 1.5rem;
            border-bottom: $border;
        }

        .widget-block {
            position: relative;
            min-height: 20rem;
        }
    }
}
