$env: false;
@import '../../../../../styles/variables';
@import '../../../../../styles/colors';

.user-peers {
    &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .reset-link {
            flex: none;
        }
    }

    &-caption-block {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        // IE 10
        flex-shrink: 1;
        margin-right: 1rem;
    }

    &-caption {
        margin-right: 2.4rem;
    }

    .list-of-peers {
        padding: 1.8rem 0;
        position: relative;

        .peer {
            display: flex;
            justify-content: space-between;
            padding: 1.6rem 0;
            border-top: 1px solid $color-border;

            &:last-child {
                border-bottom: 1px solid $color-border;
            }

            // IE 10 fix
            .peer-description {
                display: inline-block;
                flex-shrink: 1;

                .unavailable-peer {
                    font-style: italic;
                }
            }
        }
    }

    .info-panel {
        color: $color-text-info;

        .message-note {
            padding-bottom: 1rem;
        }
    }

    .search-peers-panel {
        display: flex;
        align-items: flex-start;
        padding-bottom: 1.4rem;

        .find-section {
            display: flex;
            position: relative;
            flex: 1 1 auto;
            min-width: 35rem;
            max-width: 40rem;
            padding-right: 2rem;
        }

        .font-icon-search {
            font-size: $icon-large-size;
            position: absolute;
            top: .8rem;
            right: 2.4rem;
            pointer-events: none;
        }

        .Select {
            padding: 0;
            min-width: 0;
            width: 100%;
            height: $height-base;

            &-control {
                display: flex;
                max-width: 100%;
                height: 100%;
                border: $border-dark;
                padding-right: 3rem;
            }

            &-loading-zone {
                display: flex;
                align-items: center;
            }

            &-multi-value-wrapper {
                display: flex;
                flex: 1 1 100%;
            }

            &-input {
                width: 100%;
                height: 100%;

                > input {
                    line-height: 1.5;
                }
            }

            &-menu-outer {
                max-height: 17rem;
            }

            &-menu {
                max-height: 16.8rem;
            }

            &-option {
                padding: 1rem;

                &:not(:last-child) {
                    border-bottom: $border;
                }
            }

            &-arrow-zone {
                display: none;
            }
        }
    }
}
