$env: false;
@import '../../../styles/variables';

.export-classic-shareholder-report-modal {
    width: 41rem;

    .format-container {
        margin: 2.4rem 0;
    }
}
