/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
.widget-overall-ownership-statistics .pie-content-container {
  position: relative;
  padding: 0.5rem 0;
  height: 16rem;
}
.widget-overall-ownership-statistics .legend-container {
  padding: 0;
}
.widget-overall-ownership-statistics .total-value-container {
  padding: 0 0 1rem 1.2rem;
  font-size: 1.2rem;
}
.widget-overall-ownership-statistics .total-value-container .total-value-message {
  margin-right: 0.5rem;
  font-weight: bold;
}
.widget-overall-ownership-statistics .table-container {
  padding: 1rem 0 0;
}