$env: false;
@import '../../../styles/variables';

.select-account-form {
    padding-top: 1rem;

    .accounts-list {
        max-height: 30rem;
        overflow-y: auto;
    }

    .radio-component {
        padding: 1rem 0;
        font-weight: $font-weight-regular;
    }
}
