/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
.search {
  align-items: center;
  display: flex;
  position: relative;
  margin: 0 1rem;
}
.search .search-input {
  width: 100%;
}
.search .search-input .text-input-label.horizontal {
  margin-right: 0;
}
.search .search-input .text-input {
  width: 100%;
}
.search .search-input .text-input input {
  width: 100%;
  padding-right: 3.2rem;
}
.search .font-icon-search {
  font-size: 2.4rem;
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
.search .font-icon-search.disabled {
  cursor: auto;
  opacity: 0.65;
}
.search .spinner-container {
  position: absolute;
  right: 3rem;
  top: 50%;
  transform: translateY(-50%);
}