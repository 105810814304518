$env: false;
@import 'src/styles/variables';

.undefined-shareholder-tooltip__popover-tip-content {
    font-size: $font-size-small;
}

.undefined-shareholder-tooltip__popover-trigger--block {
    width: 100%;
}
