$env: false;
@import '../../styles/variables';

.input-date-range-picker {
    display: inline-block;

    .date-picker-component {
        &:first-child {
            margin-right: 2rem;
        }
    }
}

.date-range-with-checkbox {
    .checkbox-component {
        padding: 0;
        font-weight: $font-weight-regular;
        margin: 0 2.4rem 0 0;
    }
}
