$env: false;
@import '../../../../styles/colors';
@import '../../../../styles/variables';

.position-value {
    &.active {
        color: $color-primary;
    }
}

.heading-cell-with-filtration {
    & .filter-info {
        vertical-align: super;
    }
}

.file-status-heading-cell {
    display: flex;
    align-items: center;

    &__title {
        margin-right: .5rem;
    }

    &__tooltip {
        font-size: $font-size-small;

        p {
            margin: .5rem 0;
        }
    }

    & .font-icon {
        display: block;
    }
}

.cell-with-status {
    &__tooltip {
        font-size: $font-size-small;

        p {
            margin: .5rem 0;
        }
    }
}
