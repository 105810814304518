$env: false;
@import '../../styles/colors';
@import '../../styles/mixins';

.buttons-group {
    display: inline-block;
    vertical-align: top;
    text-align: left;

    .btn {
        vertical-align: top;
        font-size: 1.2rem;
        font-weight: normal;
        background-color: $_color-white;
        color: $_color-marron;
        border-color: $_color-pale-grey;

        &:active {
            @include view-group-btn-state($_color-white, $_color-marron, $_color-pale-grey);
        }

        &:hover {
            color: $color-link-hover;
        }

        &.active {
            @include view-group-btn-state($_color-ast-taupe, $_color-white, $_color-pale-grey);
        }

        &:before {
            display: none;
        }

        & + .btn {
            margin-left: -1px;
        }

        &:not(:first-child):not(:last-child) {
            border-radius: 0;
        }

        &:first-child {
            margin-left: 0;

            &:not(:last-child):not(.dropdown-toggle) {
                @include border-right-radius(0);
            }
        }

        &:last-child:not(:first-child) {
            @include border-left-radius(0);
        }
    }
}

// automatic remove right border radius
// if element have a right siblings, ex:
// 'Selector'. Wrap button-group in element
// if want to keep radius
.buttons-group:not(:last-child) {
    .btn:last-child {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
    }
}

@if ($env) {
    .buttons-group {
        background-color: #f3f4f6;

        @include border-radius(19px);
        border: 2px solid #f3f4f6;

        .btn {
            background-color: #f3f4f6;
            border: none;

            @include border-radius(19px);
            color: $color-primary;

            &.active {
                @include view-group-btn-state(#0c41c9, $_color-white, $_color-pale-grey);
                @include border-radius(19px);
                text-shadow: none;
            }

            & + .btn {
                margin-left: 0;
            }

            &:not(:first-child):not(:last-child) {
                @include border-radius(19px);
            }

            &:first-child {
                &:not(:last-child):not(.dropdown-toggle) {
                    @include border-radius(19px);
                }
            }

            &:last-child:not(:first-child) {
                @include border-left-radius(19px);
            }
        }
    }
}
