$env: false;
@import '../../styles/variables';

.registered-overview-page {
    display: flex;
    flex-direction: column;

    .content {
        padding: 2.4rem 0 1.2rem 2.4rem;
    }

    .institutional-overview-page-header__title {
        margin-top: 2rem;
        margin-bottom: 1rem;
        display: flex;
        align-items: center;

        .title {
            margin: 0;
        }
    }
}
