$env: false;
@import '../../../styles/variables';
@import '../../../styles/colors';

.search-result-page {
    display: flex;
    min-height: 100%;

    .aside-panel {
        background-color: $color-aside-bg;
        max-width: 32rem;
        flex: 0 0 32rem;
        padding: 2.8rem 2.4rem;
    }

    .main-content {
        flex: 1 1 auto;
        min-width: 0;
        width: 100%;
        padding: 0 2.4rem;
    }

    .font-icon {
        height: 2.4rem;
        font-size: 2.4rem;
        margin-right: 1.2rem;
        padding: .2rem 0;
    }
}

body.ie {
    .search-result-page .aside-panel {
        min-height: calc(100vh - #{$app-footer-height} - #{$app-header-height});
    }
}
