/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
.search-filter .filter-header {
  display: flex;
  align-items: baseline;
}
.search-filter .filter-header h3 {
  margin-top: 0;
}
.search-filter .filter-header .expand-all-tool {
  margin-left: 1.8rem;
  color: #941f40;
  cursor: pointer;
}
.search-filter .criterion {
  display: block;
  padding: 0.8rem 0;
}
.search-filter .criterion-total {
  color: #000;
}
.search-filter .expanded-filter {
  display: flex;
  align-items: center;
}
.search-filter .expanded-filter .font-icon-arrow-drop-down,
.search-filter .expanded-filter .font-icon-arrow-up {
  margin: 0 0 0 1rem;
  cursor: pointer;
}
.search-filter .second-layer {
  margin-left: 1.8rem;
}
.search-filter .checkbox-label {
  display: inline;
  font-weight: 400;
  color: #941f40;
}
.search-filter .no-criterion {
  padding: 1rem 0;
  color: #941f40;
}
.search-filter .selector {
  padding: 1rem 0;
  width: 20rem;
}
.search-filter .panel-collapse {
  background-color: #f4f1ed;
  margin: 0 -2.4rem;
  padding-left: 2.4rem;
}
.search-filter .collapse {
  display: none;
}
.search-filter .collapse.in {
  display: block;
}
.search-filter .collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition-property: height, visibility;
  transition-duration: 0.3s;
  transition-timing-function: ease;
}

.search-result-recent-search {
  margin-top: 2rem;
}
.search-result-recent-search .recent-search__title {
  font-size: 1.8rem;
  font-weight: 700;
}
.search-result-recent-search .recent-search__header .font-icon {
  margin-right: 0;
}
.search-result-recent-search .recent-search-list {
  padding: 0 2.4rem 0 1.7rem;
  margin: 0 -2.4rem;
  background-color: #f4f1ed;
}