/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
.save-new-scheduled-report-modal {
  width: 38rem;
}
.save-new-scheduled-report-modal .input-container {
  margin: 2.4rem 0;
}
.save-new-scheduled-report-modal .input-container .text-input-label {
  font-size: 1.2rem;
}
.save-new-scheduled-report-modal .input-container .text-input,
.save-new-scheduled-report-modal .input-container input {
  width: 100%;
}
.save-new-scheduled-report-modal .input-container .text-input::placeholder,
.save-new-scheduled-report-modal .input-container input::placeholder {
  font-style: italic;
  color: #cbc4be;
}
.save-new-scheduled-report-modal .text-message {
  margin: 2.5rem 0;
  font-style: italic;
  color: #6b6056;
}
.save-new-scheduled-report-modal .btn-panel {
  border-top: 1px solid #e9e3dc;
  padding-top: 2.5rem;
}
.save-new-scheduled-report-modal .btn-panel .btn {
  margin: 0 2rem 0 0;
}