$env: false;
@import '../../styles/variables';

.announcement-page {
    display: flex;
    flex-direction: column;

    .announcement {
        background-color: $color-aside-bg;
        display: flex;
        flex-direction: column;
        align-items: center;
        align-self: center;
        padding: 2rem;
        width: 64rem;

        &-content {
            background-color: $color-tab-bg;
            width: 100%;
            padding: 2rem;
            max-height: 55vh;
            overflow-y: auto;
        }

        .btn-panel {
            .btn {
                margin: 2rem 1rem 0;
            }
        }
    }
}
