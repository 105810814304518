$env: false;
@import '../../../styles/variables';

.table-sticky-column-group {
    .aggregate-cell {
        font-weight: $font-weight-bold;
    }

    .position-cell {
        margin-left: 1.2rem;
    }
}
