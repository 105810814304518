/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
.analysis-turnover-legend-modal-view {
  max-width: 57rem;
  color: #6b6056;
}
.analysis-turnover-legend-modal-view .table {
  width: 30rem;
  font-size: 1.4rem;
  margin: 1.5rem 0;
}
.analysis-turnover-legend-modal-view .table .row {
  display: flex;
  align-items: center;
  height: 2.9rem;
}
.analysis-turnover-legend-modal-view .table .row:not(:last-child) {
  border-bottom: 1px solid #e9e3dc;
}
.analysis-turnover-legend-modal-view .table .row .label {
  width: 50%;
}
.analysis-turnover-legend-modal-view .description-block {
  font-size: 1.2rem;
  white-space: pre-wrap;
}