$env: false;
@import '../../../../styles/colors';
@import '../../../../styles/variables';

.widget-box-empty {
    border: 3px dashed $color-border;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    &-container {
        min-height: 41.8rem;
    }

    .add-label-container {
        display: inline-flex;
        margin: 0 2.4rem;
        align-items: center;
        color: $color-primary;
        flex-shrink: 1; // IE 10 fix

        &,
        .font-icon {
            font-size: 2.4rem;
        }

        .font-icon-add-circle-outline {
            cursor: pointer;
        }

        .add-label {
            padding-left: 1rem;
        }

        .inactive + .add-label {
            opacity: $icon-inactive-opacity;
            cursor: default;
        }
    }
}

.popover.bottom.box-widget-menu {
    margin-top: -18rem;
}
