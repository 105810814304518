$env: false;
@import '../../../styles/colors';
@import '../../../styles/variables';

.company-information {
    margin-bottom: 2.5rem;

    .company-data {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        font-size: $font-size-large;

        .company-name {
            font-weight: $font-weight-bold;
            white-space: nowrap;
        }

        .stock-exchange {
            margin-right: 3.9rem;
            margin-left: .5rem;
        }

        .current-price,
        .change-price {
            margin-right: 1.2rem;
            font-weight: $font-weight-bold;
        }

        .change-price {
            &.positive:before {
                content: '+';
            }

            &.negative:before {
                content: '-';
            }

            &.neutral {
                color: $color-positive;
            }
        }

        .full-quote {
            font-size: $font-size-small;
            color: $color-primary;
            cursor: pointer;
            white-space: nowrap;
            margin-left: .5rem;

            &:hover {
                color: $color-link-hover;
            }
        }

        .no-data-message {
            font-size: $font-size-medium;
            margin-right: 1.2rem;
        }
    }

    .message-note {
        margin: 1.2rem 0;
    }
}
