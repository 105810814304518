/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
.user-profile-page {
  display: flex;
  align-items: flex-start;
  padding: 0 1.2rem;
}
.user-profile-page .user-profile-container {
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  margin: 2.4rem 1.2rem;
  padding: 1.8rem 2.4rem;
}
.user-profile-page .user-profile-container h2 {
  margin-top: 0;
  margin-bottom: 0;
}
.user-profile-page .column {
  width: 50%;
}
.user-profile-page .font-icon-edit, .user-profile-page .font-icon-download, .user-profile-page .font-icon-delete {
  font-size: 2.4rem;
  cursor: pointer;
}
.user-profile-page .font-icon-edit {
  padding-left: 1.5rem;
}
.user-profile-page .info {
  font-style: italic;
  color: #6b6056;
}
.user-profile-page .user-alerts-header {
  display: flex;
  align-items: center;
}
.user-profile-page .user-alerts-header .info {
  padding-left: 2.4rem;
}
.user-profile-page .user-alerts .alerts-item {
  font-weight: normal;
  color: #000;
}