/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
.layout-three-columns {
  flex-direction: column;
}
.layout-three-columns .row {
  width: 100%;
  margin-bottom: 3.8rem;
}
.layout-three-columns .column {
  width: 0;
  flex-grow: 1;
}
.layout-three-columns .three-column-container {
  border-bottom: 1px solid #e9e3dc;
  padding-bottom: 2.4rem;
}
.layout-three-columns .three-column-container .column:not(:last-child) {
  margin-right: 5rem;
}