$env: false;
@import '../../../styles/variables';

.note-details-modal {
    .note-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem 0;
        margin: 1rem 0;
        border: .1rem solid $color-border;
        border-left: 0;
        border-right: 0;

        .info-section {
            display: flex;
            color: $color-text-label;

            .title {
                font-weight: $font-weight-bold;
                margin-right: 1rem;
            }
        }
    }

    .note-text-section {
        width: 40rem;
        height: 26rem;
        word-break: break-all;
        overflow-y: auto;
        padding: .8rem;
        border: .1rem solid $color-border;
        border-radius: .8rem;
    }

    .modal-actions {
        margin-top: 2.4rem;

        &:before {
            display: none;
        }
    }
}
