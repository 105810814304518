/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
.column-manager-modal {
  display: flex;
  justify-content: space-between;
}
.column-manager-modal .column-lists {
  border-bottom: 1px solid #e9e3dc;
  padding-bottom: 2.4rem;
  margin-right: 2.4rem;
}
.column-manager-modal .column-list .column-list-label {
  display: block;
  line-height: 3;
  color: #000;
  padding-right: 2.7rem;
  font-weight: 700;
}
.column-manager-modal .column-list .checkbox-list {
  padding-right: 2.7rem;
  overflow: hidden;
}
.column-manager-modal .column-list .checkbox-label {
  font-weight: normal;
  color: #000;
}
.column-manager-modal .column-list .checkbox-component {
  overflow: hidden;
}
.column-manager-modal .btn-panel {
  padding: 1.2rem 0 0;
}
.column-manager-modal .btn-panel .btn {
  margin: 1.2rem 1.2rem 0;
  font-size: 1.6rem;
  font-weight: 600;
}
.column-manager-modal .btn-panel .btn:first-child {
  margin-left: 0;
}
.column-manager-modal .custom-views-section {
  margin: -5.1rem -2.4rem -1.5rem 0;
  padding: 5.1rem 1rem 1.5rem;
  width: 19.2rem;
  background-color: #f8f6f4;
}
.column-manager-modal .custom-views-section .custom-views-section-header {
  display: block;
  line-height: 3;
  color: #000;
  font-weight: 700;
}
.column-manager-modal .custom-views-section .custom-views-list {
  max-height: 45.9rem;
  overflow-y: auto;
}
.column-manager-modal .custom-views-section .custom-views-section-item {
  display: flex;
  justify-content: space-between;
  padding: 0.6rem 0;
}
.column-manager-modal .custom-views-section .custom-views-section-item .custom-view-name {
  display: block;
  flex-shrink: 1;
  max-width: 10rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.column-manager-modal .custom-views-section .custom-views-section-item .font-icon {
  font-size: 2.4rem;
  padding-left: 0.3rem;
  cursor: pointer;
}