$env: false;
@import '../../../styles/colors';
@import '../../../styles/variables';

.reporting-holder-type-analysis-page {
    .reporting-holder-type-analysis-page-header {
        display: flex;
        margin-bottom: 2.4rem;

        .message-note {
            margin-left: 1.2rem;
            line-height: $line-height-header;
        }

        .title {
            margin: 0;
        }
    }
}
