/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
.table-sticky-column {
  height: 100%;
  font-size: 1.2rem;
}
.table-sticky-column .cell {
  padding: 0 0.6rem;
  min-width: 0;
  display: table;
  border-bottom: 1px solid #e9e3dc;
}
.table-sticky-column .last-row-cell {
  border-bottom: none;
}
.table-sticky-column .truncated-text {
  display: table-cell;
  vertical-align: middle;
  line-height: normal;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.table-sticky-column .text-right {
  justify-content: flex-end;
}
.table-sticky-column .text-left {
  justify-content: flex-start;
}
.table-sticky-column .header-cell {
  background-color: #f4f1ed;
  font-weight: 700;
}
.table-sticky-column .table-header {
  background-color: #f4f1ed;
}
.table-sticky-column .ReactVirtualized__Grid:first-child {
  width: 100% !important;
}