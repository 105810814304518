$env: false;
@import '../../../styles/colors';
@import '../../../styles/variables';

$error-page-padding-top: .8rem;
$error-page-padding-top-large: 11rem;

.error-page {
    position: relative;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    padding-top: $error-page-padding-top;
    padding-bottom: $error-page-padding-top + $app-footer-height;

    &-header {
        text-align: center;
    }

    &-content {
        flex: 1 0 auto;
        align-items: center;
        align-content: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: calc(100vh - #{$app-footer-height} - 16rem);
    }

    .error-code {
        font-size: 25rem;
        line-height: 28rem;
        color: $color-error-code;
    }

    .error-message-title {
        font-size: $font-size-x-large;
        font-weight: bold;
        line-height: 2.8rem;
        color: $color-error-message;
        text-transform: uppercase;
        margin: 2.4rem 0 4.8rem;
        text-align: center;
    }

    .error-message-text {
        line-height: 2.4rem;
        color: $color-error-message;
        text-align: center;

        p {
            margin: 0;
        }
    }

    .error-action {
        margin: 4.8rem 0;
    }

    .ast-logo {
        height: 10rem;
    }

    .expanded-error {
        text-align: center;
        width: 100%;
        padding: 0 10rem;

        &-header {
            display: flex;
            justify-content: space-between;
        }

        &-title {
            text-align: left;
        }

        &-links {
            display: flex;
            align-items: center;

            a {
                margin-left: 1.5rem;
            }
        }
    }

    &-no-issuer-central {
        padding-top: $error-page-padding-top-large;

        .error-page-content {
            min-height: calc(100vh - #{$app-footer-height} - 34rem);
        }

        .error-message-text {
            text-align: left;
            line-height: normal;
            color: $_color-tundora;
            font-size: $font-size-x-large;
        }

        .bottom-error-code {
            margin: 2.4rem 0 4.8rem;
            color: $color-obsolete-position;
            font-size: $font-size-large;
        }

        .ast-logo {
            height: 14rem;
        }
    }
}
