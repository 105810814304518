/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
.user-peers-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.user-peers-header .reset-link {
  flex: none;
}
.user-peers-caption-block {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-shrink: 1;
  margin-right: 1rem;
}
.user-peers-caption {
  margin-right: 2.4rem;
}
.user-peers .list-of-peers {
  padding: 1.8rem 0;
  position: relative;
}
.user-peers .list-of-peers .peer {
  display: flex;
  justify-content: space-between;
  padding: 1.6rem 0;
  border-top: 1px solid #e9e3dc;
}
.user-peers .list-of-peers .peer:last-child {
  border-bottom: 1px solid #e9e3dc;
}
.user-peers .list-of-peers .peer .peer-description {
  display: inline-block;
  flex-shrink: 1;
}
.user-peers .list-of-peers .peer .peer-description .unavailable-peer {
  font-style: italic;
}
.user-peers .info-panel {
  color: #6b6056;
}
.user-peers .info-panel .message-note {
  padding-bottom: 1rem;
}
.user-peers .search-peers-panel {
  display: flex;
  align-items: flex-start;
  padding-bottom: 1.4rem;
}
.user-peers .search-peers-panel .find-section {
  display: flex;
  position: relative;
  flex: 1 1 auto;
  min-width: 35rem;
  max-width: 40rem;
  padding-right: 2rem;
}
.user-peers .search-peers-panel .font-icon-search {
  font-size: 2.4rem;
  position: absolute;
  top: 0.8rem;
  right: 2.4rem;
  pointer-events: none;
}
.user-peers .search-peers-panel .Select {
  padding: 0;
  min-width: 0;
  width: 100%;
  height: 4rem;
}
.user-peers .search-peers-panel .Select-control {
  display: flex;
  max-width: 100%;
  height: 100%;
  border: 1px solid #cbc4be;
  padding-right: 3rem;
}
.user-peers .search-peers-panel .Select-loading-zone {
  display: flex;
  align-items: center;
}
.user-peers .search-peers-panel .Select-multi-value-wrapper {
  display: flex;
  flex: 1 1 100%;
}
.user-peers .search-peers-panel .Select-input {
  width: 100%;
  height: 100%;
}
.user-peers .search-peers-panel .Select-input > input {
  line-height: 1.5;
}
.user-peers .search-peers-panel .Select-menu-outer {
  max-height: 17rem;
}
.user-peers .search-peers-panel .Select-menu {
  max-height: 16.8rem;
}
.user-peers .search-peers-panel .Select-option {
  padding: 1rem;
}
.user-peers .search-peers-panel .Select-option:not(:last-child) {
  border-bottom: 1px solid #e9e3dc;
}
.user-peers .search-peers-panel .Select-arrow-zone {
  display: none;
}