/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
.search-action-panel {
  position: relative;
  display: flex;
  align-items: baseline;
  padding: 0.8rem 1.3rem;
}
.search-action-panel .compare-actions {
  display: flex;
  align-items: baseline;
  flex-grow: 1;
}
.search-action-panel .compare-actions .font-icon-combine,
.search-action-panel .compare-actions .font-icon-compare {
  align-self: center;
  margin-right: 1rem;
  padding: 0.5rem;
  border: 1px solid #e9e3dc;
  border-radius: 0.3rem;
  height: auto;
  cursor: pointer;
}
.search-action-panel .compare-actions .font-icon-combine.inactive,
.search-action-panel .compare-actions .font-icon-compare.inactive {
  cursor: not-allowed;
}
.search-action-panel .expand-collapse-trigger {
  color: #941f40;
  cursor: pointer;
}
.search-action-panel .expand-collapse-trigger:not(:first-child) {
  margin-left: 2rem;
}

.limit-accounts-modal,
.fail-combine-modal {
  max-width: 36rem;
  color: #6b6056;
}