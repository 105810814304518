/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
.search-by-security-selector {
  width: 100%;
  padding-bottom: 5rem;
}
.search-by-security-selector .radio-buttons {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.search-by-security-selector .search-input {
  display: flex;
}
.search-by-security-selector .search-input .form-error-summary {
  margin: 0.5rem 0;
  padding: 0 0 0 1rem;
}
.search-by-security-selector .search-input .form-error-summary .inner {
  padding: 0.6rem;
}
.search-by-security-selector .search-input .form-error-summary .arrow {
  position: absolute;
  top: 32%;
  left: 0;
  margin-left: 0.6rem;
  border-width: 5px 5px 5px 0;
  border-right-color: #ef4e37;
  border-top-color: transparent;
}
.search-by-security-selector .search-input-field {
  margin-right: 0.8rem;
  flex: auto;
  max-width: 42rem;
  position: relative;
}
.search-by-security-selector .search-input-field .spinner-container {
  position: absolute;
  right: 1rem;
  line-height: 1;
  top: 50%;
  transform: translateY(-50%);
}
.search-by-security-selector .search-input-field .text-input-label.horizontal {
  margin-right: 0;
}
.search-by-security-selector .search-input-field .text-input,
.search-by-security-selector .search-input-field .text-input input {
  width: 100%;
}
.search-by-security-selector .search-input-field .text-input input {
  padding-right: 3.2rem;
}