/* private variables */
/* public variables */
/* Link */
/* Icons */
/* Text */
/* Buttons */
/* Inputs */
/* NavPanel and Tabs */
/* Table */
/* Message card */
/* Dropdown */
/* Notification panel */
/* Intervals */
/* Modals */
/* Spinner */
/* Loading-block */
/* DatePicker */
/* Table bar chart */
/* Error message */
/* Reporting */
/* Error page */
/* Invalid input */
/* Widget */
/* Shareholder positions */
/* Scroll */
/* Footer */
/* Highlights */
/* Border */
/* Buttons */
/* Icons */
/* Modals */
/* Padding */
/* z-index */
/* Tooltip */
/* Popover */
/* Dropdown */
/* Responsive */
/* Animation */
/* Nav */
/* Link */
/* Form */
/* Table */
/* User image */
/* Position History */
/* Shareholder table */
/* Institutional Shareholders */
/* Slot */
/* Reporting page */
/* Header */
/* Footer */
.authorized-users-widget .widget-header-container {
  align-items: center;
}
.authorized-users-widget .widget-header-container .securities-container {
  display: flex;
  align-items: center;
  width: 50rem;
  margin-right: auto;
}
.authorized-users-widget .widget-header-container .securities-container > span {
  font-weight: 700;
  margin-right: 1rem;
}
.authorized-users-widget .widget-header-container .securities-container .securities-selector {
  min-width: 45rem;
}
.authorized-users-widget .widget-header-container .securities-container .securities-container__checkbox {
  margin-left: 1rem;
}
.authorized-users-widget .ReactTable .rt-thead {
  height: auto;
}
.authorized-users-widget .ReactTable .rt-th {
  border: 0;
  padding-bottom: 1rem;
  padding-top: 1rem;
  padding-right: 2.2rem;
  justify-content: center;
}
.authorized-users-widget .ReactTable .rt-td {
  border-right: 0;
  border-top: 0.1rem solid #e9e3dc;
}
.authorized-users-widget .ReactTable .rt-tr-group .rt-tr:hover .rt-td {
  border-right: 0;
}
.authorized-users-widget .ReactTable .rt-tfoot {
  font-weight: 400;
}