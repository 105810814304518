$env: false;
@import '../../../styles/colors';
@import '../../../styles/variables';

.reporting-control-books-results-page {
    &-header {
        .heading {
            margin: 1.2rem 0;
        }
    }

    .ReactTable {
        overflow-x: visible;
    }
}
